import React, { useEffect, useMemo, useState } from 'react'
import { formatNumber } from '../../../helpers/formatNumber';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { faPlus, faTrash } from '@fortawesome/pro-light-svg-icons';

import { loadBloodLimits } from '../../../actions/results-actions';
import { loadHealthCategories } from '../../../actions/test-actions' 

import { openBootstrapModal } from '../../../helpers/openBootstrapModal';
import { closeBootstrapModal } from '../../../helpers/closeBootstrapModal';

import SidebarModal from '../SidebarModal';
import RenderIf from '../../RenderIf/RenderIf';
import Button from '../../GlobalComponents/Button';

import styles from './TestUpHealthCoachSeller.module.scss'

const PHLEBOTOMY_BLOOD_AMOUNT = 225;
const MINIMUM_BLOOD_AMOUNT = 75;
const BLOOD_TUBES = {
  yellow: 1,
  purple: 2
}


function TestUpHealthCoachSeller({ 
  setPlusTests,
  quantity,
  disableQuantity,
  handleQuantityChange,
  test,
  testId,
  phlebotomyOption,
  showEdit,
  voucher,
  plusTests,
  plusTestsSelected,
  healthCategories,
  updatePhlebotomyOption,
  loadBloodLimits,
}) {
  const INITIAL_TEST_LIST = test.upsellTests?.filter(test => test.isHealthCoachTest == 1) || []
  const INITIAL_TEST_SELECTED = plusTestsSelected?.map(test => test.testId) || []
  // const [healthCategories, setHealthCategories] = useState({})
  const [testsSelected, setTestsSelected] = useState(INITIAL_TEST_SELECTED)
  const [testUpSellerList, setTestUpSellerList] = useState(INITIAL_TEST_LIST)
  const [bloodAmountSettings, setBloodAmountSettings] = useState({})

  const phlebotomyBloodAmount = useMemo(() => parseFloat(bloodAmountSettings?.phlebotomyBloodAmount) || PHLEBOTOMY_BLOOD_AMOUNT, [bloodAmountSettings?.phlebotomyBloodAmount, PHLEBOTOMY_BLOOD_AMOUNT]);
  const minimumBloodAmount = useMemo(() => parseFloat(bloodAmountSettings?.minimumBloodAmount) || MINIMUM_BLOOD_AMOUNT, [bloodAmountSettings?.minimumBloodAmount, MINIMUM_BLOOD_AMOUNT]);
  const modalConfirmationId = "phlebotomyConfirmation"
  const dispatch = useDispatch()

  // const allHealthCoachTests = useMemo(() => {
  //   if(Object.values(plusTests).length === 0) return []
    
  //   return Object.values(plusTests).filter(test => test.isHealthCategory === 1)
  // }, [plusTests])

  const healthCoachIncluded = useMemo(() => {
    if(healthCategories.length === 0 || Object.values(test).length === 0) return "";

    const biomarkerIds = test?.biomarkers?.map(biomarker => biomarker.biomarkerId) || []

    const healthCategoriesList = Object.values(healthCategories)?.filter(category => {
      return category?.biomarkerIds?.every(biomarkerId => biomarkerIds?.includes(biomarkerId));
    }).map(hc => {
      if(hc.name === "Female Hormones" && test.isMenopauseTest == 1) {
        return {...hc, name: "Menopause health"}
      }
      return hc
    });

    return healthCategoriesList || [];

  }, [healthCategories, test])

  useEffect(() => {
    dispatch(loadHealthCategories())

    loadBloodLimits().then(res => {
      if(!res.error) {
        setBloodAmountSettings(res.response)
      }
    })

    return () => {
      closeBootstrapModal(modalConfirmationId)
    }
  }, [])

  useEffect(() => {
    recalculateTestPrice()
    recalculateBloodAmount()
  }, [testsSelected])

  const recalculateBloodAmount = () => {
    if(test?.testType == 2) return

    const parentBiomarkers = test.biomarkers || []
    const testsSelectedBiomarkers = testUpSellerList?.filter(hc => testsSelected?.includes(hc.testId))?.flatMap(hc => hc.biomarkers) || []
    const allBiomarkers = uniqBy([...parentBiomarkers, ...testsSelectedBiomarkers], "biomarkerId") || []

    let totalBloodAmountYellow = allBiomarkers
      ?.filter(marker => marker.tubeColourId == BLOOD_TUBES.yellow)
      ?.reduce((total, marker) => {
        return total + parseFloat(marker.bloodAmount)
      }, 0)

    let totalBloodAmountPurple = allBiomarkers
      ?.filter(marker => marker.tubeColourId == BLOOD_TUBES.purple)
      ?.reduce((total, marker) => {
        return total + parseFloat(marker.bloodAmount)
      }, 0)

    if (totalBloodAmountYellow > 0) {
      totalBloodAmountYellow += minimumBloodAmount;
    }

    if (totalBloodAmountPurple > 0) {
      totalBloodAmountPurple += minimumBloodAmount;
    }

    let totalBloodAmount = Math.max(totalBloodAmountYellow, totalBloodAmountPurple)

    const isPhlebotomy = totalBloodAmount >= phlebotomyBloodAmount
    // console.log('isPhlebotomy: ', isPhlebotomy);

    if(isPhlebotomy && phlebotomyOption == 1){
      openBootstrapModal(modalConfirmationId)
    } else if (isPhlebotomy && phlebotomyOption != 1){
      updatePhlebotomyOption(phlebotomyOption, 0, true)
    } else {
      updatePhlebotomyOption(phlebotomyOption, 0, false)
    }
  }
  
  const recalculateTestPrice = () => {
    const testosteroneId = 41
    const oestradiolId = 45
    const parentBiomarkerIds = test.biomarkers.map(biomarker => biomarker.biomarkerId)
    let biomarkerIdsAlreadyIncluded = test.biomarkers.map(biomarker => biomarker.biomarkerId)
    
    if(testsSelected.length == 0) {
      setTestUpSellerList(prev => prev.map(test => delete test.newTestPrice))
    }

    const testAlreadySelected = testUpSellerList?.filter(test => testsSelected.includes(test.testId)) || []
    if(testAlreadySelected.length > 0){
      biomarkerIdsAlreadyIncluded = testAlreadySelected.reduce((newList, test) => {
        return uniq([...newList, ...test.biomarkers.map(biomarker => biomarker.biomarkerId)])
      }, [...biomarkerIdsAlreadyIncluded])
    }

    const newTestUpSellerList = testUpSellerList.map(test => {
      const {testName, testPrice, biomarkers} = test
      const isTestAlreadySelected = testsSelected.find(testId => testId === test.testId)
      const isFirstTestsSelected = test.testId === testsSelected[0] || false

      if(isFirstTestsSelected){
        let biomarkersNotIncluded = biomarkers.filter( biomarker => !parentBiomarkerIds.includes(biomarker.biomarkerId))

        const hasTestosteroneAndOestradiol = [testosteroneId, oestradiolId].every(markerId => {
          return biomarkersNotIncluded.some(marker => marker.biomarkerId == markerId)
        })
  
        if(hasTestosteroneAndOestradiol){
          biomarkersNotIncluded = biomarkersNotIncluded.filter( biomarker => biomarker.biomarkerId != testosteroneId)
        }

        const newTestPrice = biomarkersNotIncluded.reduce((newPrice, biomarker) => {
          return newPrice + parseFloat(biomarker.biomarkerPrice)
        }, 0)

        const testPriceFormatted = formatNumber(testPrice)
        const newTestPriceFormatted = formatNumber(newTestPrice)

        if(newTestPriceFormatted !== testPriceFormatted) {
          test.newTestPrice = newTestPriceFormatted
        }

        return test
      }

      if(isTestAlreadySelected) return test

      let biomarkersNotIncluded = biomarkers.filter( biomarker => !biomarkerIdsAlreadyIncluded.includes(biomarker.biomarkerId))
      
      const hasTestosteroneAndOestradiol = [testosteroneId, oestradiolId].every(markerId => {
        return biomarkersNotIncluded.some(marker => marker.biomarkerId == markerId)
      })

      if(hasTestosteroneAndOestradiol){
        biomarkersNotIncluded = biomarkersNotIncluded.filter( biomarker => biomarker.biomarkerId != testosteroneId)
      }
      
      const newTestPrice = biomarkersNotIncluded.reduce((newPrice, biomarker) => {
        return newPrice + parseFloat(biomarker.biomarkerPrice)
      }, 0)

      const testPriceFormatted = formatNumber(testPrice)
      const newTestPriceFormatted = formatNumber(newTestPrice)

      if(newTestPriceFormatted !== testPriceFormatted) {
        test.newTestPrice = newTestPriceFormatted
      }
      return test
    })

    setTestUpSellerList(newTestUpSellerList)

    const newPlusTestsState = testsSelected.reduce((newObj, testId) => {
      return {
        ...newObj,
        [test.testId]: testUpSellerList.filter(test => testsSelected.includes(test.testId)).map(test => ({testId: test.testId, testPrice: test.testPrice, newTestPrice: test.newTestPrice}))
      }
    }, {})
    setPlusTests(newPlusTestsState)
  }

  const handleTestSelected = (testUpSellerId) => {
    if(!testId) return

    if (quantity != 1) {
      handleQuantityChange(testId, 1);
    } 

    const testFound = Boolean(testsSelected.find(test => test == testUpSellerId))

    if(testFound) {
      setTestsSelected(prev => {
        const newTestState = prev.filter(test => test != testUpSellerId)
        if(newTestState.length > 0) disableQuantity(true)
        if(newTestState.length == 0) disableQuantity(false)

        return newTestState; 
      })
    }

    if(!testFound) {
      setTestsSelected(prev => {
        const newTestState = [...prev, testUpSellerId]
        disableQuantity(true)

        return newTestState
      })
    }
  }

  const getColSize = (arrayLength, index) => {
    let columns, columnsMd;

    if (arrayLength <= 3) {
      columns = arrayLength;
      columnsMd = 2
    } else {
      columns = 3;
        columnsMd = 2
    }

    let remainder = arrayLength % columns;
    let remainderMd = arrayLength % columnsMd;
    let colSize;

    if (remainder === 0 || index < arrayLength - remainder) {
      colSize = `col-12 col-md-${remainderMd === 0 ? 6 : index !== arrayLength - 1 ? 6 : 12} col-xl-${12 / columns}`;
    } else {
      if(remainder < columns) {
        columns = remainder;
        columnsMd = 2
      }

      if(remainderMd < columnsMd) {
        columnsMd = remainderMd;
      }

      colSize = `col-12 col-md-${remainderMd === 0 ? 6 : index !== arrayLength - 1 ? 6 : 12} col-xl-${12 / columns} `;
    }

    return colSize;

  }

  const handleConfirm = (proceed) => {
    if(!proceed) {
      setTestsSelected(prev => {
        const newTestState = prev.filter((test, index) => index !== prev.length - 1)
        if(newTestState.length > 0) disableQuantity(true)
        if(newTestState.length == 0) disableQuantity(false)

        return newTestState;
      })
      closeBootstrapModal(modalConfirmationId)

    } else {
      updatePhlebotomyOption(3, 0, true)
      closeBootstrapModal(modalConfirmationId)
      openBootstrapModal("phlebotomyOptions")
    }

  }

  return (
    <>
      <section className={`${styles.container} mt-3`}>
          <RenderIf isTrue={healthCoachIncluded.length > 0}>
            <div className={styles.healthCoachIncluded}>
              <p className='mb-0 lh-base fw-normal'>The following HealthCoach scores are included in this test:</p>
              <p className='mb-0 lh-base fw-light' dangerouslySetInnerHTML={{__html: `${healthCoachIncluded.map(hc => hc.name).join(", ")}`}}></p>
            </div>
          </RenderIf>

          <h3 className={`${healthCoachIncluded.length > 0 ? "mt-3" : ""} text-center mb-4 mb-md-3`}>Want more scores?</h3>
          
          <div className={`row g-4 gx-xl-2`}>
            {testUpSellerList.map((test, index) => {
              const isActive = testsSelected.includes(test.testId)
              const isRecommended = test.isRecommended == 1
              const colSize = getColSize(testUpSellerList.length, index)
              {/* const colSize = "col-12 col-md-6 col-xl-4" */}
              const isDisabled = test.newTestPrice && test.newTestPrice === "0"

              return (
                <div key={test.testId} className={`${colSize} d-flex`}>
                  <button className={styles.btn} data-recommended={isRecommended} onClick={() => handleTestSelected(test.testId)} disabled={isDisabled}>
                    <div className='row justify-content-start align-items-center gx-3'>
                      <div className='col-auto'>
                        <RenderIf isTrue={isActive}>
                          <FontAwesomeIcon icon={faCheckCircle} className='fa-fw' size='lg'/>
                        </RenderIf>
                        <RenderIf isTrue={!isActive}>
                          <FontAwesomeIcon icon={faCircle} className='fa-fw' size='lg'/>
                        </RenderIf>
                      </div>
                      <RenderIf isTrue={test.newTestPrice}>
                        <div className='col'>
                          <p className={`mb-0 text-start text-balance ${styles.price}`} dangerouslySetInnerHTML={{__html: `${test.testName} <span>(${formatNumber(test.newTestPrice) == 0 ? "Included" : `£${formatNumber(test.newTestPrice)}`})</span>`}}></p>
                        </div>
                      </RenderIf>
                      <RenderIf isTrue={!test.newTestPrice}>
                        <div className='col'>
                          <p className={`mb-0 text-start text-balance ${styles.price}`} dangerouslySetInnerHTML={{__html: `${test.testName} <span>(£${formatNumber(test.testPrice)})</span>`}}></p>
                        </div>
                      </RenderIf>
                    </div>
                    <RenderIf isTrue={isActive}>
                      <FontAwesomeIcon icon={faTrash} className='fa-fw' size='sm'/>
                    </RenderIf>
                    <RenderIf isTrue={!isActive}>
                      <FontAwesomeIcon icon={faPlus} className='fa-fw' size='sm'/>
                    </RenderIf>
                  </button>
                </div>
              )
            })}
          </div>
      </section>

      <SidebarModal variant="default" hideHeader={true} isStatic={true} modalId={modalConfirmationId} className={styles.modal}>
        <img src="/img/phlebotomy.svg" className='d-block mx-auto mt-4' alt='Phlebotomy icon' aria-hidden/>
        <p className='lh-base mt-4 text-pretty'>By adding this health score, the test will require your blood to be drawn by a professional due to the amount of blood required.</p>
        <p className='lh-base mb-5 text-pretty'>If you select <strong>confirm</strong>, you will have the option to either arrange a home phlebotomy appointment with a mobile nurse, to attend a clinic with one of our partners, or arrange for the blood draw yourself.</p>
        <div className='row'>
          <div className='col-6'>
            <Button onClick={() => handleConfirm(false)} variant="link" className="w-100 text-center ">
              Cancel
            </Button>
          </div>
          <div className='col-6'>
            <Button onClick={() => handleConfirm(true)} variant="primary" className="w-100 text-center ">
              Confirm
            </Button>
          </div>
        </div>
      </SidebarModal>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const basketParam = ownProps.match.params.basket;

  const {
    entities: { basketTestItems, plusTests, healthCategories },
    authentication,
    user,
    basket,
  } = state;

  return {
    authentication,
    user,
    plusTests,
    healthCategories,
    basketTestItems,
    basket
  };
};

export default withRouter(
  connect(mapStateToProps, {
    loadBloodLimits,
    loadHealthCategories
  })(TestUpHealthCoachSeller)
);