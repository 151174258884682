import React, { useMemo } from 'react'
import unescape from 'lodash/unescape';
import isEmpty from 'lodash/isEmpty';

import RenderIf from '../../RenderIf/RenderIf';
import BiomarkersIncluded from '../../Partner/PartnerPlaceOrder/BiomarkersIncluded/BiomarkersIncluded';

import { formatNumber } from '../../../helpers/formatNumber';

import styles from './OrderSummary.module.scss'

export default function OrderSummary({clientDetails = {}, phlebotomyPrices = {}, tests = [], phlebotomyOptionSelected = 0, deliveryFee = 0, deliveryAddress = {}, partner = {}, showBiomarkers = true}) {

  const testListGrouped = useMemo(() => {
    if(!tests || !tests.length) return []

    return tests.reduce((acc, test) => {
      const testFound = acc.find((item) => item.testId === test.testId)
      if(testFound){
        testFound.qty += 1
      }else{
        acc = [...acc, {...test, qty: 1}]
      }
      return acc
    }, []);
  }, [tests])

  const subtotal = useMemo(() => {
    if(!tests || !tests.length) return 0

    return tests.map((test) => parseFloat(test.testPrice)).reduce((acc, test) => acc + test, 0)
  }, [tests])

  const phlebotomySelectedDetails = phlebotomyPrices[phlebotomyOptionSelected] || {}
  const phlebotomyPrice = phlebotomyOptionSelected != 0 ? parseFloat(phlebotomySelectedDetails?.phlebotomyPrice) : 0
  const partnerDiscount = parseFloat(partner?.partnerDiscount) || 0
  const discountOrCommissionCap = partner?.partnerCommissionCap || 0
  const basketDiscount = subtotal * (partnerDiscount / 100)
  const basketDiscountApplied = basketDiscount > discountOrCommissionCap ? discountOrCommissionCap : basketDiscount
  const deliveryAmount = deliveryFee * tests.length
  const totalToPay = subtotal + (phlebotomyPrice * tests.length) - basketDiscount + deliveryAmount

  return (
    <div>
      <RenderIf isTrue={!isEmpty(clientDetails)}>
        <div className='py-2 d-flex justify-content-between flex-column p-2 rounded mb-3' style={{backgroundColor: "#F6F6F6"}}>
          <p className='mb-1 fw-normal text-decoration-underline fw-bold lh-base'>Client details</p>
          <ul className='ps-3 mb-0'>
            {clientDetails.customerFirstName && <li className='fw-normal lh-base'><strong>Full name:</strong> {clientDetails.customerFirstName} {clientDetails?.customerLastName || ""}</li>}
            {clientDetails.customerSex && [1, 2].some(customerSexId => customerSexId == clientDetails.customerSex) && <li className='fw-normal lh-base'><strong>Biological sex:</strong> {clientDetails.customerSex == 1 ? "Male" : "Female"}</li>}
            {clientDetails.customerEmailAddress && <li className='fw-normal lh-base'><strong>Email address:</strong> {clientDetails.customerEmailAddress}</li>}
          </ul>
        </div>
      </RenderIf>

      <h4 className={styles.title}>Basket Items</h4>

      <ul className={styles.list}>
        {testListGrouped.map((test, index) => (
          <li key={index} className='d-flex justify-content-between align-items-center'>
          <p className='mb-1 d-flex gap-2 align-items-center'>
            {unescape(test.testName)}
            {!isEmpty(test.biomarkers) && showBiomarkers && <BiomarkersIncluded variant="small" test={test}/>}
          </p> 
          {test.qty && <span>x{test.qty}</span>}
          </li>
        ))}
      </ul>

      <hr/>

      <div className='py-2 d-flex justify-content-between'>
        <p className='mb-0'>Subtotal</p>
        <span className='fs-6 fw-bolder'>£{formatNumber(subtotal)}</span>
      </div>

      <RenderIf isTrue={phlebotomyOptionSelected != 0}>
        <div className='py-2 d-flex align-items-center justify-content-between'>
          <p className='mb-0'>{phlebotomySelectedDetails.phlebotomyName}</p>
          <span className='fs-6 fw-bolder' dangerouslySetInnerHTML={{__html: `
            ${phlebotomyPrice != 0 
              ? `£${formatNumber(phlebotomyPrice)} <small class="fw-normal">Per test</small>` 
              : "FREE"
            }
          `}}></span>
        </div>
      </RenderIf>

      <RenderIf isTrue={!isEmpty(partner) && partnerDiscount != 0}>
        <div className='py-2 d-flex align-items-center justify-content-between'>
          <p className='mb-0'>{partner.partnerName} discount</p>
          <span className='fs-6 fw-bolder text-danger'>-£{formatNumber(basketDiscountApplied)}</span>
        </div>
      </RenderIf>

      <RenderIf isTrue={deliveryFee != 0}>
        <div className='py-2 d-flex align-items-center justify-content-between'>
          <p className='mb-0'>Tracked 24 delivery</p>
          <span className='fs-6 fw-bolder'>£{formatNumber(deliveryAmount)}</span>
        </div>
      </RenderIf>

      <RenderIf isTrue={!isEmpty(deliveryAddress)}>
        <div className='py-2 d-flex justify-content-between flex-column'>
          <p className='mb-1 fw-bold'>Delivery address</p>
          {deliveryAddress.addressLine1 && <span className='fw-light'>{deliveryAddress.addressLine1}</span>}
          {deliveryAddress.addressLine2 && <span className='fw-light'>{deliveryAddress.addressLine2}</span>}
          {deliveryAddress.addressLine3 && <span className='fw-light'>{deliveryAddress.addressLine3}</span>}
          {deliveryAddress.addressLine4 && <span className='fw-light'>{deliveryAddress.addressLine4}</span>}
          {deliveryAddress.postCode && <span className='fw-light'>{deliveryAddress.postCode}</span>}
          {deliveryAddress.city && <span className='fw-light'>{deliveryAddress.city}</span>}
          {deliveryAddress.country && <span className='fw-light'>{deliveryAddress.country}</span>}
        </div>
      </RenderIf>

      <hr/>
        <div className='py-2 d-flex align-items-center justify-content-between'>
          <p className='mb-0'>Total to pay</p>
          <span className='fs-5 fw-bold'>£{formatNumber(totalToPay)}</span>
        </div>
      <hr/>
    </div>
  );
}