import React, { useEffect } from 'react'
import styles from './DeliveryPill.module.scss'
import RenderIf from '../../RenderIf/RenderIf'
import {Tooltip} from 'bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export default function DeliveryPill(props) {
  const {deliveryOption, nextDayPrice, nextDayTitle, active, updateDeliveryOption, disabled, deliveryInfo, deliveryPrice} = props

  const handleUpdateDeliveryOption = (option) => {
    updateDeliveryOption && updateDeliveryOption(option)
  }

  useEffect(() => {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl))
  }, [deliveryPrice])

  const deliveryFee = deliveryInfo?.deliveryFee || 0
  const deliveryFeeToDisplay = deliveryPrice != 0 ? `£${parseFloat(deliveryInfo?.deliveryFee).toFixed(2).toString().replace(".00", "")}` : 'FREE'
  
  const deliveryMaxSpend = deliveryInfo?.deliveryMaxSpend || 0
  const deliveryMaxSpendToDisplay = deliveryFee != 0 ? parseFloat(deliveryMaxSpend).toFixed(2).toString().replace(".00", "") : ''

  return (
    <div className={`${styles.deliveryPill} ${active ? styles.active : ""} ${disabled ? styles.disabled : ""}`} onClick={handleUpdateDeliveryOption}>
      <div className="d-flex align-items-center justify-content-between">
        <div className={"me-3 "+styles.checkBoxCircle}>
          {active ? <FontAwesomeIcon icon={faCheck} /> : ""}
        </div>
        <div className="row align-items-center w-100">
          <div className="col col-md-auto text-break">
            <h5 className="m-0">{deliveryOption == 0 ? "Royal Mail Tracked 24" : `${nextDayTitle}`}</h5>
          </div>
          <div className="ps-0 px-md-auto col-auto">
            <div className={styles.price+" ms-1 col-auto"}>({deliveryOption == 0 || nextDayPrice == 0 ? deliveryFeeToDisplay : `£${parseFloat(nextDayPrice)}`})</div>
          </div>
          <div className="ms-auto col-auto">
            {props.showChangeBtn ? <a className={`${styles.change} ms-auto`}>Change</a> : null}
          </div>  
        </div>
      </div> 
    </div>
  );
}