import React, { Component } from 'react'

export default class PartnerFooter extends Component {
  render() {
    return (
      <div id="partnerFooter" className="row mb-5 mb-lg-0">
        <img src="img/Forth_Black_LOGO.svg" alt="forthLogo" className=""/>
        <p className="col-12 pb-2">The tests Forth offers are intended to improve wellbeing and optimise sports performance. They are not intended as a substitute for medical diagnosis. </p>

        <p className="col-12">&copy;{new Date().getFullYear()} Humankind Ventures Ltd.</p>
      </div>
    )
  }
}
