import React from 'react'
import RenderIf from '../../../RenderIf/RenderIf';
import LoadingSpinner from '../../LoadingSpinner';
import styles from './InputEmail.module.scss'

const InputEmailRef = React.forwardRef(({label, labelClassName, name, id, className, isLoading, required, ...rest}, ref) => {

  return (
    <>
      <RenderIf isTrue={label}>
        <label htmlFor={id} className={`${labelClassName} ${styles.label}`}>{label}</label>
      </RenderIf>
      
      <div className={`${styles.forthInput}`}>
        <input {...rest} type="email" name={name} id={id} ref={ref} className={`${className}`} required={required} />
        <RenderIf isTrue={isLoading}>
          <div className={`${styles.spinnerContainer}`}>
            <LoadingSpinner/>
          </div>
        </RenderIf>
      </div>
    </>
  );
})

export default InputEmailRef