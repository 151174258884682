import RenderIf from "../../RenderIf/RenderIf"
import styles from "./Slider.module.scss"

export default function Slider({onChange= () => {}, name = "", options = [], defaultValue = "", step = 1, className = "", ...rest}) {
  let min = 0, max = 0
  if(options){
    min = Math.min(...options.map(option => parseInt(option.value)))
    max = Math.max(...options.map(option => parseInt(option.value)))
  }


  const minLabel = options.find(option => option.value == min)?.name
  const maxLabel = options.find(option => option.value == max)?.name
  const initialValue = defaultValue ? defaultValue : max / 2 

  return (
    <>
      <input type='range' min={min} max={max} onChange={onChange} list={`legend`} name={name} className={`${styles.input} ${className}`} step={step} defaultValue={initialValue} {...rest}/>
      <RenderIf isTrue={minLabel !== "" || maxLabel !== ""}>
        <datalist id={`legend`} className="d-flex justify-content-between">
          {minLabel !== "" && <option value={min}>{minLabel}</option>}
          {maxLabel !== "" && <option value={max}>{maxLabel}</option>}
        </datalist>
      </RenderIf>
    </>
  )
}