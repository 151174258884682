import base64 from 'base-64' 
import find from 'lodash/find';
import { roundNumber } from './roundNumber';

export default function calculateBasket(value) {
  let user = value['user'];
  let cart = value['cart'];
  let tests = value['tests'];
  let basket = value['basket'];
  let phlebotomyOption = value['phlebotomyOption'];
  let stateVoucher = localStorage.getItem('voucher');
  let voucher = value['voucher'];
  let partner = value['partner'];
  let partnerDiscountApplied = 0
  let phlebotomyPrices = value['phlebotomyPrices'];
  let partnerDiscount = 0;
  let deliveryOption = value["deliveryOption"];
  const deliveryInfo = value['deliveryInfo'];
  const voucherIncludeDelivery = voucher?.includeDelivery || false

  if (!voucher) {
    voucher = JSON.parse(stateVoucher); 
  } 

  let sale = false;  
  let testPrice = 0.00;
  let orderDiscount = 0;
  let orderDiscountTotal = 0;
  let vouchersToDiscount = '';
  let singleTestPrice = 0;
  if (voucher) {
    vouchersToDiscount = voucher.vouchersToDiscount;
  }   
  let maxDiscount = Object.keys(partner).length > 0 && partner[0].partnerCommissionCap ? partner[0].partnerCommissionCap : 0;
  let subtotal = 0.00;
  let subscription = false; 
  let subscriptionInfo = ''; 
  let giftCardTotal = 0.00; 
  let subquentialAmounts = 0;
  let basketLength = basket.length; 
  let items = []; 
  let clinicPrice = parseFloat(value['phlebotomyPrices'][5].phlebotomyPrice);
  let showDiscountField = false;
  let deliveryPrice = 0.00;
  let nextDayDeliveryPrice = parseFloat(value['nextDayDeliveryPrice']) || 0.00;
  let basketItemPrice = 0.00;

  Object.keys(basket).map(function(key) { 
    find(tests, (test) => { 
      if (test.testId === basket[key][0]) {
        items.push(test)
      } else {
        return false;
      }
    }) 
  });

  let grouped = _.mapValues(_.groupBy(items, 'testId'));
  
  let quantityMoreThanOne = false;;
  Object.keys(grouped).reverse().map(function(key) {
    if (grouped[key].length > 1) {
      quantityMoreThanOne = true;
    }
  });


   

  if (Object.keys(basket).length > 0) {
    Object.keys(basket).map(function(key) { 
      orderDiscount = 0;
      basketItemPrice = 0.00;
      find(tests, (test) => { 
        if (test.testId === basket[key][0]) { 
          if (test.subscriptionAvailable == 1 && value["subscriptionOption"] != 0) {
            subscription = true; 
          } 
          if (basket[key][4] != "0.00") {
            if (voucher) { 
              subtotal = subtotal + parseFloat(basket[key][3]);
              basketItemPrice = basket[key][3];
            } else {
              if (Object.keys(partner).length > 0 && parseInt(partner[0].partnerDiscount) != 0 && !voucher) {
                subtotal = subtotal + parseFloat(basket[key][3]);
                sale = false;
                basketItemPrice = basket[key][3];
              } else {
                subtotal = subtotal + parseFloat(basket[key][4]);
                sale = true;
                basketItemPrice = basket[key][4];
              }
            }
             
          } else {
            subtotal = subtotal + parseFloat(basket[key][3]);
            basketItemPrice = basket[key][3];
          }

          if (basketItemPrice > 999.99) {
            nextDayDeliveryPrice = 0.00;
          }
          if (value["step"] == 0 && Object.keys(value["globalPlusTests"]).length > 0) {
            let plusTests = value["globalPlusTests"][test.testId];
            let upsellTests = test.upsellTests;
            if(plusTests && upsellTests){
              Object.keys(upsellTests).map(function(key) {  
                Object.keys(plusTests).map(function(key2) {  
                  if(plusTests[key2] && key2 == upsellTests[key].testId) {  
                    subtotal = subtotal + parseFloat(upsellTests[key].testPrice);
                    testPrice = testPrice + parseFloat(upsellTests[key].testPrice);
                  }  
                }); 
              });
            }
          } 

          if (value["step"] == 0 && (value["globalHCtests"] && Object.keys(value["globalHCtests"]).length > 0)){
            const hcPlusTests = value["globalHCtests"][test.testId];
            const upsellTests = test.upsellTests;
            
            if(upsellTests && hcPlusTests){
              const hcPrice = hcPlusTests.reduce((newPrice, test) => {
                if(test.newTestPrice) return newPrice + parseFloat(test.newTestPrice)
                return newPrice + parseFloat(test.testPrice)
              }, 0) 
              subtotal = subtotal + parseFloat(hcPrice);
              testPrice = testPrice + parseFloat(hcPrice);
            }
          }

          if (voucher && vouchersToDiscount.length > 0)  {
            vouchersToDiscount.forEach(function (item) {
              if (test.testId === item.testId) {
                singleTestPrice = parseFloat(basket[key][3]); 
                if (voucher.giftCode == 1 && voucher.discountType == 1 && basket[key][4] != "0.00") {
                  singleTestPrice = parseFloat(basket[key][4]);
                } 
                if (voucher.discountType == 1) {
                  if (singleTestPrice  <= voucher.discountAmount) {
                    orderDiscount = singleTestPrice;
                  } else {
                    orderDiscount = voucher.discountAmount;
                  }
                } 
                if (voucher.discountType == 2) {
                  orderDiscount = singleTestPrice  * (voucher.discountAmount/100);
                } 
                singleTestPrice = singleTestPrice - orderDiscount;
                testPrice = testPrice + singleTestPrice;
              } else { 
 
                if (parseFloat(basket[key][4]) > 0) {
                  testPrice = testPrice + parseFloat(basket[key][4]); 
                } else {
                  testPrice = testPrice + parseFloat(basket[key][3]);
                } 
              }
            });
          } else if (voucher && vouchersToDiscount.length == 0){ 
            if (voucher.giftCode == 1 && voucher.discountType == 1 && basket[key][4] != "0.00") {
              testPrice = testPrice + parseFloat(basket[key][4]);
            } else {
              testPrice = testPrice + parseFloat(basket[key][3]);
            } 
          } else { 
            if (parseFloat(basket[key][4]) > 0) {
              if (Object.keys(partner).length > 0) {
                if (maxDiscount > 0) {
                  if (parseFloat(cart.totalSaving) >= maxDiscount) {
                    testPrice = testPrice + parseFloat(basket[key][3]); 
                  } else {
                    testPrice = testPrice + parseFloat(basket[key][4]);
                  }
                } else {
                  testPrice = testPrice + parseFloat(basket[key][4]);
                } 
              } else {
                testPrice = testPrice + parseFloat(basket[key][4]);
              }
                
            } else {
              testPrice = testPrice + parseFloat(basket[key][3]); 
              if (Object.keys(partner).length > 0) {
                orderDiscount = basket[key][3] * partner[0].partnerDiscount/100
                testPrice = parseFloat(testPrice - orderDiscount)
                partnerDiscountApplied = 1
              }  
            }  
          }

          if (Object.keys(partner).length > 0 && parseInt(partner[0].partnerDiscount) != 0 && !voucher && !partnerDiscountApplied) {
            orderDiscount = parseFloat(cart.totalSaving);
          } 
        }


      }) 

 

      if (phlebotomyOption == 5) { 
        if (voucher && voucher.includePhlebotomy) { 
          if (voucher.discountType == 2) { 
            if (voucher.phlebotomyOnly) { 
              orderDiscount = clinicPrice  * (voucher.discountAmount/100); 
              testPrice = testPrice + (clinicPrice - orderDiscount); 
            } else { 
              orderDiscount = orderDiscount + clinicPrice;
              testPrice = testPrice + clinicPrice; 
            } 
          } else {
            if (voucher.phlebotomyOnly) { 
              orderDiscount = voucher.discountAmount;
              if (orderDiscount > clinicPrice * basket.length) orderDiscount = parseFloat(clinicPrice * basket.length);
              testPrice = subtotal + ((clinicPrice * basket.length) - orderDiscount); 
            } else { 
              orderDiscount = voucher.discountAmount;
              testPrice = subtotal + ((clinicPrice * basket.length) - orderDiscount); 
              if (orderDiscount > (subtotal + (clinicPrice * basket.length))) {
                orderDiscount = parseFloat(subtotal + ((clinicPrice * basket.length)));
                testPrice = 0
              }
            } 
            //testPrice = testPrice + clinicPrice;
          }   
        } else { 
          testPrice = testPrice + clinicPrice; 
        } 
      } 
 
      orderDiscountTotal = parseFloat(orderDiscountTotal) + parseFloat(orderDiscount);
      if (Object.keys(partner).length > 0 && parseInt(partner[0].partnerDiscount) != 0 && !voucher && !partnerDiscountApplied) {
        orderDiscountTotal = parseFloat(cart.totalSaving);
      } 
    });  
    orderDiscount = parseFloat(orderDiscountTotal);

    if ((Object.keys(partner).length > 0) && (parseFloat(cart.totalSaving) >= 50)) {
      testPrice = parseFloat(testPrice) - parseFloat(cart.totalSaving)
    }

    let phlebotomyPrice = parseFloat(phlebotomyPrices[2].phlebotomyPrice);
    if (phlebotomyOption == 2) { 
      if (voucher && voucher.includePhlebotomy) { 
        if (voucher.discountType == 2) { 
          if (voucher.phlebotomyOnly) { 
            orderDiscount = phlebotomyPrice  * (voucher.discountAmount/100); 
            testPrice = testPrice + (phlebotomyPrice - orderDiscount); 
          } else { 
            orderDiscount = orderDiscount + phlebotomyPrice;
            testPrice = testPrice + phlebotomyPrice; 
          } 
        } else {
          orderDiscount = voucher.discountAmount;
          if (voucher.phlebotomyOnly) { 
            if (orderDiscount > phlebotomyPrice) {
              orderDiscount = phlebotomyPrice;
            }
            testPrice = subtotal + (phlebotomyPrice - orderDiscount); 
          } else { 
            testPrice = subtotal + phlebotomyPrice - orderDiscount; 
            if (orderDiscount > (subtotal + phlebotomyPrice)) {
              orderDiscount = parseFloat(subtotal + phlebotomyPrice);
              testPrice = 0
            }
 
          } 
        }  
      } else { 
        testPrice = subtotal + phlebotomyPrice
        if (orderDiscount != 0 && (global.config.websiteId == 5 || (Object.keys(partner).length > 0 && parseInt(partner[0].partnerDiscount) != 0 && !partnerDiscountApplied && !voucher))) {
          testPrice = testPrice - orderDiscount
        }
      } 
    }  

    if (voucher && !subscription && parseFloat(voucher.minimumSpend) != 0 && voucher.perTest == true) {
      const totalVoucherSaving = basket.reduce((acc, test) => {
        
        const testPrice = test[3];

        if (parseFloat(testPrice) >= parseFloat(voucher.minimumSpend)) {
          const discount = voucher.discountType == 1 ? voucher.discountAmount : testPrice * (voucher.discountAmount/100);
          acc += parseFloat(discount);
        }
        return acc;
      }, 0);

      testPrice = parseFloat(testPrice) - totalVoucherSaving;
      orderDiscount = parseFloat(orderDiscount) + totalVoucherSaving;
    } else if (voucher && !voucher.phlebotomyOnly) {
      if (vouchersToDiscount.length == 0)  { 
        if (voucher.includePhlebotomy != true && phlebotomyOption == 2) {
          testPrice = testPrice - phlebotomyPrice;
        } 

        if (voucher.includePhlebotomy != true && phlebotomyOption == 5) {
          testPrice = testPrice - (clinicPrice * basketLength);
        } 


        if (voucher.discountType == 1) {
          if (testPrice  <= voucher.discountAmount) {
            orderDiscount = testPrice;
          } else {
            orderDiscount = voucher.discountAmount;
          } 
          testPrice = testPrice - orderDiscount;
        } 

        if (voucher.discountType == 2) { 
          orderDiscount = testPrice  * (voucher.discountAmount/100);
          testPrice = testPrice - orderDiscount; 
        }

        if (voucher.includePhlebotomy != true && phlebotomyOption == 2) {
          testPrice = testPrice + phlebotomyPrice;
        }

        if (voucher.includePhlebotomy != true && phlebotomyOption == 5) {
          testPrice = testPrice + (clinicPrice * basketLength);
        } 

        if (deliveryOption == 0 && voucherIncludeDelivery) {
          const deliveryFee = parseFloat(deliveryInfo?.deliveryFee) || 0
          const deliveryMaxSpend = parseFloat(deliveryInfo?.deliveryMaxSpend) || 0
    
          deliveryPrice = (deliveryFee && testPrice < deliveryMaxSpend) ? deliveryFee : 0
          deliveryPrice = deliveryPrice * Object.values(basket).map(test => tests[test[0]]?.isTracked24 == 1).length;
          
          if (voucher.discountType == 2) { 
            const deliveryDiscount = deliveryPrice  * (voucher.discountAmount/100) ;
            deliveryPrice = deliveryPrice - deliveryDiscount
            orderDiscount = orderDiscount + deliveryDiscount
          }
          testPrice = parseFloat(testPrice) + parseFloat(deliveryPrice);
        }
      }
 
      if (voucher.discountType == 1){ 
        if (voucher.perTest == true) { 
          testPrice = testPrice - (voucher.discountAmount * (Object.keys(basket).length - 1));
          orderDiscount = orderDiscount * Object.keys(basket).length;
        } else { 
          testPrice = testPrice;
        }  
      } 
    }

    if (phlebotomyOption == 2) {
      subtotal = parseFloat(subtotal);
    } 

    if (phlebotomyOption == 5) {
      subtotal = parseFloat(subtotal);
    } 

  } 

  if (Object.keys(tests).length > 0) {
    let subscriptionAvailable;
    let subscriptionTestName;
    let subscriptionLength;
    let testOfferPrice;
    let testOfferFullPrice;

    Object.keys(basket).map(function(key) {

      let testId = parseInt(basket[key][0]);

      if ((basket[key][5] > 0 || value["subscriptionOption"] > 0) && (basket[key][6] == 0)) {
        subscriptionAvailable = tests[testId]?.subscriptionAvailable;
        subscriptionTestName = tests[testId]?.testName;
        subscriptionLength = value["subscriptionOption"];
        testOfferPrice = tests[testId]?.testOfferPrice;
        testOfferFullPrice = tests[testId]?.testPrice; 
      } 
    }); 

    let preGiftPrice = testPrice;

    if (voucher && voucher.giftCode) {
      if (voucher.discountAmount > orderDiscount) {
        giftCardTotal = voucher.discountAmount - orderDiscount;
      }
    }
    if (deliveryOption != 0) { 
      deliveryPrice = parseFloat(nextDayDeliveryPrice) * Object.keys(basket).length; 
      testPrice = parseFloat(testPrice) + parseFloat(deliveryPrice);
      subquentialAmounts = parseFloat(subquentialAmounts) + parseFloat(deliveryPrice);
    } 
    
    if (deliveryOption == 0 && !voucherIncludeDelivery) {
      const deliveryFee = parseFloat(deliveryInfo?.deliveryFee) || 0
      const deliveryMaxSpend = parseFloat(deliveryInfo?.deliveryMaxSpend) || 0

      deliveryPrice = (deliveryFee && testPrice < deliveryMaxSpend) ? deliveryFee : 0
      deliveryPrice = deliveryPrice * Object.values(basket).filter(test => tests[test[0]]?.isTracked24 == 1).length;
      testPrice = parseFloat(testPrice) + parseFloat(deliveryPrice);
    }
    
    if (Object.keys(user).length > 0 && user.checkoutCredit != 0 && user.checkoutCredit != undefined && testPrice != 0.00 && (!value["guestCheckout"] || value["guestCheckout"] == "unset")) {
      let originalPrice = testPrice;
      testPrice = testPrice - (Math.abs(user.checkoutCredit)/100);

      if (testPrice < 0.00) { 
        giftCardTotal =  Math.abs(user.checkoutCredit)/100 - originalPrice;
        testPrice = 0.00; 
      }
    } 

    let autoCreditAmount = cart.autoCreditAmount || 0.00; 
    if (subscription) { 
      if (orderDiscount == 0.00 || orderDiscount == 0) { 
        if (testOfferPrice != 0.00 || autoCreditAmount != 0.00) { 
          subquentialAmounts = parseFloat(testOfferFullPrice) + parseFloat(phlebotomyPrices[phlebotomyOption].phlebotomyPrice); 
          // subquentialAmounts = parseFloat(testOfferFullPrice); 
          if (deliveryOption != 0) {
            deliveryPrice = parseFloat(nextDayDeliveryPrice); 
          }

          subquentialAmounts = parseFloat(subquentialAmounts) + parseFloat(deliveryPrice);
          subscriptionInfo += "Your " + subscriptionTestName + " check will be charged at £" + parseFloat(testPrice)?.toFixed(2) + " then £" + subquentialAmounts.toFixed(2) + " every " + subscriptionLength + " month(s).";
        } else { 
          if(Object.keys(user).length > 0 && user.checkoutCredit != 0) {
            const testFullAmount = parseFloat(testPrice) + Math.abs(user?.checkoutCredit || 0)/100
            subscriptionInfo += "Your " + subscriptionTestName + " check will be charged at £" + parseFloat(testPrice)?.toFixed(2) + " then £" + testFullAmount.toFixed(2) + " every " + subscriptionLength + " month(s), unless you have credit that can be deducted from your balance.";  
          } else {
            subscriptionInfo += "Your " + subscriptionTestName + " check will be charged at £" + parseFloat(testPrice)?.toFixed(2) + " every " + subscriptionLength + " month(s).";
          }
        } 
      } else { 
        if (voucher && !voucher.voucherAppliesToSubscription) { 
          subquentialAmounts =  (parseFloat(preGiftPrice) + parseFloat(orderDiscount));
          if (deliveryOption != 0) {
            deliveryPrice = parseFloat(nextDayDeliveryPrice); 
            subquentialAmounts = parseFloat(subquentialAmounts) + parseFloat(deliveryPrice);
          } 
          subscriptionInfo +=  "Your " + subscriptionTestName + " check will be charged at £" + parseFloat(testPrice)?.toFixed(2) + " then £" + subquentialAmounts.toFixed(2) + " every " + subscriptionLength + " month(s).";
        } else { 
          subscriptionInfo += "Your " + subscriptionTestName + " check will be charged at £" + parseFloat(testPrice)?.toFixed(2) + " every " + subscriptionLength + " month(s). ";
        } 
      }
      if (subquentialAmounts == 0) {
        subscriptionInfo += " You can cancel your test at any time using your dashboard.";
      } else if (subscriptionAvailable == 1) { 
        subscriptionInfo += " You can cancel or change the frequency of your test at any time using your dashboard.";
      } else if (subscriptionAvailable == 2) {
        subscriptionInfo += subscriptionInfo.substr(0, -1);
        subscriptionInfo += " for a minimum of 12 months. After this period you can cancel at any time.";
      } 
    } 
    showDiscountField = false;

    if ((voucher && voucher.discountAmount != 0.00) || (Object.keys(partner).length > 0 && parseInt(partner[0].partnerDiscount) != 0)) {
      showDiscountField = true;
    } 
  } 

  let autoCreditAmount = cart.autoCreditAmount || 0.00; 
  let guestCheckout = value["guestCheckout"];
  if (guestCheckout == 'unset') {
    guestCheckout = false;
  }
    
  if (cart.autoCreditAmount != 0.00) {
    if (voucher && vouchersToDiscount.length == 0){  
      if (voucher.giftCode == 1) {
        autoCreditAmount = 0.00; 
      } 
    } 
    if (Object.keys(partner).length > 0) {
      autoCreditAmount = 0.00; 
    }
  }

  if (global.config.websiteId != 1) {
    autoCreditAmount = 0.00; 
  } 

 
  if (orderDiscount != cart.totalSaving) {
    //orderDiscount = cart.totalSaving;
  }
  return {
    "subtotal": subtotal,
    "showDiscountField": showDiscountField,
    "orderDiscount": orderDiscount,
    "deliveryPrice": deliveryPrice,
    "testPrice": roundNumber(testPrice, 2),
    "subscriptionInfo": subscriptionInfo,
    "giftCardTotal": giftCardTotal,
    "autoCreditAmount": autoCreditAmount,
    "quantityMoreThanOne": quantityMoreThanOne,
    "subscription": subscription,
    "sale": sale,
    "vouchersToDiscount": vouchersToDiscount,
    "singleTestPrice": singleTestPrice,
    "clinicPrice": clinicPrice,
    "subquentialAmounts":subquentialAmounts
  }

}
