import React, {useState, useEffect} from 'react' 
import find from 'lodash/find' 
import _, { forEach } from 'lodash';
import styles from "./CheckoutTypeOption.module.scss";
import {Modal} from 'bootstrap'
import Button from '../../GlobalComponents/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { faGift } from '@fortawesome/pro-light-svg-icons';
 
export default function CheckoutTypeOption(props) {  
  const partner = props.partner;
  let guestError = props.guestError;
  let guestCheckout = props.guestCheckout;
  let basketTestItems = props.basketTestItems;
  let errorClass = '';
  if (guestError) {
    errorClass = 'guestNoSelectError'
  }
 
  let subscriptionOrder = false;
  let cart = props.cart[0].basketArray;
  let treatAsSubscription = false;
  Object.keys(cart).map(function(key) { 
    if ((parseInt(cart[key][16]) == 1 && parseInt(cart[key][5]) > 0)) {
      subscriptionOrder = true; 
    } 
  });

  if (props.subscriptionOption == 0) {
    subscriptionOrder = false; 
  }

  if (props.foundTrueCheckTest) {
    subscriptionOrder = false;
  }
  let labId = 0;
  Object.keys(basketTestItems).map(function(key) { 
    let biomarkers = basketTestItems[key].biomarkers;
    labId = basketTestItems[key].labId; 
    forEach(biomarkers, function(value, key) { 
      if (biomarkers[key].biomarkerId == 45 && labId == 3) {
        subscriptionOrder = true; 
      }
    });
    
  });

  if (treatAsSubscription) {
    subscriptionOrder = true; 
  }
  
  const hasPreventGuestCheckoutTest = Object.values(basketTestItems).some(test => test.preventGuestPurchase == 1)

  const handleSetGuestCheckout = (value) => {
    var errorModal = new Modal(document.getElementById('phleberror'), {})
    if (props.phlebotomyOption == 2 && value == true) {
      errorModal.toggle()
    } else { 
      props.setGuestCheckoutValue(value)
    }
  }

  const switchPhlebotomy = () => {
    var myModal = document.getElementById('phleberror');
    var errorModal = Modal.getOrCreateInstance(myModal)
    
    props.setGuestCheckoutValue(true)
    errorModal.toggle()
  }

  return ( 
  <>
    <div id={styles.guestOptions} className="row pt-5 pb-4">
      <div className="col-12 text-center">
        <h2>Confirm your test options</h2>
      </div>
      <div className="col-12 mt-4">
        <h3 className="mb-2 mt-0 text-center w-100">Who is the test for</h3>
      </div> 
      <div className="col-12">
        <div className="mt-3 row row-eq-height justify-content-center">
          <React.Fragment>   
          
              <div className="col-12 mb-3 text-start" onClick={() => handleSetGuestCheckout(false)}>
                <div className={styles.phlebotomyOption+" p-3" + errorClass + ' ' +  (guestCheckout == false || guestCheckout == "unset" ? styles.selected : '')}>
                  <div className="d-flex justify-content-between"> 
                    
                    <div className={"me-3 "+styles.checkboxContainer}>
                      <div className={styles.checkBoxCircle}>{guestCheckout == false || guestCheckout == "unset" ? <FontAwesomeIcon icon={faCheck} /> : ''}</div>
                    </div>
                    <div>
                      <div className={styles.phlebotomyTitle+" d-flex justify-content-between"}>
                        <h4 className="m-0">For me</h4>
                        <FontAwesomeIcon icon={faUser} />
                      </div>

                      {subscriptionOrder ? ( 
                        <p className="mt-2">You are opting into a subscription test that can be cancelled at any time. As you are starting a subscription, an account is required during purchase to allow you to manage your subscription.</p>
                      ) : hasPreventGuestCheckoutTest ? (
                        <p className="mt-2">On the next step, please provide information of the person submitting their sample. The details provided will be printed onto the lab forms ready for when the sample arrives for analysis.</p>
                      ) : props.cart[0].basketArray.length > 1 ? ( 
                        <p className="mt-2">If all test kits in your basket are for you, we will make things nice and easy by activating your kits straight away and printing your details onto the lab request forms.</p>  
                      ) : (
                        <p className="mt-2">If this test kit is for you, we will make things nice and easy by activating your kit straight away and printing your details onto the lab request form.</p>
                      )} 
                    </div> 
                  </div>
                </div>
              </div>
             
              {!subscriptionOrder && !hasPreventGuestCheckoutTest && (
              <div className="col-12 mb-3 text-start" onClick={() => handleSetGuestCheckout(true)}>
                <div className={styles.phlebotomyOption+" p-3" + errorClass + ' ' +  (guestCheckout == true ? styles.selected : '')}>
                  <div className="d-flex justify-content-between"> 
                    
                    <div className={"me-3 "+styles.checkboxContainer}>
                      <div className={styles.checkBoxCircle}>{guestCheckout == true ? <FontAwesomeIcon icon={faCheck} /> : ''}</div>
                    </div>
                    <div>
                      <div className={styles.phlebotomyTitle+" d-flex justify-content-between"}>

                        {props.cart[0].basketArray.length > 1 ? ( 
                          <h4 className="m-0">One or more is for someone else</h4>
                        ) : ( 
                          <h4 className="m-0">For someone else</h4> 
                        )} 
                        <FontAwesomeIcon icon={faGift}/>
                      </div>


                      {props.cart[0].basketArray.length > 1 ? (  
                        <p className="mt-2">If one or more kits are for someone else {Object.keys(partner).length > 0 && props.partner[0].partnerDiscount > 0 ? ( <>(e.g. your customer/patient)</> ) : ( <>(e.g. a gift)</> )} we will send a blank lab request form that will contain information on how to set up an account or assign to an existing account and activate the kit. The recipient of the kits must be 18 or over.</p>
                      ) : ( 
                        <p className="mt-2">If this kit is for someone else {Object.keys(partner).length > 0 && props.partner[0].partnerDiscount > 0 ? ( <>(e.g. your customer/patient)</> ) : ( <>(e.g. a gift)</> )} we can send a blank lab request form that will contain information on how to set up an account and activate the kit. The recipient of the kit must be 18 or over.</p>
                      )} 
                     
                    </div> 
                  </div>
                </div>
              </div>
              )}
      

            {props.guestError == true && 
              <div className="col-12 alert alert-danger text-center mt-3">
                <FontAwesomeIcon icon={faInfoCircle}/> Please select who this test is for.
              </div>
            } 
          </React.Fragment> 
        </div>
      </div>   
    </div> 


    <div className="modal deleteMarkerModal" tabIndex="-1" role="dialog" id={"phleberror"}>
      <div className="modal-dialog modal-dialog-centered mx-auto my-0" role="document">
        <div className={"modal-content px-4 shadow-sm"}>
          <div className="border-0 modal-header text-center">
            <h5 className={styles.modalText + " mt-4 modal-title w-100 biomarkerModalTestName no-letter-spacing"}>Unfortunately we are unable to provide a home phlebotomy appointment if the kit is intended for someone else. Information of the person having their sample collected is required before the dispatch of the kit. Are you happy to change your home phlebotomy appointment to be conducted at one of our partner clinics?</h5>
          </div> 
          <div className={`modal-body mb-4`}>
            <div className="row">
              <div className="col-6">
                <Button variant="primary-outline" className="w-100 h-100" data-bs-dismiss="modal" aria-label="Close">No change</Button>
              </div>
              <div className="col-6">
                <Button variant="primary" className="w-100 h-100" onClick={()=>switchPhlebotomy()}>Change to partner clinic</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </>

  )
}