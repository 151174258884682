import React, {useState} from 'react'
import isEmpty from 'lodash/isEmpty';

import {RemoveFromBasket, TestUpseller, QuantityToggle, BiomarkersAnalysed, SubscriptionOptions, CustomiseSubscription} from '../../../components'
import RenderIf from '../../RenderIf/RenderIf';
import TestUpHealthCoachSeller from '../TestUpHealthCoachSeller/TestUpHealthCoachSeller';

import styles from "./BasketItem.module.scss";

export default function BasketItem(props) {  
  const [quantity, setQuantity] = useState(props.grouped.length);  
  const [disableQuantity, setDisableQuantity] = useState(false);  
  const [showUpdateButton, setShowUpdateButton] = useState(false);  
  const partner = props.partner;  
  
  let nonCustomisable = props.grouped[0][17]
  const isBiomarkerTrackingCheckout = ["appdev.biomarkertracking.com", "shop.biomarkertracking.com"].some(domain => window.location.hostname.includes(domain) || (window.location.hostname == "localhost" && !isEmpty(props.partner)))
  if(props.test?.isBespokeWithNoBase == 0 && isBiomarkerTrackingCheckout){
    nonCustomisable = 1;
  }

  let showSubscriptionTag = true;  
  let partnerDiscount = false;

  let subscription = 0;
  if(props.test.subscriptionAvailable == 1 && (props.grouped[0][4] == "0.00" )) {
    subscription = 1;
  }
  
  if (Object.keys(partner).length > 0 && parseInt(partner[0].subscriptionTestsAsSingles)== 1) {
    showSubscriptionTag = false; 
  }

  if (Object.keys(partner).length > 0 && parseInt(partner[0].partnerDiscount)) {
    partnerDiscount = true; 
  }
 
  let colSize = 'col-8 col-md-12 col-lg-8 d-none d-md-block d-lg-block';
  let align =  'align-items-center'
  let align2 = 'align-items-center'

  if (props.step == 0) { 
    colSize = 'col-6 col-md-12 col-lg-6 d-none d-md-block d-lg-block'; 
    align = 'align-items-center align-items-md-start align-items-lg-start';
    align2 = '';
  }

  let testPrice = props.grouped[0][3];
  if (partnerDiscount) {
    testPrice = props.grouped[0][3];
  } else {
    if(props.grouped[0][4] != "0.00" && props.orderVoucherId == 0) {
      testPrice = props.grouped[0][4];
    } else { 
      testPrice = props.grouped[0][3];
    }
  }

  let plusTestPrice = 0.00;
  
  if (props.step == 0 && Object.keys(props.globalPlusTests).length > 0) {
    let plusTests = props.globalPlusTests[props.test.testId];
    let upsellTests = props.test.upsellTests;

    if(upsellTests && plusTests){
      Object.keys(upsellTests).map(function(key) {  
        Object.keys(plusTests).map(function(key2) { 
          if(plusTests[key2] && key2 == upsellTests[key].testId) {
            plusTestPrice = parseFloat(plusTestPrice) + parseFloat(upsellTests[key].testPrice);
          }  
        });
  
      });
    }
  }

  if (props.step == 0 && Object.keys(props.globalHCTests).length > 0){
    const hcPlusTests = props.globalHCTests[props.test.testId];
    const upsellTests = props.test.upsellTests;

    if(upsellTests && hcPlusTests){
      plusTestPrice = hcPlusTests.reduce((newPrice, test) => {
        if(test.newTestPrice) return newPrice + parseFloat(test.newTestPrice)
        return newPrice + parseFloat(test.testPrice)
      }, 0)
    }
  }

  let disableFlex = props.test.subscriptionAvailable;

  let autoCreditAmount = props.test.autoCreditAmount;
  let stateVoucher = localStorage.getItem('voucher');
  let voucher = props.voucher;
  if (!voucher) {
    voucher = JSON.parse(stateVoucher); 
  } 
  let vouchersToDiscount = '';
  if (voucher) {
    vouchersToDiscount = voucher.vouchersToDiscount;
  }  

  if (autoCreditAmount != 0.00) {
    if (voucher && vouchersToDiscount.length == 0){  
      if (voucher.giftCode == 1) {
        autoCreditAmount = 0.00; 
      } 
    }
    
    let guestCheckout = props.guestCheckout;
    if (guestCheckout == 'unset') {
      guestCheckout = false;
    }
    
    if (Object.keys(partner).length > 0) {
      autoCreditAmount = 0.00; 
    }
  }

  if (global.config.websiteId != 1) {
    autoCreditAmount = 0.00; 
  }

   

  const changeDisableQuantity = (value) => {
    setDisableQuantity(value)
    setQuantity(1);
  }
   
  let ageAlert; 
  if (props.test.minAgeLimit != 18 || props.test.maxAgeLimit != 125){
    ageAlert = "You must be ";
    if (props.test.minAgeLimit != 18) {
      ageAlert += "over " + props.test.minAgeLimit + " ";
    }
    if (props.test.minAgeLimit != 18 && props.test.maxAgeLimit != 125) {
      ageAlert += "and ";
    } 
    if (props.test.maxAgeLimit != 125) {
      ageAlert += "under " + props.test.maxAgeLimit + " ";
    }
    ageAlert += "to preform this test";
  }

  const showHealthCoachTests = 
    props.test.showCheckoutHCRecommend == 1 && 
    props.test.upsellTests?.filter(test => test.isHealthCoachTest == 1).length > 0 && 
    global.config.websiteId == 1 && 
    isEmpty(props.partner);

  return ( 

    <>
    <div className={`${styles.basketItem} mb-3`}>
      <div className="row align-items-center">
        <div className="col-4 pe-3 position-relative col-lg-3">
          <img className={`img-fluid ${styles.productImage} ${props.step == 0 ? '' : styles.productImageSmall}`} src={props.testImage} />
        
          {props.step != 0 && (
            <div className={styles.itemQuantity}>{props.grouped.length}</div>
          )}
        </div>
        <div className={`col-8 ${props.step == 0 ? 'col-lg-9' : 'col-lg-8'}`}>
          
          <div className="row">
            <div className="col-12">  
              {ageAlert &&
                <div className="mb-2"> 
                  <span className={"d-none d-md-none d-lg-inline-block ms-0 me-0 me-sm-3 "+styles.testType + " " + styles.danger }>{ageAlert}</span>
                </div>
              }
              {autoCreditAmount != 0 && props.step == 0 &&
                <div className="mb-2"> 
                  <span className={"d-none d-md-none d-lg-inline-block ms-0 me-0 me-sm-3 "+styles.testType + " " + styles.autoCreditAmount}>GET <strong>&pound;{parseFloat(autoCreditAmount).toFixed(2)}</strong> OFF YOUR NEXT ORDER*</span>
                </div>
              }

              {props.test.subscriptionAvailable == 1 && showSubscriptionTag  &&
                <div className="mb-2"> 
                  <span className={"d-none d-md-none d-lg-inline-block ms-0 me-0 me-sm-3 "+styles.testType}>Cancel Anytime</span>
                </div>
              }  
                 
              {props.grouped[0][4] != "0.00" && props.orderVoucherId == 0 && !partnerDiscount &&
                <div className="mb-2"> 
                  <span className={"d-none d-md-none d-lg-inline-block ms-0 me-0 me-sm-3  "+styles.testType + " " + styles.sale}>Sale: Was £{props.grouped[0][3]}</span>
                </div>
              }  
            </div>

            <div className="col-12">
              <h2 className={ "mb-0 "+styles.testTitle} dangerouslySetInnerHTML={{__html: props.test.testName}}></h2>
            </div>

            <div className="col-12">
              <div className={styles.testPrice}>  
                <span className={styles.priceIcon}>&pound;</span> 
                {partnerDiscount ? (
                  <React.Fragment>
                    {(parseFloat(props.grouped[0][3]) + plusTestPrice).toFixed(2)}
                  </React.Fragment>
                ) : (
                  <>
                  {props.grouped[0][4] != "0.00" && props.orderVoucherId == 0  ? (
                    <React.Fragment>
                      {(parseFloat(props.grouped[0][4]) + plusTestPrice).toFixed(2)}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {(parseFloat(props.grouped[0][3]) + plusTestPrice).toFixed(2)}
                    </React.Fragment>
                  )}
                  </>
                )} 
                {props.test.subscriptionAvailable == 1 && (props.grouped[0][4] == "0.00" ) && ( <span className={styles.perTest}>Per test</span> )}
              </div>
            </div> 
            {props.step == 0 && (            
              <div className="col-12 mt-3">
                <QuantityToggle handleQuantityChange={props.handleQuantityChange} quantity={quantity} setQuantity={setQuantity} setShowUpdateButton={setShowUpdateButton} disableQuantity={disableQuantity} testPrice={testPrice} grouped={props.grouped} customise={props.customise} test={props.test} step={props.step} small={true}  phlebotomyOption={props.phlebotomyOption} testName={props.test.testName} testId={props.testId} partner={props.partner} quantityLoading={props.quantityLoading}/>
              </div>
            )}

          </div>           
        </div> 
      </div>
      {props.step == 0 &&
        <>

        <RenderIf isTrue={props.test.biomarkers.length > 1}>
          <div className="row">
            <div className="col-12">
              <BiomarkersAnalysed testId={props.testId} test={props.test}/>
            </div>
          </div>
        </RenderIf>
         
          {props.test.subscriptionAvailable == 1 && (
            <SubscriptionOptions handleSubscriptionChange={props.handleSubscriptionChange} partner={props.partner} subscriptionOption={props.subscriptionOption} testId={props.testId} test={props.test}/>
          )}
          <div className="row">
            <div className="col-12">
              <RenderIf isTrue={nonCustomisable == 0}>
                {showHealthCoachTests ? (
                  <TestUpHealthCoachSeller updatePhlebotomyOption={props.updatePhlebotomyOption} setPlusTests={props.setGlobalHCTests} plusTestsSelected={props.globalHCTests[props.testId]} quantity={quantity} disableQuantity={changeDisableQuantity} handleQuantityChange={props.handleQuantityChange} test={props.test} testId={props.testId} phlebotomyOption={props.phlebotomyOption} showEdit={props.grouped[0][9]} voucher={props.voucher}/>
                ) : props.test.subscriptionAvailable == 1 && (props.grouped[0][4] == "0.00" ) ? (
                  <CustomiseSubscription phlebotomyOption={props.phlebotomyOption} test={props.test} testId={props.testId} showEdit={props.grouped[0][9]} voucher={props.voucher}/>
                ) : props.test.upSellTests && props.test.upSellTests.length > 0 ? (
                  <TestUpseller relevantMarkersReason={props.test.relevantMarkersReason} setPlusTests={props.setPlusTests} quantity={quantity} disableQuantity={changeDisableQuantity} handleQuantityChange={props.handleQuantityChange} test={props.test} testId={props.testId} phlebotomyOption={props.phlebotomyOption} showEdit={props.grouped[0][9]} voucher={props.voucher}/>
                ) : (
                  <CustomiseSubscription phlebotomyOption={props.phlebotomyOption} test={props.test} testId={props.testId} showEdit={props.test.bespokeTest == 1} voucher={props.voucher} message="Add extra biomarkers to this test from as <strong>little as £6.</strong>"/>
                )}
              </RenderIf>
            </div>
          </div> 
        </>
      }  
    </div> 
  </> 
  )
}