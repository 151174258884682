import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import $ from 'jquery'; 
import { NavLink } from 'react-router-dom'

import { logout } from '../actions/authentication-actions'
import { loadLatestOrderStatus, loadLastOrderDate } from '../actions/order-actions'
import { loadAllTests } from '../actions/test-actions'


import BackButton from '../components/modules/BackButton' 
import { faBars, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class Menus extends Component {

  constructor(props) {
    super(props); 
    this.handleLogout = this.handleLogout.bind(this);
  }
 
  static propTypes = {
    authentication: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired, 
    basket: PropTypes.object.isRequired, 
    logout: PropTypes.func.isRequired,
    loadAllTests: PropTypes.object.isRequired
  }

  componentWillMount() {
    this.props.loadAllTests('me');
  }

  componentDidMount() { 
    var page = this;
    $(function() {
      $("#menu-toggle").click(function(e) {
        e.preventDefault();
        $("#wrapper").toggleClass("toggled");
      });
      $(".dropdown-menu > li a").on("click", function(e) {
        var link = $(this).attr('href');
        page.props.history.push(link)
      });
      $(".dropdown-menu .logout").on("click", function(e) {
        e.stopPropagation(); 
        page.handleLogout();
      });
    });

  }

  handleLogout() {
    this.props.history.push('/');
    this.props.logout();
    this.props.loadLatestOrderStatus('me');
    this.props.loadLastOrderDate('me');
  }

  basket() {

    var basketItems = this.props.basket; 
    return (
      <Link to="/checkout" id="toggleBasket" className="basket-wrapper">
        <div className="basket">
          <FontAwesomeIcon icon={faShoppingCart}/>
          <div className="basket-total">{Object.keys(basketItems).length}</div>
        </div>
      </Link>

    )
  }

  render() {
    const order = this.props.order;
    const lastOrder = this.props.lastOrder;
    var orderId = Object.keys(order)[0];
    var lastOrderId = Object.keys(lastOrder)[0];
    var partnerId = 0;
    var logo;

    if (global.config.websiteId == 1 || global.config.websiteId == 3) {
      
      logo = <img src="/img/forthGradientIcon.png" className="header-logo"/>
                  
                     
    } else {
 
      logo =  <div>
              <svg version="1.1" id="Layer_1" viewBox="0 0 147.4 147.4"> 
              <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="25.4723" y1="24.8219" x2="142.9642" y2="142.3137" gradientTransform="matrix(1 0 0 -1 0 147.4016)">
                <stop  offset="0" stopColor="#E0363E"/>
                <stop  offset="1" stopColor="#FAC03C"/>
              </linearGradient>
              <path  fill="url(#SVGID_1_)" className="st0" d="M74.1,5.1C34.4,5.1,2.6,38.7,5.4,78.8c2.5,34.9,31.2,62.6,66.1,63.9c38.5,1.5,70.2-28.7,71.5-66.6l0,0v-71H74.1
                z M109.1,75.2L109.1,75.2c-0.7,19.7-17.3,35.2-37.5,33.9c-17.3-1.2-31.6-15.4-32.5-32.7C37.8,56,53.9,39,74.1,39h35V75.2z"/>
              </svg>
              </div>
    
    }
    var logoUrl = 'https://sys.forthwithlife.co.uk'
    if (Object.keys(order).length > 0) {
      partnerId = order[orderId].partnerId; 
      if (partnerId != 0 && Object.keys(order[orderId].partner).length > 0 && order[orderId].partner.appLogo != '') {
        logo = <img src={logoUrl + order[orderId].partner.appLogo} />;
      }
    } 

    if (Object.keys(lastOrder).length > 0 && lastOrder[lastOrderId].partnerId != 0) {
      partnerId = lastOrder[lastOrderId].partnerId; 
      if (partnerId != 0 && lastOrder[lastOrderId].partner.appLogo != '') {
        logo = <img src={logoUrl + lastOrder[lastOrderId].partner.appLogo} />
      }
    } 
    
    const user = this.props.user;
    const userId = user.iD; 
    const stripeCustomerId = user.customerStripeId;
    const authentication = this.props.authentication;
    var backButton = '';
    if (this.props.location.pathname != "/") {
      backButton = <BackButton />;
    }
    var profilePictureStyle = {};
    if (user.customerImage) {
      if (user.customerImage.includes("/public/uploads/")) {
        profilePictureStyle = {
          backgroundImage: 'url(https://api.forthwithlife.co.uk' + user.customerImage + ')'
        };
      } else if (user.customerImage.includes("forth-image-uploads.s3")) {
        profilePictureStyle = {
          backgroundImage: 'url(' + user.customerImage + ')'
        };
      } else {
        profilePictureStyle = {
          backgroundImage: 'url(https://sys.forthwithlife.co.uk' + user.customerImage + ')'
        };
      }
       
    }

    const matchWorkaround = (pathname) => (isMatch, location) => isMatch || location.pathname.startsWith('/result') || location.pathname.startsWith('/category');
    const isBespokePage = window.location.pathname.indexOf('amend-subscription') != -1

    return (
      <header className="sticky-top mb-3">
        <nav className={`navbar head-nav navbar-expand-sm navbar-dark ${isBespokePage ? "higher-zindex" : ""}`}>
          {userId == 94 || userId == 405 || userId == 41206 ? (
            <div className="signUpModal alert alert-warning m-0 rounded-0" role="alert" onClick={this.handleLogout}>
              <span>You are currenty logged into a demo account. <strong>Click here</strong> to log out.</span>
            </div>
          ) : (
            null
          )}

          <div className="container manage-account">
            <button className="btn-burger d-block d-sm-none ms-3 me-1" id="menu-toggle" data-bs-toggle="collapse" data-bs-target="#sidebar-wrapper" aria-controls="sidebar-wrapper" aria-expanded="false" aria-label="Toggle navigation"><FontAwesomeIcon icon={faBars} size='1x'/></button>
            {backButton}
            <Link className="navbar-brand ps-3" to="/">
              {logo}
            </Link>
            
            <div className="collapse navbar-collapse manage-account" id="navbarNavDropdown">
              <button className="btn btn-burger d-block d-sm-none ms-3 me-1" id="menu-toggle" data-bs-toggle="collapse" data-bs-target="#sidebar-wrapper" aria-controls="sidebar-wrapper" aria-expanded="false" aria-label="Toggle navigation"><FontAwesomeIcon icon={faBars} size='1x'/></button>
              {backButton}
              <ul className="navbar-nav d-flex justify-content-between align-content-center w-100 manage-account">
                <li className="d-flex align-items-center m-auto">
                  <ul className="navbar-nav d-flex justify-content-between align-content-center">
                    <li className="nav-item">
                        <NavLink exact className="nav-link" to="/" activeClassName="active">Dashboard</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/results" activeClassName="active" isActive={matchWorkaround('/results')}>My results</NavLink>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown d-flex align-items-center">
                {authentication.isAuthenticated ? (
                  <div className="manage-account-container d-flex">
                    <div className="col-3 pe-0 d-none d-sm-block">
                      <Link to="/my-account"><div className={'profilePicture ' + user.customerSex}   style={profilePictureStyle}></div></Link>
                    </div>
                    <div>
                      <div className="welcomeName ps-2">
                        Hello, <span>{user.userNicename}</span>
                      </div>
                      {userId != 94 && userId != 405 && userId != 41206 ? (
                        <>
                          <a className="nav-link dropdown-button ps-2 pe-0 py-1" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Manage Account <span className="chevron-icon"></span>
                          </a>
                          <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <li className="nav-item">
                              <NavLink exact activeClassName="active" data-keepopenonclick className="nav-link dropdown-item" to="/my-account">Manage profile</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink exact activeClassName="active" data-keepopenonclick className="nav-link dropdown-item" to="/my-orders">Orders</NavLink>
                            </li>
                            <li className="nav-item">
                              <NavLink exact activeClassName="active" data-keepopenonclick className="nav-link dropdown-item" to="/my-subscriptions">Subscriptions</NavLink>
                            </li>
                            {stripeCustomerId != "" ? (
                              <li className="nav-item">
                                <NavLink exact activeClassName="active" data-keepopenonclick className="nav-link dropdown-item" to="/refer-a-friend">Refer a friend</NavLink>
                              </li>
                            ) : null}
                            <li className="nav-item">
                              <NavLink exact activeClassName="active" data-keepopenonclick className="nav-link dropdown-item" to="/my-settings">Settings</NavLink>
                            </li>
                            {user.checkoutCredit != 0  && (
                              <li className="nav-item">
                                <NavLink exact activeClassName="active" data-keepopenonclick className="nav-link dropdown-item" to="/account-credit">Account Credit</NavLink>
                              </li>
                            )}
                            {window.location.hostname == 'app.biomarkertracking.com' || window.location.hostname == 'localhost' ? (
                              <li className="nav-item">
                                <NavLink exact activeClassName="active" data-keepopenonclick className="nav-link dropdown-item" to="/third-party-access">Third Party Access</NavLink>
                              </li>
                            ):(
                              null
                            )}
                            <li data-keepopenonclick onClick={this.handleLogout} className="nav-link logout dropdown-item">Log out</li>
                          </ul>
                        </>) : null}
                    </div>
                  </div>
                ) : (
                  <div>error</div>
                )}
                </li>
              </ul>
            </div>
          </div>
        </nav> 
      </header>
    )
  }
}

const mapStateToProps = (state, ownProps) => {

  const { 
    entities: { order, lastOrder, allTests },
    authentication, 
    user,
    basket
  } = state
 

  return {
    authentication, 
    user, 
    order,
    lastOrder,
    basket,
    allTests 
  }
}

export default withRouter(connect(mapStateToProps, { 
  logout,
  loadLatestOrderStatus,
  loadLastOrderDate,
  loadAllTests,
})(Menus))
