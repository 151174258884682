import {useState} from "react";

export const useCopyToClipboard = ({resetTimeout = 3000} = {}) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = value => {
    if (!navigator?.clipboard) {
      fallbackCopyToClipboard(value);
      return;
    }

    navigator.clipboard
      .writeText(value)
      .then(() => {
        setIsCopied(true);
        if (resetTimeout) {
          setTimeout(() => setIsCopied(false), resetTimeout);
        }
      })
      .catch(error => {
        console.error("Failed to copy to clipboard:", error);
      });
    }


  const fallbackCopyToClipboard = value => {
    const textarea = document.createElement("textarea");
    textarea.value = value;
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();

    try {
      document.execCommand("copy");
      setIsCopied(true);
      if (resetTimeout) {
        setTimeout(() => setIsCopied(false), resetTimeout);
      }
    } catch (error) {
      console.error("Failed to copy to clipboard:", error);
    } finally {
      document.body.removeChild(textarea);
    }
  };

  return {copyToClipboard, isCopied};
};
