import { CALL_API, Schemas } from '../middleware/api'

export const GENERATE_REFER_CODE_REQUEST = 'GENERATE_REFER_CODE_REQUEST'
export const GENERATE_REFER_CODE_SUCCESS = 'GENERATE_REFER_CODE_SUCCESS'
export const GENERATE_REFER_CODE_FAILURE = 'GENERATE_REFER_CODE_FAILURE'

const fetchGenerateReferCode = () => ({
  [CALL_API]: {
    types: [ GENERATE_REFER_CODE_REQUEST, GENERATE_REFER_CODE_SUCCESS, GENERATE_REFER_CODE_FAILURE ],
    endpoint: `/secure/generate-refer-code`,
    schema: null,
  }
})

export const generateReferCode = () => (dispatch, getState) => {
  return dispatch(fetchGenerateReferCode())
};

export const GET_REFER_CODE_USES_REQUEST = 'GET_REFER_CODE_USES_REQUEST'
export const GET_REFER_CODE_USES_SUCCESS = 'GET_REFER_CODE_USES_SUCCESS'
export const GET_REFER_CODE_USES_FAILURE = 'GET_REFER_CODE_USES_FAILURE'

const fetchLoadReferCodeUses = () => ({
  [CALL_API]: {
    types: [ GET_REFER_CODE_USES_REQUEST, GET_REFER_CODE_USES_SUCCESS, GET_REFER_CODE_USES_FAILURE ],
    endpoint: `/secure/get-refer-code-uses`,
    schema: null,
  }
})

export const loadReferCodeUses = () => (dispatch, getState) => {
  return dispatch(fetchLoadReferCodeUses())
};

export const CHECK_REFER_CODE_USES_REQUEST = 'CHECK_REFER_CODE_USES_REQUEST'
export const CHECK_REFER_CODE_USES_SUCCESS = 'CHECK_REFER_CODE_USES_SUCCESS'
export const CHECK_REFER_CODE_USES_FAILURE = 'CHECK_REFER_CODE_USES_FAILURE'

const fetchCheckReferCodeUses = () => ({
  [CALL_API]: {
    types: [ CHECK_REFER_CODE_USES_REQUEST, CHECK_REFER_CODE_USES_SUCCESS, CHECK_REFER_CODE_USES_FAILURE ],
    endpoint: `/check-refer-code-uses`,
    schema: null,
  }
})

export const checkReferCodeUses = () => (dispatch, getState) => {
  return dispatch(fetchCheckReferCodeUses())
};

export const LOAD_REFER_CODE_DETAILS_REQUEST = 'LOAD_REFER_CODE_DETAILS_REQUEST'
export const LOAD_REFER_CODE_DETAILS_SUCCESS = 'LOAD_REFER_CODE_DETAILS_SUCCESS'
export const LOAD_REFER_CODE_DETAILS_FAILURE = 'LOAD_REFER_CODE_DETAILS_FAILURE'

const fetchLoadReferCodeDetails = () => ({
  [CALL_API]: {
    types: [ LOAD_REFER_CODE_DETAILS_REQUEST, LOAD_REFER_CODE_DETAILS_SUCCESS, LOAD_REFER_CODE_DETAILS_FAILURE ],
    endpoint: `/load-refer-code-details`,
  }
})

export const loadReferCodeDetails = () => (dispatch, getState) => {
  const referCodeDetails = getState().entities.referCodeDetails;
  if (referCodeDetails) {
    return null
  }

  return dispatch(fetchLoadReferCodeDetails())
};

export const SET_REFER_CODE_DETAILS_REQUEST = "SET_REFER_CODE_DETAILS_REQUEST"
export const SET_REFER_CODE_DETAILS_SUCCESS = "SET_REFER_CODE_DETAILS_SUCCESS"
export const SET_REFER_CODE_DETAILS_FAILURE = "SET_REFER_CODE_DETAILS_FAILURE"

export const setReferCodeDetails = (referCodeDetails) => (dispatch, getState) => {
  dispatch({
    type: SET_REFER_CODE_DETAILS_REQUEST
  })

  try {
    dispatch({
      type: SET_REFER_CODE_DETAILS_SUCCESS,
      response: referCodeDetails
    })
  } catch (error) {
    dispatch({
      type: SET_REFER_CODE_DETAILS_FAILURE,
      error: error
    })
  }
}