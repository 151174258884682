import React from 'react'
import RenderIf from '../../../RenderIf/RenderIf';
import LoadingSpinner from '../../LoadingSpinner';

import styles from './InputText.module.scss'

const InputText = ({label, labelClassName, name, id, className, isLoading, required, ...rest}) => {
  return (
    <> 
      <RenderIf isTrue={label}>
        <label htmlFor={id} className={`${labelClassName}`}>{label}</label>
      </RenderIf>

      <div className={`${styles.forthInput}`}>
        <input type="text" 
          name={name} 
          id={id} 
          required={required} 
          className={`${styles.input} ${className}`} 
          {...rest}
        />
        <RenderIf isTrue={isLoading}>
          <div className={`${styles.spinnerContainer}`}>
            <LoadingSpinner/>
          </div>
        </RenderIf>
      </div>
    </>
  );
}

export default InputText