import {useState, useEffect} from 'react'
import { useDispatch } from "react-redux";
import styles from "./CheckoutFooter.module.scss";
import RenderIf from '../../RenderIf/RenderIf';

export default function CheckoutFooter(props) {  

  const [isLoading, setIsLoading] = useState(false); 
  
  let url;
  if (window.location.hostname == 'app.forthwithlife.co.uk' || window.location.hostname == 'shop.forthwithlife.co.uk') {
    url = 'https://www.forthwithlife.co.uk'; 
  } else {
    url = 'https://www.sportsbloodtests.co.uk.co.uk' 
  }
 
  const partner = props.partner; 
  let hideFooter = false; 
  let partnerPortalId = localStorage.getItem('partnerPortalId');
  if (partnerPortalId == 'undefined' || !partnerPortalId) {
    partnerPortalId = 0;
  }

  if ((global.config.websiteId == 3 || partnerPortalId) && Object.keys(partner).length > 0) { 
    hideFooter = true;
  }  

  useEffect(() => {  
  }, [props]); 
  
  return ( 

    <footer id={styles.bespokeTestFooter} className={`${Object.values(props.partner).length > 0 ? "py-5" : "pt-5 pb-3"}`}>
      <div className="container">
        <div className="row justify-content-around mb-3">

          <div className="col-12 col-lg-4 text-center">
            <div className={'row justify-content-center ' +styles.paymentSection}>
              <div className="col-12 text-center mb-3"> 
                <h5>Secure payments</h5> 
              </div>
              <div className="col-12 mb-5 mb-lg-0">
                <div className="row align-items-center justify-content-center">
                  <div className="col-auto mb-2">
                    <img className="w-100" src="/img/stripe.png" />
                  </div> 
                  <div className="col-auto mb-2">
                    <img className="w-100" src="/img/visa.png" />
                  </div> 
                  <div className="col-auto mb-2">
                    <img className="w-100" src="/img/mastercard.png" />
                  </div> 
                  <div className="col-auto mb-2">
                    <img className="w-100" src="/img/gpay.png" />
                  </div> 
                  <div className="col-auto mb-2">
                    <img className="w-100" src="/img/applepay.png" />
                  </div> 
                  <div className="col-auto mb-2">
                    <img className="w-100" src="/img/klarna-colour.svg" />
                  </div> 
                </div> 
              </div> 
            </div> 
          </div>

          <div className="col-12 col-lg-4 mb-4 mb-lg-0">
            <div className="row justify-content-center">
              <div className="col-12 text-center mb-3"> 
                <h5><img className={`${styles.certifiedIcon} me-2`} src="/img/certified.png"/> Certified for quality & security</h5> 
              </div>
              <div className="col-12">
                <div className={`row g-2 align-items-center justify-content-center ${styles.certificateISOSection}`}>
                  <div className="col-auto col-lg">
                    <img className="w-100" src="/img/ISO15189.png" />
                  </div> 
                  <div className="col-auto col-lg">
                    <img className="w-100" src="/img/iso13485.png" />
                  </div> 
                  <div className="col-auto col-lg">
                    <img className="w-100" src="/img/iso27001.png" />
                  </div> 
                  <div className="col-auto col-lg">
                    <img className="w-100" src="/img/iso9001.png" />
                  </div>  
                </div> 
              </div> 
              <div className="col-12 mt-3">
                <div className={'row align-items-center justify-content-center text-center ' + styles.certificateSection}>
                  <div className="col-auto">
                    <img className={`w-100 ${styles.mdCertificate}`} alt="MD Certified" src="/img/MD.png" />
                  </div> 
                  <div className="col-auto">
                    <img className={`w-100 ${styles.ceCertificate}`} alt="CE Certified" src="/img/CE.png" />
                  </div> 
                </div> 
              </div> 
            </div> 
          </div>
        </div>
      </div>

      <div className={'container pt-3 ' + styles.smallText}>
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-lg-3 mb-3 mb-lg-0 text-center">  
            <RenderIf isTrue={!props.isLoadingLogo}>
              {props.isEdge || global.config.websiteId == 2 ? (
                <img src="/img/fullwhiteedge.png" className={styles["forth-logo"]} /> 
              ) : (
                <img src="/img/fullwhite.png" className={styles["forth-logo"]} /> 
              )}
            </RenderIf>
            <RenderIf isTrue={props.isLoadingLogo}>
              <div className='placeholder-glow'>
                <span className={`placeholder me-2 d-block ${styles.placeholderLogo}`}></span>
              </div>
            </RenderIf>
          </div>
          
          <RenderIf isTrue={!props.isLoadingLogo}>
            <div className="col-12 col-lg-6 text-center mb-3 mb-lg-0">
              {!props.isEdge && (global.config.websiteId == 1 || global.config.websiteId == 3) ? "Forth’s" : "Edge's"}  service is intended to enable people to improve wellbeing and optimise sports performance. It is not intended for clinical diagnosis. Test results are for information purposes only. If you do have concerns about your health, please discuss these directly with your GP.
            </div>
          </RenderIf>
          <RenderIf isTrue={props.isLoadingLogo}>
            <div className="col-12 col-lg-6 text-center mb-3 mb-lg-0 placeholder-glow">
              <p className='placeholder mb-0 lh-base'>
                PartnerName service is intended to enable people to improve wellbeing and optimise sports performance. It is not intended for clinical diagnosis. Test results are for information purposes only. If you do have concerns about your health, please discuss these directly with your GP.
              </p>
            </div>
          </RenderIf>

          <div className="col-12 col-lg-3 text-center text-lg-end">
            &copy;{new Date().getFullYear()} Humankind Ventures Ltd.
          </div>

          <div className="col-12 mt-3">
            <ul className="list-group flex-row justify-content-center">
              <li className={styles.listItem + ' list-group-item border-0'}><a href={url + '/terms-of-service/'} target="_blank">Terms of service</a></li>
              {global.config.websiteId == 1 &&
                <li className={styles.listItem + ' list-group-item border-0'}><a href="https://www.forthwithlife.co.uk/offers-tc/" target="_blank">Offers T&amp;C</a></li>
              } 
              <li className={styles.listItem + ' list-group-item border-0'}><a href={url + '/privacy-policy/'} target="_blank">Privacy policy</a></li>
              <li className={styles.listItem + ' list-group-item border-0'}><a href={url + '/cookie-policy/'} target="_blank">Cookie policy</a></li>
            </ul>
          </div>

        </div>

      
      </div>

    </footer>
 
  )
}