import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux' 
import { addToCart, loadRemoveFromBasket } from '../../../actions/basket-actions'
import { Button } from '../../../components'
import styles from "./RemoveFromBasket.module.scss"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

const RemoveFromBasket = (props) => {

  const removeFromCart = (testId) => {  
    let basket = props.basket;
    let partner = props.partner;
    let partnerId = 0;

    if (Object.keys(partner).length > 0) {
      partnerId = partner[0].partnerId;
    } 
    
    for (var key in basket) {
      if (basket[key] == testId) {
        var phlebotomyType = 'Finger-prick';
        if (props.phlebotomyOption == 2 || props.phlebotomyOption == 3) {
          phlebotomyType = 'Phlebotomy';
        } else if (props.phlebotomyOption == 4) { 
          phlebotomyType = 'Swab';
        } else {
          phlebotomyType = 'Finger-prick';
        }
        //delete basket[key];
        window.dataLayer.push({
          'event':'EERemoveFromBasket',
          'ecommerce': {
            'remove': {
              'products': [{
                'name': props.testName,
                'id': 'F'+testId,
                'price': props.testPrice,
                'category': 'Uncategorised',
                'variant': phlebotomyType,
              }]
            }
          }
        });

        delete basket[key];
      } 
    }

    // reset key value, start from 1
    var newBasket = {}
    var i = 1
    for(var index in basket) { 
      newBasket[i] = basket[index];
      i++;
    }

    var count = 0;

    for (var key in newBasket) {
      count++;
    } 

    var dt, expires;
    var cname="hasBasketItems";
    var cvalue = count;
    var days = 999;
    dt = new Date();
    dt.setTime(dt.getTime()+(days*24*60*60*1000));
    expires = "; expires="+dt.toGMTString();
    document.cookie = cname+"="+cvalue+expires+'; domain=forthwithlife.co.uk';
    if(count == 0) {
      if(window.parent) {
        window.parent.postMessage('basketCleared', '*');
      }
    } 


    localStorage.setItem("basket", JSON.stringify(newBasket));
 
    props.addToCart(newBasket)

    if (localStorage.getItem("orderCookieId") != "") {
      props.loadRemoveFromBasket(testId, localStorage.getItem("orderCookieId"), partnerId).then(function(response) {
        props.reload();
      });
     
    } 
 
  }

 
  let randomModalString =  Math.floor(Math.random() * 1001);

  return (
    <>
    
    {props.small ? (
      <>
      <span data-bs-toggle="modal" data-bs-target={"#deleteMarker"+randomModalString} className={"deleteMarker "+styles.minus}><FontAwesomeIcon icon={faTrash} /></span>
      <div className="modal deleteMarkerModal" tabIndex="-1" role="dialog" id={"deleteMarker"+randomModalString}>
        <div className="modal-dialog modal-dialog-centered mx-auto my-0" role="document">
          <div className={"modal-content shadow-sm " + styles.modalContent}>
            <div className="border-0 modal-header text-center">
              <h5 className="mt-4 fw-bold modal-title w-100 biomarkerModalTestName no-letter-spacing">Remove from basket?</h5>
            </div> 
            <div className={`modal-body text-center mb-4 mx-4`}>
              <div className="row">
                <div className="col-6">
                  <Button data-bs-dismiss="modal" aria-label="Close" variant="primary-outline" className="w-100">
                    Cancel
                  </Button>
                </div>
                <div className="col-6">
                  <Button onClick={() => removeFromCart(props.testId)} variant="primary" className="w-100">
                    Remove
                  </Button>
                </div>
              </div>
               
               
            </div>
          </div>
        </div>
      </div>
      </>
      
    ) : (
      <button className="removeFromCart btn" onClick={() => removeFromCart(props.testId)}>Delete</button>
    )} 
    </>
    
   

  )

}
 

const mapStateToProps = (state, ownProps) => {

  const {
    basket,
    authentication, 
    user
  } = state
 

  return {
    authentication, 
    user,
    basket
  }
}

export default withRouter(connect(mapStateToProps, { 
  addToCart,
  loadRemoveFromBasket
})(RemoveFromBasket))     
