import React, {useState, useEffect} from 'react'
import { useDispatch } from "react-redux";
import find from 'lodash/find'
import _ from 'lodash'; 
import styles from "./BasketItems.module.scss";
import {BasketItem, Loader} from '../../../components'

export default function BasketItems(props) {  
  

  const basketItems = () => {
 
    if (Object.keys(props.cart).length > 0 && Object.keys(props.basketTestItems).length > 0) {
      let tests = props.basketTestItems;
      let basket = props.cart[0].basketArray;
      let cart = props.cart[0].basketArray;
      let orderVoucherId = 0;
      let customise = true;
      Object.keys(cart).map(function(key) {
        if (cart[key][0] == parseInt(38978) || parseInt(cart[key][0]) == parseInt(78612) || cart[key][0] == "78612" || parseInt(cart[key][0]) == parseInt(78612) || cart[key][0] == "81948" || parseInt(cart[key][0]) == parseInt(81948) || cart[key][0] == "78612" || cart[key][0] == parseInt(40305) || cart[key][0] == parseInt(42639) || cart[key][0] == "100815" || parseInt(cart[key][0]) == parseInt(100815) || cart[key][15] || cart[key][0] == "277803" || parseInt(cart[key][0]) == parseInt(277803)) {
          customise = false;
        }
      });

      if (props.phlebotomyOption == 4) {
        customise = false;
      }
      
      let voucher = props.getVoucher();
 
      if (props.cart[0].orderVoucherId && voucher && voucher.giftCode != 1) {
        orderVoucherId = voucher.voucherId;
      }

      let count = 0;
      let items = []; 

      if (Object.keys(props.cart).length > 0 && Object.keys(props.basketTestItems).length > 0) {
        Object.keys(basket).map(function(key) {
          find(tests, (test) => {
            if (test.testId === basket[key][0]) {
              items.push(basket[key]);
            } else {
              return false;
            }
          });
        });


        let grouped = _.mapValues(_.groupBy(items, "0"));
        return Object.keys(grouped)
          .reverse()
          .map(function(key) {
            let testId = parseInt(grouped[key][0][0]);
            let kitImage = "";
            let phlebImage = "";

            let priceMonthly;
            let bundleTotal;

            if (grouped[key][0][5] != 0 && grouped[key][0][6] == 0 && grouped[key][0][4] == 0.0) {
              if (grouped[key][0][5] > 1) {
                priceMonthly = " every " + grouped[key][0][5] + " months";
              } else {
                priceMonthly = " every " + grouped[key][0][5] + " months";
              }

              if (grouped[key][0][5] != props.subscriptionOption) {
                priceMonthly = " every " + props.subscriptionOption + " months";
              }
            } else if (grouped[key][0][5] != 0 && grouped[key][0][6] != 0 && grouped[key][0][4] == 0.0) {
              bundleTotal = grouped[key][0][3] * grouped[key][0][6];
              priceMonthly = " x " + grouped[key][0][6] + " (£" + bundleTotal + ")";
            }

            if (global.config.websiteId == 1 || global.config.websiteId == 3) {
              kitImage = "/img/lifeBasketProduct.jpg";
              phlebImage = "/img/lifeBasketPhlebotomist.jpg";
            } else {
              kitImage = "/img/edgeBasketProduct.jpg";
              phlebImage = "/img/edgeBasketPhlebotomist.jpg";
            }

            let testImage = "/img/FE-Product-Box.png";

            if (global.config.websiteId == 1 || global.config.websiteId == 3) {
              testImage = "/img/forthPackaging.png";
            }

            let border = "";

      
            return (
               
              <BasketItem
                key={testId}
                globalPlusTests={props.globalPlusTests}
                setPlusTests={props.setPlusTests}
                testImage={testImage}
                test={tests[testId]}
                grouped={grouped[key]}
                orderVoucherId={orderVoucherId}
                priceMonthly={priceMonthly}
                border={border}
                handleQuantityChange={props.handleQuantityChange}
                subscriptionInfo={props.subscriptionInfo}
                subscriptionOption={props.subscriptionOption}
                testId={testId}
                customise={customise}
                step={props.step}
                phlebotomyOption={props.phlebotomyOption} 
                updatePhlebotomyOption={props.updatePhlebotomyOption}
                partner={props.partner}
                state={props.state}
                voucher={props.voucher}
                cart={props.cart[0]}
                handleSubscriptionChange={props.handleSubscriptionChange}
                quantityLoading={props.quantityLoading}
                globalHCTests={props.globalHCTests}
                setGlobalHCTests={props.setGlobalHCTests}
              />
                   
            );
          });
      } else {
        return <div>ERROR</div>;
      }
    } else {
      return <Loader />;
    }
  }
  
   
   
  return ( 

    <> 
     {basketItems()}
    </> 
  )
}