import config from '../config'
import {useState, useEffect, useRef} from 'react';  
import ReactGA from 'react-ga';

export default async function runGA(page) {
  let GaCode = "UA-174048119-1";

  if (global.config.websiteId == 1) {
    GaCode = config.GALIFECODE;
  } else {
    GaCode = config.GAEDGECODE;
  }

  ReactGA.initialize(GaCode);

  ReactGA.pageview(page); 
};