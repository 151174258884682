import React from 'react';
import PropTypes from 'prop-types';
import packageJson from '../../package.json';

const Loader = ({ text }) => {
  return (
    <div id="loaderScreen" style={{"--loader-bg-color": global.config.websiteId == 5 && "#ffffff"}}>
      <div className="align-self-end">
        <svg className="pl col-12" viewBox="-5 -5 170 170" width="90" height="90" xmlns="http://www.w3.org/2000/svg">
          <defs>
            {[1,3,5].includes(global.config.websiteId) ? (
              <linearGradient id="pl-grad1" x1="1" y1="0.5" x2="0" y2="0.5">
                <stop offset="0%" stopColor="hsl(6, 0%, 10%)" />
                <stop offset="100%" stopColor="hsl(6, 0%, 10%)" />
              </linearGradient>
            ) : (
              <linearGradient id="pl-grad1" x1="1" y1="0.5" x2="0" y2="0.5">
                <stop offset="0%" stopColor="hsl(6, 0%, 100%)" />
                <stop offset="100%" stopColor="hsl(6, 0%, 100%)" />
              </linearGradient>
            )}
          </defs>

          <path className="pl__ring" id="Path_2132" cx="100" cy="100" r="82" fill="none" stroke="url(#pl-grad1)" strokeWidth="2" d="M737.441,586.442a66.209,66.209,0,1,0,66.169,68.4l.04-.011V586.442Zm33.669,67.315-.021.006a33.667,33.667,0,1,1-33.649-34.78h33.669Z" transform="translate(-889.311 153.589) rotate(-45)" dasharray="0 257 1 257"/>
        </svg>
        {text && <p className="col-12 text-center">{text}</p>}
      </div>
      <span className="version col-12 text-end">{global.config.partnerPlatform == 1 ? `v${packageJson.B2Bversion}` : `v${packageJson.version}`}</span>
    </div>
  );
};

// Props
Loader.propTypes = {
  text: PropTypes.string,
};
Loader.defaultProps = {
  text: '',
};

export default Loader;
