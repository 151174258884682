import React, { Component } from "react";
import CheckoutSkeleton from "../containers/Checkout/CheckoutSkeleton/CheckoutSkeleton";
import Loader from "./Loader";

const CHECKOUT_URLS = [
  /^\/checkout\/?$/,                                  // Matches "/checkout" and "/checkout/"
  /^\/checkout\/[A-Za-z0-9+/=]+$/,                    // Matches "/checkout/[base64]"
  /^\/checkout\/[A-Za-z0-9+/=]+\/[A-Za-z0-9-]+$/,     // Matches "/checkout/[base64]/[voucherCode]"
  /^\/checkout-from-partner\/[A-Za-z0-9+/=]+$/        // Matches "/checkout-from-partner/[base64]"
];

export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        component: null
      };
    }

    async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({
        component: component
      });
    }

    render() {
      const C = this.state.component;
      const isCheckout = CHECKOUT_URLS.some(url => url.test(window.location.pathname))

      return C ? <C {...this.props} /> : isCheckout ? <CheckoutSkeleton /> : <Loader/>;
    }
  }

  return AsyncComponent;
}