import React, {useEffect, useState} from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

import RenderIf from "../../RenderIf/RenderIf";

import styles from "./ShareSocialMedia.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/pro-regular-svg-icons";

export default function ShareSocialMedia({
  title = "",
  description = "",
  url = "",
  emailBody = "",
  variant = "block",
  socialMedia = ["email", "facebook", "twitter", "whatsapp"],
  iconSize = "44px",
  label = "",
}) {
  const [canShareNative, setCanShareNative] = useState(false);

  useEffect(() => {
    if (navigator.share) {
      setCanShareNative(true);
    }
  }, []);

  const handleShare = () => {
    navigator.share({
      title,
      text: description,
      url,
    });
  };

  return (
    <div className="row justify-content-center align-items-center">
      <RenderIf isTrue={!canShareNative}>
        <p className="mb-3 lh-base text-center fw-semibold">{label}:</p>
        <RenderIf isTrue={socialMedia.includes("email")}>
          <div className={`${variant == "block" ? "col-12" : "col-auto"}`}>
            <EmailShareButton
              url={url}
              subject={title}
              body={emailBody || description}
              className={`${variant == "block" ? `mx-auto ${styles.emailBtn} col-12 col-md-auto px-md-5` : ""}`}>
              <EmailIcon size={iconSize} round={variant !== "block"} />
              <span className={`${variant == "block" ? "" : "d-none"}`}>Share via email</span>
            </EmailShareButton>
          </div>
        </RenderIf>

        <RenderIf isTrue={socialMedia.includes("email") && socialMedia.filter(item => item !== "email").length > 0}>
          <div className={`${variant == "block" ? "col-12" : "d-none"}`}>
            <span className={styles.separator}>or</span>
          </div>
        </RenderIf>

        <RenderIf isTrue={socialMedia.includes("facebook")}>
          <div className="col-auto">
            <FacebookShareButton url={url}>
              <FacebookIcon size={iconSize} round />
            </FacebookShareButton>
          </div>
        </RenderIf>

        <RenderIf isTrue={socialMedia.includes("twitter")}>
          <div className="col-auto">
            <TwitterShareButton size={iconSize} url={url} title={description}>
              <svg viewBox={"0 0 64 64"} width={`${iconSize}`} height={`${iconSize}`} fill="#ffffff">
                <circle cx={"50%"} cy={"50%"} r={"calc(50% - 1px)"} fill="#000000" />
                <path
                  d="M 41.116 18.375 h 4.962 l -10.8405 12.39 l 12.753 16.86 H 38.005 l -7.821 -10.2255 L 21.235 47.625 H 16.27 l 11.595 -13.2525 L 15.631 18.375 H 25.87 l 7.0695 9.3465 z m -1.7415 26.28 h 2.7495 L 24.376 21.189 H 21.4255 z"
                  fill="white"></path>
              </svg>
            </TwitterShareButton>
          </div>
        </RenderIf>

        <RenderIf isTrue={socialMedia.includes("whatsapp")}>
          <div className="col-auto">
            <WhatsappShareButton size={iconSize} url={url} title={description}>
              <WhatsappIcon size={iconSize} round />
            </WhatsappShareButton>
          </div>
        </RenderIf>
      </RenderIf>

      <RenderIf isTrue={canShareNative}>
        <RenderIf isTrue={socialMedia.includes("email")}>
          <div className={"col-12"}>
            <button
              onClick={handleShare}
              className={`d-flex align-items-center justify-content-between gap-3 mx-auto col-12 col-md-auto py-3 ${styles.shareBtn}`}>
              <FontAwesomeIcon icon={faShareAlt} className="fa-fw"/>
              <span className={"mx-auto"}>{label}</span>
            </button>
          </div>
        </RenderIf>
      </RenderIf>
    </div>
  );
}
