import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';

import ShareSocialMedia from '../ShareSocialMedia/ShareSocialMedia';
import RenderIf from '../../RenderIf/RenderIf';

import styles from './ReferAFriendBanner.module.scss'
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faMoneyBill } from '@fortawesome/pro-regular-svg-icons';

export default function ReferAFriendBanner({url = "", title = "", description = "", emailBody = "", voucherCode = "XXXX-XXXX-XXXX", isGeneratingReferCode = false, showSocialShareBtn = true}) {
  const {copyToClipboard, isCopied} = useCopyToClipboard()

  const {
    referralCodeDiscount = "10.00", 
    referralCodeMinimumSpend = "49", 
    referralCodeType = "1"
  } = useSelector(state => state.referCodeDetails || {})

  const handleCopyReferCode = async () => {
    if(!voucherCode || voucherCode === "XXXX-XXXX-XXXX") return

    copyToClipboard(voucherCode)
  }
  const getDiscount = useCallback(() => {
    let discount = "";

    if (referralCodeType == 1) {
      discount = `£${referralCodeDiscount.replace(".00", "")}`;
    }

    if (referralCodeType == 2) {
      discount = `${referralCodeDiscount.replace(".00", "")}%`;
    }
    return discount;
  }, [referralCodeDiscount, referralCodeType])

  const discount = useMemo(() => getDiscount(), [getDiscount])

  return (
    <div className='row'>
      <div className='col-12'>
        <div className={`${styles.box}`}>
          <div className={`${styles.badge}`}>
            <FontAwesomeIcon icon={faMoneyBill} className='fa-fw me-2' /> GET {discount}
          </div>
          <div className="d-flex flex-wrap align-items-center mb-3">
            <div className='col-8'>
              <h2 className={`text-start mb-0 ${styles.title}`}>Refer a friend and you'll both get {discount}</h2>
            </div>
            <div className='col-4'>
              <img src="/img/referral-code-icon.svg" alt="referral code icon" className={`${styles.icon}`}/>
            </div>
            <div className='col-12 mt-3'>
              <p className={`${styles.description} text-start mb-0`}>Give friends {discount} off their first order, and for every friend who makes a purchase over £{referralCodeMinimumSpend}, you earn {discount}.</p>
            </div>
            <div className='col-12 mt-3'>
              <RenderIf isTrue={isGeneratingReferCode}>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Generating code...
              </RenderIf>

              <RenderIf isTrue={!isGeneratingReferCode}>
                <div className={`${styles.codeContainer} d-flex align-items-center`} data-copied={isCopied}>
                  <div className='col'>
                    <small>Your code</small>
                    <p className={styles.code}>{voucherCode}</p>
                  </div>
                  <div className='col-auto' onClick={handleCopyReferCode}>
                    <FontAwesomeIcon icon={faCopy} className='fa-fw mx-auto' size='2x' />
                  </div>
                </div>
              </RenderIf>
            </div>
          </div>
          <RenderIf isTrue={showSocialShareBtn}>
            <ShareSocialMedia
              variant="inline"
              btnVariant="default"
              url={url}
              title={title}
              description={description}
              emailBody={emailBody}
              label="Share your code with your friends"
            />
          </RenderIf>
        </div>
      </div>
    </div>
  );
}