import React from 'react'
import unescape from 'lodash/unescape';

import styles from './BiomarkersIncluded.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicroscope, faTint } from '@fortawesome/pro-regular-svg-icons';

export default function BiomarkersIncluded({variant = "", test = {}}) {  

  let accordionId = Math.random().toString(36).substring(7);
  return ( 
    <>
      {variant === "small" ? (
        <button className="btn btn-primary py-1 ps-2 pe-2 d-flex gap-1 align-items-center justify-content-center" data-bs-toggle="modal" data-bs-target={"#biomarkersIncludedModal"+accordionId}>
          <small>
            <FontAwesomeIcon icon={faTint} className='fa-fw'/> <span className="mx-auto">{Object.keys(test.biomarkers).length}</span>
          </small>
        </button>
      ) : (
        <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target={"#biomarkersIncludedModal"+accordionId}>
          <FontAwesomeIcon icon={faMicroscope} size='lg'/><span className="mx-auto text-nowrap ms-2">{Object.keys(test.biomarkers).length} biomarker{Object.keys(test.biomarkers).length > 1 ? "s" : ""} included</span>
        </button>
      )}

      <div className="modal fade" id={"biomarkersIncludedModal"+accordionId} tabIndex="-1" role="dialog" aria-labelledby="phlebModal" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content border-0 rounded-0">
            <div className="modal-header align-items-start">
              <h3 className="modal-title fs-5 flex-grow-1 text-center text-balance" id="exampleModalLabel">Biomarkers included for <br/> <strong>{unescape(test.testName)}</strong></h3>
              <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body text-start">
             
              <div className="row pb-4">
                <div className="col-12 py-3">
                  <ul className={styles.biomarkersList}>
                    {Object.values(test.biomarkers)?.map( (biomarker, index) =>
                      <li key={biomarker?.biomarkerName}>
                        {biomarker?.biomarkerName}
                      </li>  
                    )}
                  </ul>
                </div> 
              </div> 
            </div> 
             
          </div>
        </div>
      </div>  
    </>
  )
}



 