import React, { Component } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom' 
import PropTypes from 'prop-types' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';


export default function BackButton(props) {
    let history = useHistory();
    return (
      <button
        className="d-block d-md-none button back-button icon-left"
        onClick={history.goBack()}>
          <FontAwesomeIcon icon={faChevronLeft} /> Back
      </button>
    );
};