import React from 'react'
import styles from './LoadingSpinner.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export default function LoadingSpinner({size = "md", className = ""}) {
  return (
    <div data-size={size} className={`${styles.spinner}`} title="spinner">
      <FontAwesomeIcon icon={faSpinner} className={`fa-fw ${className}`} size={size}/>
    </div>
  )
}
