
import { CALL_API } from '../middleware/api';

export const updateApp = (n = {type: 'users', payload: ''}) => {
  return n;
}

export const CHECKOUT_SETTINGS_REQUEST = 'CHECKOUT_SETTINGS_REQUEST'
export const CHECKOUT_SETTINGS_SUCCESS = 'CHECKOUT_SETTINGS_SUCCESS'
export const CHECKOUT_SETTINGS_FAILURE = 'CHECKOUT_SETTINGS_FAILURE'

const fetchCheckoutSettings = () => ({
  [CALL_API]: {
    types: [ CHECKOUT_SETTINGS_REQUEST, CHECKOUT_SETTINGS_SUCCESS, CHECKOUT_SETTINGS_FAILURE ],
    endpoint: `checkout-settings`,
  }
})

export const loadCheckoutSettings = () => (dispatch, getState) => {
  return dispatch(fetchCheckoutSettings())
};
