import "es6-string-polyfills";
import 'babel-polyfill'; 
import 'react-app-polyfill/ie11';
import 'es6-shim';
import 'isomorphic-fetch';
import 'html5shiv';
import 'core-js';

import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import Root from './containers/Root'
import configureStore from './store/configureStore'
import * as serviceWorker from './serviceWorker';
import config from "./config";

import 'bootstrap'
import './stylesheets/main.scss' 
import { clearCache } from "./helpers/clearCache";


window.addEventListener("message", event => {
  if (event) {
    if (event.origin == "http://wordpressdev.forthwithlife.co.uk" || event.origin == "https://wordpressdev.forthwithlife.co.uk" || event.origin == "http://forthwithlife.co.uk" || event.origin == "https://forthwithlife.co.uk" || event.origin == "http://www.forthwithlife.co.uk" || event.origin == "https://www.forthwithlife.co.uk") {
      if (JSON.parse(event.data).orderCookieId) { 
        localStorage.setItem('orderCookieId', JSON.parse(event.data).orderCookieId);
        window.parent.postMessage('refreshIframe', '*');
      }
    }
  } 
});
 



const store = configureStore()
if (window.location.hostname == 'localhost' || window.location.hostname == 'app.forthwithlife.co.uk' || window.location.hostname == 'shop.forthwithlife.co.uk' || window.location.hostname == 'appdev.forthwithlife.co.uk' || window.location.hostname == 'appdev.forthpartners.co.uk' || window.location.hostname == 'forthpartners.co.uk' || window.location.hostname == 'betashop.forthpartners.co.uk' || window.location.hostname == 'beta.forthpartners.co.uk' || window.location.hostname == 'appdev.forthpartners.co.uk' || window.location.hostname == 'partnershopdev.forthwithlife.co.uk' || window.location.hostname == 'biomarkertracking.com' || window.location.hostname == 'www.biomarkertracking.com' || window.location.hostname == 'www.forthpartners.co.uk') {
  document.body.classList.add('life');

} else if (window.location.hostname == "app.sportsbloodtests.co.uk" || window.location.hostname == "appdev.sportsbloodtests.co.uk" || window.location.hostname == "shop.sportsbloodtests.co.uk" ||  window.location.hostname == 'app.forthedge.co.uk' || window.location.hostname == 'appdev.forthedge.co.uk' || window.location.hostname == 'shop.forthedge.co.uk' ||  window.location.hostname == 'appdev.forthedge.co.uk') {
  document.body.classList.add('edge');
  document.body.classList.add('dark');

} else if (window.location.hostname == 'app.biomarkertracking.com' || window.location.hostname == 'shop.biomarkertracking.com') {
  document.body.classList.add('life');
  document.body.classList.add('biomarkertracking');
} else {
  document.body.classList.add('nonforth');
}



if (window.location.hostname == 'app.forthwithlife.co.uk' || window.location.hostname == 'shop.forthwithlife.co.uk') {     
  document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="apple-touch-icon" sizes="76x76" href="/favicons/apple-touch-icon.png">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="manifest" href="/site.webmanifest">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color="#ffffff">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<meta name="msapplication-TileColor" content="#ffffff">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<meta name="theme-color" content="#ffffff">' );
  var z = document.createElement('script');
  z.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-TSZR32F');";
  document.head.appendChild(z);
  var x = document.createElement('script');
  x.innerHTML = '!function(){window.sib={equeue:[],client_key:"y17dn737nzd8hnlxzkgdyb7c"},window.sendinblue={};for(var e=["track","identify","trackLink","page"],n=0;n<e.length;n++)!function(e){window.sendinblue[e]=function(){var n=Array.prototype.slice.call(arguments);(window.sib[e]||function(){var i={};i[e]=n,window.sib.equeue.push(i)})(n[0],n[1],n[2])}}(e[n]);var i=document.createElement("script");n=document.getElementsByTagName("script")[0];i.type="text/javascript",i.id="sendinblue-js",i.async=!0,i.src="https://sibautomation.com/sa.js?key="+window.sib.client_key,n.parentNode.insertBefore(i,n),window.sendinblue.page()}();';
  document.head.appendChild(x);
} else if (window.location.hostname == 'shop.forthedge.co.uk' || window.location.hostname == "shop.sportsbloodtests.co.uk" ) {   
    document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="apple-touch-icon" sizes="76x76" href="/favicons/apple-touch-icon.png">' );
    document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png">' );
    document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png">' );
    document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="manifest" href="/edgemanifest.webmanifest">' );
    document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color="#ffffff">' );
    document.head.insertAdjacentHTML( 'beforeEnd', '<meta name="msapplication-TileColor" content="#ffffff">' );
    document.head.insertAdjacentHTML( 'beforeEnd', '<meta name="theme-color" content="#ffffff">' );  
    var x = document.createElement('script');
    x.innerHTML = '!function(){window.sib={equeue:[],client_key:"y17dn737nzd8hnlxzkgdyb7c"},window.sendinblue={};for(var e=["track","identify","trackLink","page"],n=0;n<e.length;n++)!function(e){window.sendinblue[e]=function(){var n=Array.prototype.slice.call(arguments);(window.sib[e]||function(){var i={};i[e]=n,window.sib.equeue.push(i)})(n[0],n[1],n[2])}}(e[n]);var i=document.createElement("script");n=document.getElementsByTagName("script")[0];i.type="text/javascript",i.id="sendinblue-js",i.async=!0,i.src="https://sibautomation.com/sa.js?key="+window.sib.client_key,n.parentNode.insertBefore(i,n),window.sendinblue.page()}();';
    document.head.appendChild(x);
} else if (window.location.hostname == 'forthpartners.co.uk'){
  var c = document.createElement('script');
  c.innerHTML = '(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", "hgmlmd7wn1");'
  document.head.appendChild(c);
} else {
  document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="apple-touch-icon" sizes="76x76" href="/favicons/apple-touch-icon.png">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color="#ffffff">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<meta name="msapplication-TileColor" content="#ffffff">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<meta name="theme-color" content="#ffffff">' );
  document.head.insertAdjacentHTML( 'beforeEnd', '<script type="text/javascript" async="" src="https://www.google-analytics.com/analytics.js"></script>')
  document.head.insertAdjacentHTML( 'beforeEnd', '<script async src="https://www.googletagmanager.com/gtag/js?id=UA-71798345-3"></script><script>window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'UA-71798345-3\');</script>' );
}


const clearCacheUrlList = [
  /^\/checkout\/?$/,                                  // Matches "/checkout" and "/checkout/"
  /^\/checkout\/[A-Za-z0-9+/=]+$/,                    // Matches "/checkout/[base64]"
  /^\/checkout\/[A-Za-z0-9+/=]+\/[A-Za-z0-9-]+$/,     // Matches "/checkout/[base64]/[voucherCode]"
  /^\/checkout-from-partner\/[A-Za-z0-9+/=]+$/        // Matches "/checkout-from-partner/[base64]"
];

const mustClearCache = clearCacheUrlList.some(url => url.test(window.location.pathname))
if(mustClearCache){
  clearCache({store}).finally(() => {
    render(
      <Router>
        <Root store={store} />
      </Router>,
      document.getElementById('root')
    )
  })
} else {
  render(
    <Router>
      <Root store={store} />
    </Router>,
    document.getElementById('root')
  )
}


 
serviceWorker.unregister();
