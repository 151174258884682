import { CALL_API, Schemas } from '../middleware/api'
 

export const KIT_PRICE_REQUEST = 'KIT_PRICE_REQUEST'
export const KIT_PRICE_SUCCESS = 'KIT_PRICE_SUCCESS'
export const KIT_PRICE_FAILURE = 'KIT_PRICE_FAILURE'

const fetchKitPrices = () => ({
  [CALL_API]: {
    types: [ KIT_PRICE_REQUEST, KIT_PRICE_SUCCESS, KIT_PRICE_FAILURE ],
    endpoint: `/kit-pricing/get-kit-prices`, 
  }
})

export const loadKitPrices = () => (dispatch, getState) => {
  const kitPrices = getState().entities.kitPrices;
  if (kitPrices) {
    return null
  }

  return dispatch(fetchKitPrices())
}

export const SET_KIT_PRICES_REQUEST = "SET_KIT_PRICES_REQUEST"
export const SET_KIT_PRICES_SUCCESS = "SET_KIT_PRICES_SUCCESS"
export const SET_KIT_PRICES_FAILURE = "SET_KIT_PRICES_FAILURE"

export const setKitPrices = (kitPrices) => (dispatch, getState) => {
  dispatch({
    type: SET_KIT_PRICES_REQUEST
  })

  try {
    dispatch({
      type: SET_KIT_PRICES_SUCCESS,
      response: kitPrices
    })
  } catch (error) {
    dispatch({
      type: SET_KIT_PRICES_FAILURE,
      error: error
    })
  }
}