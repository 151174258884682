import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import config from '../../../config'
import QuickPayForm from './QuickPayForm';
import calculateBasket from '../../../helpers/calculateBasket';

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(config.STRIPE_KEY);



export default function QuickPay(props) {
  const [subtotal, setSubtotal] = useState(0); 
  const [credit, setCredit] = useState("")
  const [testPrice, setTestPrice] = useState();
  const [discountedCredit, setDiscountedCredit] = useState("")
  const [orderId, setOrderId] = useState(0);
  const [orderReference, setOrderReference] = useState(0);
  const [subscriptionOrder, setSubscriptionOrder] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0); 
  const [totalSavingAmount, setTotalSaving] = useState(0); 
  const [cardLoading, setCardLoading] = useState(true); 
  const [subquentialAmounts, setSubquentialAmounts] = useState(0)
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  
  useEffect(() => { 
    getValues();  
  }, [props]);


  const cardFormState = (data) => {
    if (data == "success") {  
      props.setPaymentSuccess(true);
      removeAllValues()
      localStorage.removeItem("basket");
      localStorage.removeItem("orderCookieId");
      props.removeAllFromCart();
      localStorage.removeItem("token"); 
      props.goToStep(5, false, false, true);
      localStorage.removeItem("token"); 
      if(window.parent) {
        window.parent.postMessage('checkoutSuccess', '*');
      }
    } else {  
      props.setPaymentSuccess(false);
      props.goToStep(5);
      localStorage.removeItem("token");
    }
  }
  

  const removeAllValues = () => {
    localStorage.removeItem('payerEmail')
    localStorage.removeItem('payerName')
    localStorage.removeItem('payerAddressLine1')
    localStorage.removeItem('payerAddressLine2')
    localStorage.removeItem('payerCity')
    localStorage.removeItem('payerPostcode')
    localStorage.removeItem('payerUserId')
    localStorage.removeItem('payerStripeCustomerId')
    localStorage.removeItem('payerPaymentIntent')
    localStorage.removeItem('payerClientSecret')
    localStorage.removeItem("payerOrderId");
    localStorage.removeItem("payerOrderReference");
    localStorage.removeItem("payerSubscriptionOrder");
    localStorage.removeItem("payerTotalAmount");
    localStorage.removeItem("payerCredit");
    localStorage.removeItem("payerDiscountedCredit");
  }
  const getValues = () => {
    //removeAllValues();
    
    let cartProps = {
      "user": props.user,
      "cart": props.cart[0],
      "tests": props.basketTestItems,
      "basket": props.cart[0].basketArray,
      "phlebotomyOption": props.phlebotomyOption,
      "stateVoucher": localStorage.getItem('voucher'),
      "voucher": props.voucher,
      "partner": props.partner,
      "phlebotomyPrices": props.phlebotomyPrices,
      "subscriptionOption": props.subscriptionOption,
      "globalPlusTests": props.globalPlusTests,
      "globalHCtests": props.globalHCTests,
      "guestCheckout": props.guestCheckout,
      "deliveryOption": props.deliveryOption,
      "deliveryInfo": props.deliveryInfo,
      "nextDayDeliveryPrice": props.nextDayDeliveryPrice,
      "step": props.step
    }  
    
    let checkoutTotal = calculateBasket(cartProps); 
    setSubtotal(checkoutTotal.subtotal);
    setTestPrice(checkoutTotal.testPrice);
    setTotalAmount(checkoutTotal.testPrice);
    setDeliveryPrice(checkoutTotal.deliveryPrice);
    setSubquentialAmounts(checkoutTotal.subquentialAmounts);
  }

  const renderQuickpayData = () => {
    const partner = props.partner
    const user = props.user;
    let orderCookieId = localStorage.getItem('orderCookieId');
    let partnerId;
    if (Object.keys(partner).length > 0) {
      partnerId = props.partner[0].partnerId;
    }
    //setCardLoading(true)
    props.loadGetCart(orderCookieId, 0, partnerId, props.checkoutFromPartner).then(function(response) {

      let cart = response.response.entities.cart[0].basketArray;

      let subscriptionOrder = false;
      let orderReference = ''; 
      Object.keys(cart).map(function(key) { 
        if ((cart[key][5] > 0) && (cart[key][6] == 0)) {
          subscriptionOrder = true; 
        } 
      });

      if (props.subscriptionOption == 0) {
        subscriptionOrder = false; 
      }
 
      let finalTotalAmount = parseFloat(response.response.entities.cart[0].totalAmount).toFixed(2);
      let credit = response.response.entities.cart[0].credit
      let discountedCredit = response.response.entities.cart[0].discountedCredit
      let totalSaving = response.response.entities.cart[0].totalSaving
      
      let userId = user.iD;
      let activationKit = false;

      if (props.guestCheckout && props.guestCheckout != 'unset') {
        userId = props.guestCustomerId;
        activationKit = true;
      } 



      setCredit(credit);
      setDiscountedCredit(discountedCredit);
      props.setCart(response.response.entities.cart)
 
      props.loadUpdateBasketReference(userId, subscriptionOrder, orderCookieId)
      .then(function(response) {
        if (!response.error) { 
          
          setOrderId(response.response.orderId)
          setOrderReference(response.response.orderReference)
          setSubscriptionOrder(subscriptionOrder)
          setTotalAmount(finalTotalAmount)
          setCredit(credit)
          setDiscountedCredit(discountedCredit)

          localStorage.setItem("payerOrderId", response.response.orderId);
          localStorage.setItem("payerOrderReference", response.response.orderReference);
          localStorage.setItem("payerSubscriptionOrder", response.response.clientSecret);
          localStorage.setItem("payerTotalAmount", finalTotalAmount);
          localStorage.setItem("payerCredit", credit);
          localStorage.setItem("payerDiscountedCredit", discountedCredit);
          
      

          let websiteId = 1;
          if (global.config.websiteId == 1) { 
            websiteId = 1;
          } else {
            websiteId = 2;
          } 
          let partnerCode = ''
          let partnerPortalId = localStorage.getItem('partnerPortalId');
          if ((global.config.websiteId == 3 || partnerPortalId != 0) && Object.keys(partner).length > 0) { 
            partnerCode = partner[0].requestCode;  
          }   
          let grantPartnerPermission = 1;
          /*
          if (props.grantPartnerPermission) {
            grantPartnerPermission = 1
          }*/
          const voucher = JSON.parse(localStorage.getItem('voucher'));
          let voucherId;
          if (voucher) {
            voucherId = voucher.iD;
          }
          //let orderCookieId = localStorage.getItem('orderCookieId');

          let receiptEmailAddress = props.receiptEmailAddress 

/*           if (props.checkoutFromPartner){
            receiptEmailAddress = props.clientReceipt == true ? props.receiptEmailAddress : props.partnerEmail 
          } else {
            receiptEmailAddress = props.receiptEmailAddress
          } */
          let payload = {
            userId: localStorage.getItem("payerUserId"),
            userIp: '',
            websiteId: websiteId,
            amount: finalTotalAmount * 100,
            id:'',
            orderReference: response.response.orderReference,
            orderId: response.response.orderId,
            stripeCustomerId:'', 
            userEmail: props.user.email ? props.user.email : props.customerEmailAddress,
            totalAmount: finalTotalAmount,
            totalSaving: totalSavingAmount,
            subscriptionOrder: false,
            activationKit: activationKit,
            partnerId: partnerPortalId,
            partnerCode: partnerCode,
            overidePayPalProcess: '',
            voucherId: voucherId,
            f: '',
            freeOrderEncryptedUrl: '',
            stripeToken: '',
            orderCookieId: orderCookieId,
            doNotReview: props.doNotReview,
            credit: credit * 100,
            discountedCredit: discountedCredit,
            receiptEmailAddress: receiptEmailAddress,
            grantPartnerPermission: 1,
            checkoutFromPartner: props.checkoutFromPartner,
            clientEmailsCheck: props.clientEmailsCheck,
            clientReciept: props.clientReceipt,
            quickPay: true
          } 
          if (finalTotalAmount > 0.00 || subscriptionOrder == true) {
            props.updatePaymentIntent(payload)
            .then((response) => { 
              props.setClientSecret(response.response.clientSecret)
              props.setPaymentIntent(response.response.id) 
              props.setStripeCustomerId(response.response.stripeCustomerId) 
              localStorage.setItem("payerStripeCustomerId", response.response.stripeCustomerId);
              localStorage.setItem("payerPaymentIntent", response.response.id);
              localStorage.setItem("payerClientSecret", response.response.clientSecret);
              getValues();
              setCardLoading(false) 
            });
          } else {
            setCardLoading(false) 
          }
        }  
      });

    }); 
  }

 
  return (
    <>
    { props.reloadQuickPay ||  ((localStorage.getItem("payerClientSecret") && localStorage.getItem("payerClientSecret")=='undefined') && localStorage.getItem("payerUserId")) ? (
      <div className="row align-items center justify-content-center">
        <div className="col text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    ) : ( 
      <>
      <Elements stripe={stripePromise}>
        <QuickPayForm  
          setGuestCheckout={props.setGuestCheckout}  
          setGuestCheckoutValue={props.setGuestCheckoutValue}
          basketTestItems={props.basketTestItems} 
          user={props.user}
          basket={props.basket}
          subscriptionOption={props.subscriptionOption}
          phlebotomyOption={props.phlebotomyOption}
          loadClearBasket={props.loadClearBasket}
          loadAddToBasket={props.loadAddToBasket}
          renderQuickpayData={renderQuickpayData}
          orderId={orderId}
          orderReference={orderReference}
          stripeCustomerId={props.stripeCustomerId}
          paymentIntent={props.paymentIntent} 
          loadCreateAccount={props.loadCreateAccount}
          updateOrderWithDeliveryOption={props.updateOrderWithDeliveryOption} 
          totalAmount={totalAmount}
          subscriptionOrder={subscriptionOrder}
          subquentialAmounts={subquentialAmounts} 
          subtotal={subtotal}
          setCart={props.setCart}
          voucher={props.voucher}
          partner={props.partner}
          amount={totalAmount * 100}
          websiteId={global.config.websiteId}
          cart={props.cart[0]}
          loadUpdateBasketReference={props.loadUpdateBasketReference}
          updatePaymentIntent={props.updatePaymentIntent}
          updateUserProfile={props.updateUserProfile}
          removeAllValues={removeAllValues}
          cardFormState={cardFormState}
          trackQuickPay={props.trackQuickPay}
          deliveryOption={deliveryPrice}
        />
      </Elements>
      </>
    )}
    </>
    
  );
};
 