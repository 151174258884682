import React, {useState, useEffect, useMemo} from 'react'
import { Link, withRouter } from 'react-router-dom'
import { useDispatch, connect } from "react-redux";
import calculateBasket from "../../../helpers/calculateBasket"
import styles from "./CartSummary.module.scss";
import config from '../../../config' 
import moment from 'moment'    
import {SidebarModal, PhlebotomySelector, DeliverySelector, RenderIf} from '../../../components/' 
import { loadRenameTest } from '../../../actions/bespoke-test-actions'
import { checkTestTypeIsSexualTest, checkBasketHasSexualTest } from '../../../helpers/checkHasSexualTest';
import { getFingerPrickCopy } from '../../../helpers/getFingerPrickCopy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';

const CartSummary = (props) => { 
  let randomModalString =  Math.floor(Math.random() * 1001);
  const [subtotal, setSubtotal] = useState();
  const [showDiscountField, setShowDiscountField] = useState();
  const [orderDiscount, setOrderDiscount] = useState();
  const [deliveryPrice, setDeliveryPrice] = useState();
  const [testPrice, setTestPrice] = useState();
  const [subscriptionInfo, setSubscriptionInfo] = useState();
  const [giftCardTotal, setGiftCardTotal] = useState();
  const [autoCreditAmount, setAutoCreditAmount] = useState();
  const [quantityMoreThanOne, setQuantityMoreThanOne] = useState();
  const [subscription, setSubscription] = useState();
  const [sale, setSale] = useState();
  const [vouchersToDiscount, setVouchersToDiscount] = useState();
  const [singleTestPrice, setSingleTestPrice] = useState();
  const [clinicPrice, setClinicPrice] = useState();
  const [hideKlarna, setHideKlarna] = useState(props.hideKlarna || false)
  let modalString = (Math.random() + 1).toString(36).substring(7);

  useEffect(() => { 
    getValues();  
  },[props]);

  const getValues = () => {
    let cartProps = {
      "user": props.user,
      "cart": props.cart,
      "tests": props.basketTestItems,
      "basket": props.basketArray,
      "phlebotomyOption": props.phlebotomyOption,
      "stateVoucher": localStorage.getItem('voucher'),
      "voucher": props.voucher,
      "partner": props.partner,
      "phlebotomyPrices": props.phlebotomyPrices,
      "subscriptionOption": props.subscriptionOption,
      "globalPlusTests": props.globalPlusTests,
      "globalHCtests": props.globalHCTests,
      "guestCheckout": props.guestCheckout,
      "deliveryOption": props.deliveryOption,
      "deliveryInfo": props.deliveryInfo,
      "nextDayDeliveryPrice": props.nextDayDeliveryPrice,
      "step": props.step
    }  
    
    let checkoutTotal = calculateBasket(cartProps);
    setSubtotal(checkoutTotal.subtotal);
    setShowDiscountField(checkoutTotal.showDiscountField);
    setOrderDiscount(checkoutTotal.orderDiscount);
    setDeliveryPrice(checkoutTotal.deliveryPrice);
    setTestPrice(checkoutTotal.testPrice);
    setSubscriptionInfo(checkoutTotal.subscriptionInfo);
    setGiftCardTotal(checkoutTotal.giftCardTotal);
    setAutoCreditAmount(checkoutTotal.autoCreditAmount);
    setQuantityMoreThanOne(checkoutTotal.quantityMoreThanOne);
    setSubscription(checkoutTotal.subscription);
    setSale(checkoutTotal.sale);
    setVouchersToDiscount(checkoutTotal.vouchersToDiscount);
    setSingleTestPrice(checkoutTotal.singleTestPrice);
    setClinicPrice(checkoutTotal.clinicPrice);
  } 

  let tests = props.basketTestItems;  
  let phlebotomyNameOverride = '';
  let showChangeBtn = true;
  Object.keys(tests).map(function(key) { 
    if (parseInt(tests[key].testType) == 5) {
      phlebotomyNameOverride = tests[key].testCollectionMethod;
      showChangeBtn = false;
    } 

    if(tests[key].blockKitOptions == 1){
      showChangeBtn = false;
    }

    const isSexualTest = checkTestTypeIsSexualTest(tests[key].testType) || false
    if(isSexualTest){
      phlebotomyNameOverride = getFingerPrickCopy(tests[key].testType)
    }
  });

  const basketHasTracked24 = props.basketArray.some(test => props.basketTestItems[test[0]]?.isTracked24 == 1)
  const quantity = props.basketArray.filter(test => props.basketTestItems[test[0]]?.isTracked24 == 1)?.length || 1
  const deliveryFee = props.deliveryInfo?.deliveryFee * quantity || 0
  const deliveryFeeToDisplay = deliveryPrice == 0 ? "FREE" : `£${parseFloat(deliveryFee).toFixed(2).toString().replace(".00", "")}`
  const deliveryOptionLabel = props.deliveryOption == 1 ? "Guaranteed delivery" : "Tracked 24 delivery"
  const isGuaranteedDeliveryAvailable = props.deliveryInfo?.showNextDayDelivery == 1 || false
  
  const hasSexualTest = useMemo(() => {
    return props.basketArray.some(function(test) {
      const testType = test[2]
      return checkTestTypeIsSexualTest(testType)
    })
  })

  const sexualKitTypes = useMemo(() => {
    if(!hasSexualTest) return [] 

    const kitNames =  props.basketArray.map(function(test) {
      const testType = test[2]

      return getFingerPrickCopy(testType)
    })

    return [...new Set(kitNames)]
  }, [hasSexualTest])

  return ( 
    <>
      {subtotal ? ( 
        <div id={styles.cartSummary}>
          <div className={`p-3 ${props.step != 0 ? 'px-0' : 'px-3'}`}>
            <div className="d-flex justify-content-between align-items-center">
              <p>Subtotal</p>
              {props.quantityLoading ?
              <p className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </p>
              :
              <div className={"fw-normal text-dark "+styles.priceValue}>
                &pound;{subtotal.toFixed(2)} {subscription == 1 && !sale && ( <span className={styles.smallText}>Per test</span> )}
              </div>}
            </div>
          </div>
          
          {subscriptionInfo && ( 
            <div className={`p-3 pt-0 ${styles.smallText} ${props.step != 0 ? 'px-0' : 'px-3'}`}>
              <strong>Subscription</strong><br/>
              {subscriptionInfo}
            </div>
          )}

          {props.voucherRemovedBecauseOfCredit && (
            <div className={`py-0 ${props.step != 0 ? 'px-0' : 'px-3'}`}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="voucherRemovedBecauseOfCredit">
                  Your voucher code cannot be used in conjunction with credit and has been removed from your basket
                </div>
              </div>
            </div>
          )}
          
          <RenderIf isTrue={!hasSexualTest}>
            {props.phlebotomyOption == 1 && (
              <div className={`p-3 ${props.step != 0 ? 'px-0' : 'px-3'}`}>
                <div className="d-flex justify-content-between align-items-center">
                  <p>{phlebotomyNameOverride ? phlebotomyNameOverride : 'Finger prick kit'} {showChangeBtn && props.step != 4 ? <a href="" className="ms-2" data-bs-toggle="modal" data-bs-target={"#phlebotomyOptionsSidebar"+modalString} href="#"><FontAwesomeIcon icon={faPenToSquare} /> Edit</a> : ''}</p>
                  <p className={"fw-normal text-dark "+styles.priceValue}>
                    FREE
                  </p>
                </div>
              </div>
            )}
            
            {props.phlebotomyOption == 2 && (
              <div className={`p-3 ${props.step != 0 ? 'px-0' : 'px-3'}`}>
                <div className="d-flex justify-content-between align-items-center">
                  <p>Home Phlebotomy {props.step != 4 ? <a href="" className="ms-2" data-bs-toggle="modal" data-bs-target={"#phlebotomyOptionsSidebar"+modalString} href="#"><FontAwesomeIcon icon={faPenToSquare} /> Edit</a> : ''}</p>
                  <p className={"fw-normal text-dark "+styles.priceValue}>
                    &pound;{props.phlebotomyPrices[2].phlebotomyPrice}
                  </p>
                </div>
              </div>
            )}

            {props.phlebotomyOption == 3 && (
              <div className={`p-3 ${props.step != 0 ? 'px-0' : 'px-3'}`}>
                <div className="d-flex justify-content-between align-items-center">
                  <p>Organise a phlebotomist myself {props.step != 4 ? <a href="" className="ms-2" data-bs-toggle="modal" data-bs-target={"#phlebotomyOptionsSidebar"+modalString} href="#"><FontAwesomeIcon icon={faPenToSquare} /> Edit</a> : ''}</p>
                  <p className={"fw-normal text-dark "+styles.priceValue}>
                    FREE
                  </p>
                </div>
              </div>
            )}

            {props.phlebotomyOption == 5 && (
              <div className={`p-3 ${props.step != 0 ? 'px-0' : 'px-3'}`}>
                <div className="d-flex justify-content-between align-items-center">
                  <p>Partner Clinic {props.step != 4 ? <a href="" className="ms-2" data-bs-toggle="modal" data-bs-target={"#phlebotomyOptionsSidebar"+modalString} href="#"><FontAwesomeIcon icon={faPenToSquare} /> Edit</a> : ''}</p>
                  <p className={"fw-normal text-dark "+styles.priceValue}>
                    &pound;{props.phlebotomyPrices[5].phlebotomyPrice} <span className={styles.smallText}>Per test</span>
                  </p>
                </div>
              </div>
            )}
          </RenderIf>
          
          <RenderIf isTrue={hasSexualTest}>
            {sexualKitTypes.map(kitName => {
              return (
                <div className={`${props.step != 0 ? 'px-0' : 'px-3'}`}>
                  <div className='text-start d-flex justify-content-between'>
                    <span>{kitName} Kit</span> <span className={"fw-normal text-dark "+styles.priceValue}>FREE</span>
                  </div>
                </div>
              )
            })}
          </RenderIf>

          <SidebarModal hideHeader={true} modalId={"phlebotomyOptionsSidebar"+modalString}>
            <PhlebotomySelector disabledFingerPrick={props.disabledFingerPrick} modalId={"phlebotomyOptionsSidebar"+modalString} partnerClinics={props.partnerClinics} phlebotomyError={props.phlebotomyError} basket={props.basket} guestCheckout={props.guestCheckout} tests={props.tests} updatePhlebotomyOption={props.updatePhlebotomyOption} phlebotomyOption={props.phlebotomyOption} phlebotomyPrices={props.phlebotomyPrices} />
          </SidebarModal>

          {showDiscountField  && (
            <React.Fragment>
              <div className={`pt-3 pb-2 ${props.step != 0 ? 'px-0' : 'px-3'}`}>
                <div className="d-flex justify-content-between align-items-center">


                  <p>{Object.keys(props.partner).length > 0 && parseInt(props.partner[0].partnerDiscount) != 0 && ( <>{props.partner[0].partnerName}</> )} Discount</p>
                  {props.quantityLoading ?
                    <p className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </p>
                  :
                    <p  key={parseFloat(orderDiscount).toFixed(2)} className={"fw-normal text-red text-danger "+styles.priceValue}>
                      -&pound;{parseFloat(orderDiscount).toFixed(2)}
                    </p>
                  }
                  
                </div>
              </div>
            </React.Fragment>
          )}

          {Object.keys(props.user).length > 0 && (props.user.checkoutCredit != 0 && props.user.checkoutCredit != undefined) && (!props.guestCheckout || props.guestCheckout == "unset") && (
            <div className={`pt-3 pb-2 ${props.step != 0 ? 'px-0' : 'px-3'}`}>
              <div className="d-flex justify-content-between align-items-center">
                <p>Credit</p>
                <p  key={parseFloat(props.user.checkoutCredit/100).toFixed(2)} className={"fw-normal text-red text-danger "+styles.priceValue}>
                  -&pound;{parseFloat(Math.abs(props.user.checkoutCredit)/100).toFixed(2)}
                </p>
              </div>
            </div>
          )}

          <RenderIf isTrue={basketHasTracked24}>
            <div className={`pt-3 pb-1 ${props.step != 0 ? 'px-0' : 'px-3'}`}>
              <div className="d-flex justify-content-between align-items-top">
                <p>{deliveryOptionLabel} {props.step != 4 && isGuaranteedDeliveryAvailable ? <a href="" className="ms-2 d-none" data-bs-toggle="modal" data-bs-target={"#deliveryOptionsSidebar"+modalString}><FontAwesomeIcon icon={faPenToSquare} /> Edit</a> : ''}
                </p>
                <p className={"fw-normal text-dark "+styles.priceValue}>
                  {props.deliveryOption == 0 || deliveryPrice == 0 ? deliveryFeeToDisplay : `£${parseFloat(deliveryPrice).toFixed(2)} `}
                </p>
              </div>
            </div>
  
            <SidebarModal hideHeader={true} modalId={"deliveryOptionsSidebar"+modalString}>
              <DeliverySelector showNextDayDelivery={props.deliveryInfo?.showNextDayDelivery || 0} deliveryInfo={props.deliveryInfo} deliveryOption={props.deliveryOption} nextDayPrice={props.nextDayDeliveryPrice} updateDeliveryOption={props.updateDeliveryOption} allowInternational={props.allowInternational}/>
            </SidebarModal>
          </RenderIf>
          
          <RenderIf isTrue={props.step == 4}> 
            <p>
              <br/><strong>{props.customerDetails && props.customerDetails.customerFirstName ? <>{props.customerDetails.customerFirstName}</> : ''} {props.customerDetails && props.customerDetails.customerLastName ? <>{props.customerDetails.customerLastName}</> : ''}</strong>
              {props.customerDetails && props.customerDetails.customerAddress1 ? <><br/> {props.customerDetails.customerAddress1}</> : ''}
              {props.customerDetails && props.customerDetails.customerAddress2 ? <><br/> {props.customerDetails.customerAddress2}</> : ''}
              {props.customerDetails && props.customerDetails.customerAddress3 ? <><br/> {props.customerDetails.customerAddress3}</> : ''}
              {props.customerDetails && props.customerDetails.customerAddress4 ? <><br/> {props.customerDetails.customerAddress4}</> : ''}
              {props.customerDetails && props.customerDetails.customerCity ? <><br/> {props.customerDetails.customerCity}</> : ''}
              {props.customerDetails && props.customerDetails.customerPostcode ? <><br/> {props.customerDetails.customerPostcode}</> : ''}
            </p>
          </RenderIf> 

          <hr/>

          <div className="checkoutBar">
            <div className={`pt-1 pb-1 ${props.step != 0 ? 'px-0' : 'px-3'}`}>
              <div className="d-flex align-items-center justify-content-between align-items-center">
                <div>Total to pay</div>
                {props.quantityLoading ? 
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                :
                <div className={"fw-normal text-dark bespokeTestPrice "+styles.priceValue+" "+styles.largeText}>
                  &pound;{(parseFloat(testPrice)).toFixed(2)} 
                </div>}
              </div> 



              {giftCardTotal != 0.00 && (
                <div className="mt-3">
                  <p className="fw-semibold">&pound;{parseFloat(giftCardTotal).toFixed(2)} will be credited to your account to use in your next purchase</p>
                  {props.guestCheckout ? (
                    <p className="text-danger mt-3">
                      The code you have applied will have credit remaining after this purchase. To be able to apply this credit to your account, an account is required during purchase. If you opt to proceed with purchasing for someone else, this credit may be lost.
                    </p>
                  ) : null}
                </div> 
              )}
        
              {autoCreditAmount != 0 && (
                <div className={"mt-3 "+styles.autoCreditAmount}>A <strong>&pound;{parseFloat(autoCreditAmount).toFixed(2)}</strong> voucher code will be emailed to you after purchase. T&C’s apply.</div> 
              )}

            
              
            </div>
          </div> 
          <hr/>
          <div className="checkoutBar">
            <div className={`pt-1 pb-1 ${props.step != 0 ? 'px-0' : 'px-3'}`}>
              <div className={styles.paymentOptions}>
                <RenderIf isTrue={(!hideKlarna) && ((!subscription || subscription == 0) && parseFloat(testPrice) > 50)}> 
                  <div data-bs-toggle="modal" data-bs-target={"#whyKlarna"+randomModalString} className={styles.payWithKlarna + " d-flex align-items-center justify-content-center"}>
                    <img className="me-2" src="/img/klarna-colour.svg"/> 3 Interest-free payments of &pound;{(parseFloat(testPrice) / 3).toFixed(2)}&nbsp;<u>Learn More</u>
                  </div>
                </RenderIf>
                <SidebarModal modalId={"whyKlarna"+randomModalString}>
                  <div className={styles.klarnaModal + ' px-3'}>
                    <div className="row align-items-center justify-content-center">
                      <div className="col-12 text-center">
                        <img src="/img/klarna-colour.svg"/>
                      </div>
                      <div className="mt-4 col-12 text-center">
                        <h2 className="mb-4">Shop now. Pay over time with Klarna.</h2>
                        <p className="mb-2">Klarna's Pay in 3 is an unregulated credit agreement. Borrowing more than you can afford or paying late may negatively impact your financial status and ability to obtain credit. 18+, UK residents only. Subject to status. Late fees apply. By continuing I accept the <a href="https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en-GB/paylaterin3" target="_blank">Pay in 3 terms.</a></p>
                         
                      </div>
                    </div>
                  </div>
                </SidebarModal>
            
                <div className={`cardTypes d-flex align-items-center justify-content-center ${(!subscription || subscription == 0) && parseFloat(testPrice) > 50 ? 'mt-3' : 'mt-0'}`}>
                  <div className="col-auto me-2">
                    <img className="w-100" src="/img/amex.png" />
                  </div>
                  <div className="col-auto me-2">
                    <img className="w-100" src="/img/visa.png" />
                  </div>
                  <div className="col-auto me-2">
                    <img className="w-100" src="/img/mastercard.png" />
                  </div>
                  <div className="col-auto me-2">
                    <img className="w-100" src="/img/gpay.png" />
                  </div>
                  <div className="col-auto me-2">
                    <img className="w-100" src="/img/applepay.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr/>

        </div>
      ) : null}
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  
  const {
    basket,
    authentication, 
    user
  } = state
 
  return {
    authentication, 
    user,
    basket
  }
}

export default withRouter(connect(mapStateToProps, { 
  loadRenameTest
})(CartSummary))   