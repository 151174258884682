import { parseISO, differenceInYears } from 'date-fns'

function calculateAge(dob) {

  const date = parseISO(dob, "dd/MM/yyyy");
  const age = differenceInYears(new Date(), date);
  return age;
}

export default function validateField(value, type) {
  let error = false
    
  if (!value || value == "") {
    error = true;
  } else {
    
    if (type == "text"){
      error = /\d/.test(value)
    } else if (type == "email") {
      error = !(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value))
    } else if (type == 'PhoneNumber'){
      error = !(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value))
    } else if (type == 'DateOfBirth') {
      if ((calculateAge(value) < 18)) {
        error = true
      }
    } else if (type == 'Postcode') {
      error = !(/^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i.test(value))
    } else if (type == "bool") {
      error = value <= 1 || !value
    } else if (type == "int") {
      error = value == 0 || !value
    }
  }
  if (error) {
    return false;
  } else {
    return true;
  } 
}
