import React, {useState, useEffect, useRef} from 'react'
import { useDispatch } from "react-redux";
import formValidator from '../../../helpers/formValidator'; 
import {Alert, Button, LoadingSpinner} from '../../';
import styles from "./LoginForm.module.scss";
import { InputEmailRef, InputPasswordRef } from '../FormFields';
import RenderIf from '../../RenderIf/RenderIf';
import base64 from 'base-64' 
import { logout } from '../../../actions/authentication-actions'
 

const LIST_APP_URLS = {
  1: "https://app.forthwithlife.co.uk",
  2: "https://app.sportsbloodtests.co.uk",
  3: "https://app.biomarkerTracking.com"
}

const APP_URL = LIST_APP_URLS[global.config.websiteId] || LIST_APP_URLS[1]

const isBiomarkerTracking = global.config.websiteId != 1 && global.config.websiteId != 2

export default function LoginForm(props) {  
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [useLogin, setUseLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [checkingEmail, setCheckingEmail] = useState(false);
  const email = useRef(props.customerDetails.customerEmailAddress);
  const password = useRef('');

  const dispatch = useDispatch()

  const INITIAL_HRT_SELECTED_VALUE = {
    3: false,
    4: false,
    5: false
  }

  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    props.setNewAccount(false)
    setUseLogin(false)
    setError(false)
    setErrorMessage('')

 
    if (email.current.value != '' && formValidator(email.current.value, 'email') == true) {
      setCheckingEmail(true);
      const delayDebounceFn = setTimeout(() => {
        updateInputs()
        // Send Axios request here
      }, 300)
      
      return () => clearTimeout(delayDebounceFn)
    } else {
      setCheckingEmail(false);
    } 
 
    
  }, [searchTerm])

  useEffect(() => { 
  }, [props.newAccount])

  const updateInputs = () => {
    setCheckingEmail(true);
    let checkEmail = formValidator(email.current.value, 'email');
    if (checkEmail) {
      setError(false);
      setErrorMessage(''); 
      props.setCanEnterDetails(true);
      submitForm()
      setCheckingEmail(false);
    } else {
      setError(true);
      props.setCanEnterDetails(false);
      setCheckingEmail(false);
      setErrorMessage('Please enter a valid email address');
    }  
  }

  const submitForm = (event) => {
    //event.preventDefault();
    setError(true);
    let guestCheckout = props.guestCheckout;
    if (guestCheckout == "unset") {
      guestCheckout = false;
    }
 
    if (!guestCheckout) {

      const authentication = props.authentication;

      if (!authentication.isAuthenticated) {
        setIsLoading(true);
        setError(true);
        setErrorMessage('Missing password'); 
        props.setCanEnterDetails(false); 
       

        let r = (Math.random() + 1).toString(36).substring(2);
        let payload = {
          customerEmailAddress:  email.current.value,
          customerPassword:  r,
          orderCookieId: localStorage.getItem("orderCookieId"),
        };

        props.checkEmailExist({customerEmailAddress:  email.current.value})
        .then( response => {
          if(!response.error){
            const { emailExist } = response.response

            if(emailExist){
              setUseLogin(true);
              props.setNewAccount(false)
              props.setCanEnterDetails(false); 
              props.setCustomerDetails({
                customerEmailAddress:  email.current.value,
                ...props.customerDetails
              })
            } else {
              props.setNewAccount(true)
              props.setCustomerDetails({
                ...props.customerDetails,
                userId: 0,
                customerFirstName: "",
                customerLastName: "",
                customerEmailAddress:  email.current.value,
                customerPassword: "",
                customerDOBD: "",
                customerDOBM: "",
                customerDOBY: "",
                customerSex: "",
                customerMenopausal: "",
                customerHRT: "",
                customerHRTSelected: INITIAL_HRT_SELECTED_VALUE,
                customerContraception: "",
                customerContraceptionSelected: "",
                customerRegularPeriods: "",
                customerIsPregnant: "",
                customerIsBreastfeeding: "",
                customerAverageCycleLength: "",
                customerMainGoal: "",
                customerAddress1: "",
                customerAddress2: "",
                customerAddress3: "",
                customerCity: "",
                customerCountry: "",
                customerPostcode: "",
                customerHomeTelephone: "",
                customerOptIn: 0,
                doNotReview: 0,
                grantPartnerPermission: false
              })
            }

            if(!emailExist){
              props.setCanEnterDetails(true); 
              props.setCustomerDetails({
                ...props.customerDetails,
                userId: 0,
                customerFirstName: "",
                customerLastName: "",
                customerEmailAddress:  email.current.value,
                customerPassword: "",
                customerDOBD: "",
                customerDOBM: "",
                customerDOBY: "",
                customerSex: "",
                customerMenopausal: "",
                customerHRT: "",
                customerHRTSelected: INITIAL_HRT_SELECTED_VALUE,
                customerContraception: "",
                customerContraceptionSelected: "",
                customerRegularPeriods: "",
                customerIsPregnant: "",
                customerIsBreastfeeding: "",
                customerAverageCycleLength: "",
                customerMainGoal: "",
                customerAddress1: "",
                customerAddress2: "",
                customerAddress3: "",
                customerCity: "",
                customerCountry: "",
                customerPostcode: "",
                customerHomeTelephone: "",
                customerOptIn: 0,
                doNotReview: 0,
                grantPartnerPermission: false
              })
            }
          }
        }).finally(() => setIsLoading(false));     
      }

      if (!error) { 
        setError(false);
        setErrorMessage(""); 
        props.setCanEnterDetails(true); 
      }

    } else {
      props.setReceiptEmailAddress(email.current.value)
    }
    
  }

  const submitLogin = (e) => {
    e.preventDefault();
    if (props.guestCheckout == false || props.guestCheckout == 'unset') {
      setIsAuthenticating(true)
      if (e.target.checkValidity() !== false) {
        if (email.current.value && password.current.value) {
          props.loadToken(email.current.value, password.current.value, '', global.config.websiteId, 'CO')
          .then((response) => {
            if (response.error) {
              setError(true);
              props.setCanEnterDetails(false);
              setErrorMessage(response.error); 
              setIsAuthenticating(false);
            } else {  
              setError(false);
              props.setCanEnterDetails(true);
              setErrorMessage(''); 
      

              props.loadUser('me').then((response) => { 
                if (response.error) {
                  setError(true);
                  props.setCanEnterDetails(false); 
                  setErrorMessage(response.error); 
                  setIsAuthenticating(false);
                } else {
                  props.reloadUserData();
                  setError(false);
                  props.setCanEnterDetails(true);
                  setErrorMessage(''); 
                  setIsAuthenticating(false);
                  if (!response.error && (response.response.partnerId != 0 && config.websiteId == 3)){
                    props.loadPartnerDetailsUnsecure(response.response.partnerId).then((res)=>{ 
                      props.setPartner(res.response.entities.partner)    
                      if(isBiomarkerTracking) {
                        localStorage.setItem('partnerPortalId', response.response.partnerId);  
                      } else {
                        localStorage.setItem('partnerPortalId', 0)
                      }
                    })
                  }  

                  if(!response.response.referCode){
                    props.generateReferCode().then((response) => {
                      if (!response.error) {
                        props.setCustomerDetails({
                          ...props.customerDetails,
                          referCode: response.response.voucherCode
                        })
                      }
                    })
                  }
                } 
              }); 
            }
 
             
          });
        }
      }
    } 
  }

  const resetLogin = () => {
    setSearchTerm("");
    setUseLogin(false)
    setErrorMessage("");
    props.setCanEnterDetails(false)
    props.setCustomerDetails({
      customerDetails: {}
    });
    props.reloadUserData();

    email.current.value = ""
  }

  const handleSubmit = (e) => {

    if (!useLogin) { 
      e.preventDefault(); 
    } else { 
      submitLogin(e);
    }
  }

  return ( 

    <div className="my-3" id={styles.loginForm}>

      <form onSubmit={(e) => {handleSubmit(e)}}>

        <div className="position-relative">
          <InputEmailRef label={props.guestCheckout ? "Email address to receive receipt" : "Email address"} isLoading={checkingEmail} disabled={props.authentication.isAuthenticated ? true : false} required onChange={(e) => setSearchTerm(e.target.value)} defaultValue={email?.current?.value || props.customerDetails.customerEmailAddress} ref={email} type="email" className={`form-control required py-2 ${props.authentication.isAuthenticated ? "disabled" : ""} `} id="customerEmailAddress" placeholder="Enter your email address" autoComplete="email"/>
        </div> 

         {!useLogin && (
          <>
          <Alert variant="danger" className="mt-3">
            {errorMessage}
          </Alert>

          <RenderIf isTrue={props.newAccount}>
            <div className="text-center mt-4">
              <p className="mb-0 fw-normal">Looks like you're new to Forth</p>
              <p className="fw-normal">Fill in your details below to get started</p>
            </div>
          </RenderIf>

          <RenderIf isTrue={props.authentication.isAuthenticated}>
            <div className="text-center mt-2">
              <p className="mb-0 fw-normal">Not you?</p>
              <p className="fw-normal">Sign out to login to your account or create a new account</p>
            </div>

            <Button variant="primary-outline" onClick={()=>{dispatch(logout()); resetLogin();}} className="d-flex w-100 justify-content-center align-items-center" disabled={isAuthenticating} isLoading={false}>
              Log Out
            </Button>
          </RenderIf>
          </>

         )}


        {useLogin && (
          <>
          <RenderIf isTrue={!props.authentication.isAuthenticated}>
            <div className="text-center mt-2">
              <p className="mb-0 fw-normal">Looks like you already have an account.</p>
              <p className="fw-normal">Sign in to continue</p>
            </div>
            <InputPasswordRef label="Password" labelClassName="mt-3" ref={password} className="form-control passwordLogin py-2" name="loginPassword" id="loginPassword" required placeholder="Password" autoComplete="current-password" showPassword={true}/>
          </RenderIf>
          
          <Alert variant="danger" className="mt-3">
            {errorMessage}
          </Alert>

          <RenderIf isTrue={!props.authentication.isAuthenticated}>
            <Button href={`${APP_URL}/sign-in?forgot-password`} variant={global.config.websiteId == 1 ? "link-default" : "link"} target="_blank" className="w-100 d-block text-center my-2 text-decoration-underline">
              Forgot password
            </Button>

            <div className="row">
              <div className="col">
                <Button variant="primary-outline" type="button" onClick={resetLogin} className="d-flex w-100 justify-content-center align-items-center" disabled={isAuthenticating} isLoading={false}>
                  Clear
                </Button>
              </div>
              <div className="col">
                <Button variant="primary-outline" type="submit" className="d-flex w-100 justify-content-center align-items-center" disabled={isAuthenticating} isLoading={false}>
                  Sign in
                </Button>
              </div>
            </div>
          </RenderIf>
          <RenderIf isTrue={props.authentication.isAuthenticated}>
            <div className="text-center mt-2">
              <p className="mb-0 fw-normal">Not you?</p>
              <p className="fw-normal">Sign out to login to your account or create a new account</p>
            </div>

            <Button variant="primary-outline" onClick={()=>{dispatch(logout()); resetLogin();}} className="d-flex w-100 justify-content-center align-items-center" disabled={isAuthenticating} isLoading={false}>
              Log out
            </Button>
          </RenderIf>
          </>
        )}

      </form>
      
      
       
 
    </div>

  )
}