import { CALL_API, Schemas } from '../middleware/api'

export const LATEST_RESULTS_REQUEST = 'LATEST_RESULTS_REQUEST'
export const LATEST_RESULTS_SUCCESS = 'LATEST_RESULTS_SUCCESS'
export const LATEST_RESULTS_FAILURE = 'LATEST_RESULTS_FAILURE'

const fetchLatestResult = (userId) => ({
  userId,
  [CALL_API]: {
    types: [ LATEST_RESULTS_REQUEST, LATEST_RESULTS_SUCCESS, LATEST_RESULTS_FAILURE ],
    endpoint: `secure/results/latest-result/${userId}`,
    schema: Schemas.RESULT
  }
})

export const loadLatestResult = (userId) => (dispatch, getState) => {
  const result = getState().entities.result;
  if (Object.keys(result).length > 0) {
    return null
  }

  return dispatch(fetchLatestResult(userId))
}


export const IMPROVED_RESULTS_REQUEST = 'IMPROVED_RESULTS_REQUEST'
export const IMPROVED_RESULTS_SUCCESS = 'IMPROVED_RESULTS_SUCCESS'
export const IMPROVED_RESULTS_FAILURE = 'IMPROVED_RESULTS_FAILURE'

const fetchImprovedResults = (userId) => ({
  userId,
  [CALL_API]: {
    types: [ IMPROVED_RESULTS_REQUEST, IMPROVED_RESULTS_SUCCESS, IMPROVED_RESULTS_FAILURE ],
    endpoint: `secure/results/improved-markers/${userId}`,
    schema: Schemas.IMPROVED_RESULTS
  }
})

export const loadImprovedResults = (userId) => (dispatch, getState) => {
 

  return dispatch(fetchImprovedResults(userId))
}


export const OUT_OF_RANGE_RESULTS_REQUEST = 'OUT_OF_RANGE_RESULTS_REQUEST'
export const OUT_OF_RANGE_RESULTS_SUCCESS = 'OUT_OF_RANGE_RESULTS_SUCCESS'
export const OUT_OF_RANGE_RESULTS_FAILURE = 'OUT_OF_RANGE_RESULTS_FAILURE'

const fetchOutOfRangeResults = (userId) => ({
  userId,
  [CALL_API]: {
    types: [ OUT_OF_RANGE_RESULTS_REQUEST, OUT_OF_RANGE_RESULTS_SUCCESS, OUT_OF_RANGE_RESULTS_FAILURE ],
    endpoint: `secure/results/out-of-range-markers/${userId}`,
    schema: Schemas.OUT_OF_RANGE_RESULTS
  }
})

export const loadOutOfRangeResults = (userId) => (dispatch, getState) => {
  return dispatch(fetchOutOfRangeResults(userId))
}



export const COMPLETE_INCOMPLETE_RESULTS_REQUEST = 'COMPLETE_INCOMPLETE_RESULTS_REQUEST'
export const COMPLETE_INCOMPLETE_RESULTS_SUCCESS = 'COMPLETE_INCOMPLETE_RESULTS_SUCCESS'
export const COMPLETE_INCOMPLETE_RESULTS_FAILURE = 'COMPLETE_INCOMPLETE_RESULTS_FAILURE'

const fetchCompleteIncompleteMarkerResults = (userId) => ({
  userId,
  [CALL_API]: {
    types: [ COMPLETE_INCOMPLETE_RESULTS_REQUEST, COMPLETE_INCOMPLETE_RESULTS_SUCCESS, COMPLETE_INCOMPLETE_RESULTS_FAILURE ],
    endpoint: `secure/results/completed-biomarkers/${userId}`,
    schema: Schemas.COMPLETE_INCOMPLETE_RESULTS
  }
})

export const loadCompleteIncompleteMarkerResults = (userId) => (dispatch, getState) => {
  return dispatch(fetchCompleteIncompleteMarkerResults(userId))
}



export const CURRENT_BIOMARKER_REQUEST = 'CURRENT_BIOMARKER_REQUEST'
export const CURRENT_BIOMARKER_SUCCESS = 'CURRENT_BIOMARKER_SUCCESS'
export const CURRENT_BIOMARKER_FAILURE = 'CURRENT_BIOMARKER_FAILURE'

const fetchCurrentMarker = (biomarkerResultId) => ({
  biomarkerResultId,
  [CALL_API]: {
    types: [ CURRENT_BIOMARKER_REQUEST, CURRENT_BIOMARKER_SUCCESS, CURRENT_BIOMARKER_FAILURE ],
    endpoint: `/secure/results/current-marker/${biomarkerResultId}`,
    schema: Schemas.CURRENT_BIOMARKER
  }
})

export const loadCurrentMarker = (biomarkerResultId) => (dispatch, getState) => {
  const currentMarker = getState().entities.currentMarker;

  if (Object.keys(currentMarker).length > 0) {
    Object.keys(currentMarker).map(function(key) {

      delete currentMarker[key];

    })  
  }

  return dispatch(fetchCurrentMarker(biomarkerResultId))
}


export const BIOMARKER_AVERAGE_REQUEST = 'BIOMARKER_AVERAGE_REQUEST'
export const BIOMARKER_AVERAGE_SUCCESS = 'BIOMARKER_AVERAGE_SUCCESS'
export const BIOMARKER_AVERAGE_FAILURE = 'BIOMARKER_AVERAGE_FAILURE'

const fetchMarkerAverage = (biomarkerResultId) => ({
  biomarkerResultId,
  [CALL_API]: {
    types: [ BIOMARKER_AVERAGE_REQUEST, BIOMARKER_AVERAGE_SUCCESS, BIOMARKER_AVERAGE_FAILURE ],
    endpoint: `/secure/results/marker-average/${biomarkerResultId}`,
    schema: Schemas.BIOMARKER_AVERAGE
  }
})

export const loadMarkerAverage = (biomarkerResultId) => (dispatch, getState) => {
  const markerAverage = getState().entities.markerAverage;
  if (Object.keys(markerAverage).length > 0) {
    Object.keys(markerAverage).map(function(key) {

      delete markerAverage[key];

    })  
  }

  return dispatch(fetchMarkerAverage(biomarkerResultId))
}

 


export const CURRENT_BIOMARKER_HISTORY_REQUEST = 'CURRENT_BIOMARKER_HISTORY_REQUEST'
export const CURRENT_BIOMARKER_HISTORY_SUCCESS = 'CURRENT_BIOMARKER_HISTORY_SUCCESS'
export const CURRENT_BIOMARKER_HISTORY_FAILURE = 'CURRENT_BIOMARKER_HISTORY_FAILURE'

const fetchCurrentMarkerHistory = (biomarkerId) => ({
  biomarkerId,
  [CALL_API]: {
    types: [ CURRENT_BIOMARKER_HISTORY_REQUEST, CURRENT_BIOMARKER_HISTORY_SUCCESS, CURRENT_BIOMARKER_HISTORY_FAILURE ],
    endpoint: `/secure/results/current-marker-history/${biomarkerId}`,
    schema: Schemas.CURRENT_BIOMARKER_HISTORY
  }
})

export const loadCurrentMarkerHistory = (biomarkerId) => (dispatch, getState) => {
  const currentMarkerHistory = getState().entities.currentHistoryMarker;

  if (Object.keys(currentMarkerHistory).length > 0) {
    Object.keys(currentMarkerHistory).map(function(key) {

      delete currentMarkerHistory[key];

    })  
  }

  return dispatch(fetchCurrentMarkerHistory(biomarkerId))
}

export const ALL_BIOMARKER_HISTORY_REQUEST = 'ALL_BIOMARKER_HISTORY_REQUEST'
export const ALL_BIOMARKER_HISTORY_SUCCESS = 'ALL_BIOMARKER_HISTORY_SUCCESS'
export const ALL_BIOMARKER_HISTORY_FAILURE = 'ALL_BIOMARKER_HISTORY_FAILURE'

const fetchMarkerHistory = (userId) => ({
  userId,
  [CALL_API]: {
    types: [ ALL_BIOMARKER_HISTORY_REQUEST, ALL_BIOMARKER_HISTORY_SUCCESS, ALL_BIOMARKER_HISTORY_FAILURE ],
    endpoint: `/secure/results/get-marker-history/${userId}`,
    schema: Schemas.BIOMARKER_HISTORY
  }
})

export const loadMarkerHistory = (userId) => (dispatch, getState) => {
  var markerHistory = getState().entities.markerHistory;
  if (Object.keys(markerHistory).length > 0) {
    Object.keys(markerHistory).map(function(key) {

      delete markerHistory[key];

    })  
  }

  return dispatch(fetchMarkerHistory(userId))
}

export const ALL_BIOMARKERS_FROM_ORDER_REQUEST = 'ALL_BIOMARKERS_FROM_ORDER_REQUEST'
export const ALL_BIOMARKERS_FROM_ORDER_SUCCESS = 'ALL_BIOMARKERS_FROM_ORDER_SUCCESS'
export const ALL_BIOMARKERS_FROM_ORDER_FAILURE = 'ALL_BIOMARKERS_FROM_ORDER_FAILURE'

const fetchAllResultsFromOrder = (orderId) => ({
  orderId,
  [CALL_API]: {
    types: [ ALL_BIOMARKERS_FROM_ORDER_REQUEST, ALL_BIOMARKERS_FROM_ORDER_SUCCESS, ALL_BIOMARKERS_FROM_ORDER_FAILURE ],
    endpoint: `/secure/results/all-order-results/${orderId}`,
    schema: Schemas.ALL_BIOMARKERS_FROM_ORDER
  }
})

export const loadAllResultsFromOrder = (orderId) => (dispatch, getState) => {
  var orderBiomarkers = getState().entities.orderBiomarkers;
  if (Object.keys(orderBiomarkers).length > 0) {
    Object.keys(orderBiomarkers).map(function(key) {

      delete orderBiomarkers[key];

    })  
  }
  return dispatch(fetchAllResultsFromOrder(orderId))
}


export const ALL_LATEST_BIOMARKERS_REQUEST = 'ALL_LATEST_BIOMARKERS_REQUEST'
export const ALL_LATEST_BIOMARKERS_SUCCESS = 'ALL_LATEST_BIOMARKERS_SUCCESS'
export const ALL_LATEST_BIOMARKERS_FAILURE = 'ALL_LATEST_BIOMARKERS_FAILURE'

const fetchAllLatestBiomarkers = (userId) => ({
  userId,
  [CALL_API]: {
    types: [ ALL_LATEST_BIOMARKERS_REQUEST, ALL_LATEST_BIOMARKERS_SUCCESS, ALL_LATEST_BIOMARKERS_FAILURE ],
    endpoint: `/secure/results/get-latest-markers/${userId}`,
    schema: Schemas.ALL_LATEST_BIOMARKERS
  }
})

export const loadAllLatestBiomarkers = (userId) => (dispatch, getState) => {
  var allLatestBiomarkers = getState().entities.allLatestBiomarkers;
  if (Object.keys(allLatestBiomarkers).length > 0) {
    Object.keys(allLatestBiomarkers).map(function(key) {

      delete allLatestBiomarkers[key];

    })  
  }
  return dispatch(fetchAllLatestBiomarkers(userId))
}


export const SHARE_REQUEST = 'SHARE_REQUEST'
export const SHARE_SUCCESS = 'SHARE_SUCCESS'
export const SHARE_FAILURE = 'SHARE_FAILURE'

const fetchShareRequest = (body) => ({
  [CALL_API]: {
    types: [ SHARE_REQUEST, SHARE_SUCCESS, SHARE_FAILURE ],
    endpoint: 'secure/results/share',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateShareRequest = (body) => (dispatch, getState) => {
  return dispatch(fetchShareRequest(body))
}; 


export const SHARED_BIOMARKER_REQUEST = 'SHARED_BIOMARKER_REQUEST'
export const SHARED_BIOMARKER_SUCCESS = 'SHARED_BIOMARKER_SUCCESS'
export const SHARED_BIOMARKER_FAILURE = 'SHARED_BIOMARKER_FAILURE'

const fetchSharedBiomarker = (biomarkerResultId, userId) => ({
  biomarkerResultId,
  userId,
  [CALL_API]: {
    types: [ SHARED_BIOMARKER_REQUEST, SHARED_BIOMARKER_SUCCESS, SHARED_BIOMARKER_FAILURE ],
    endpoint: `results/shared/${biomarkerResultId}/${userId}`,
    schema: Schemas.SHARED_BIOMARKER
  }
})

export const loadSharedBiomarker = (biomarkerResultId, userId) => (dispatch, getState) => {
  var sharedBiomarker = getState().entities.sharedBiomarker;
  if (Object.keys(sharedBiomarker).length > 0) {
    Object.keys(sharedBiomarker).map(function(key) {

      delete sharedBiomarker[key];

    })  
  }
  return dispatch(fetchSharedBiomarker(biomarkerResultId, userId))
} 


export const FEMALE_HORMONES_RESULTS_REQUEST = 'FEMALE_HORMONES_RESULTS_REQUEST'
export const FEMALE_HORMONES_RESULTS_SUCCESS = 'FEMALE_HORMONES_RESULTS_SUCCESS'
export const FEMALE_HORMONES_RESULTS_FAILURE = 'FEMALE_HORMONES_RESULTS_FAILURE'

const fetchFemaleHormonesResult = (orderId) => ({
  orderId,
  [CALL_API]: {
    types: [ FEMALE_HORMONES_RESULTS_REQUEST, FEMALE_HORMONES_RESULTS_SUCCESS, FEMALE_HORMONES_RESULTS_FAILURE ],
    endpoint: `secure/results/get-hormone-results/${orderId}`,
    schema: Schemas.FEMALE_HORMONES_RESULTS
  }
})

export const loadFemaleHormonesResult = (orderId) => (dispatch, getState) => {
  var femaleHormoneResults = getState().entities.femaleHormoneResults;
  if (Object.keys(femaleHormoneResults).length > 0) {
    Object.keys(femaleHormoneResults).map(function(key) {

      delete femaleHormoneResults[key];

    })  
  }
  return dispatch(fetchFemaleHormonesResult(orderId))
}

export const UPDATE_TEST_RATING_REQUEST = 'UPDATE_TEST_RATING_REQUEST'
export const UPDATE_TEST_RATING_SUCCESS = 'UPDATE_TEST_RATING_SUCCESS'
export const UPDATE_TEST_RATING_FAILURE = 'UPDATE_TEST_RATING_FAILURE'

const fetchUpdateRating = (body) => ({
  [CALL_API]: {
    types: [UPDATE_TEST_RATING_REQUEST, UPDATE_TEST_RATING_SUCCESS, UPDATE_TEST_RATING_FAILURE],
    endpoint: `secure/results/update-rating`,
    schema: null, 
    method: 'POST', 
    body: body
  }
})

export const updateRating = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdateRating(body))
}



const fetchGetRating = (orderId, ratingType) => ({
  orderId,
  [CALL_API]: {
    types: [GET_TEST_RATING_REQUEST, GET_TEST_RATING_SUCCESS, GET_TEST_RATING_FAILURE],
    endpoint: `secure/results/get-rating/${orderId}/${ratingType}`,
    schema: Schemas.RATINGS_RESULTS, 
  }
})

export const GET_TEST_RATING_REQUEST = 'GET_TEST_RATING_REQUEST'
export const GET_TEST_RATING_SUCCESS = 'GET_TEST_RATING_SUCCESS'
export const GET_TEST_RATING_FAILURE = 'GET_TEST_RATING_FAILURE'

export const getRating = (orderId, ratingType) => (dispatch, getState) => {
  var ratings = getState().entities.ratings;
  if (Object.keys(ratings).length > 0) {
    Object.keys(ratings).map(function(key) {

      delete ratings[key];

    })  
  }
  
  return dispatch(fetchGetRating(orderId, ratingType))

}

const fetchGetPDF = (orderId, resultType, dataType) => ({
  orderId,
  [CALL_API]: {
    types: [GET_TEST_PDF_REQUEST, GET_TEST_PDF_SUCCESS, GET_TEST_PDF_FAILURE],
    endpoint: `secure/results/get-pdf/${resultType}/${dataType}/${orderId}`,
    schema: Schemas.PDF_RESULTS, 
  }
})

export const GET_TEST_PDF_REQUEST = 'GET_TEST_PDF_REQUEST'
export const GET_TEST_PDF_SUCCESS = 'GET_TEST_PDF_SUCCESS'
export const GET_TEST_PDF_FAILURE = 'GET_TEST_PDF_FAILURE'

export const getPDF = (orderId, resultType, dataType) => (dispatch, getState) => {
  var pdfs = getState().entities.pdfs;
  if (Object.keys(pdfs).length > 0) {
    Object.keys(pdfs).map(function(key) {

      delete pdfs[key];

    }) 
  } 
  return dispatch(fetchGetPDF(orderId, resultType, dataType))
}

const fetchDeletePDF = (fileName) => ({
  [CALL_API]: {
    types: [DELETE_TEST_PDF_REQUEST, DELETE_TEST_PDF_SUCCESS, DELETE_TEST_PDF_FAILURE],
    endpoint: `secure/results/delete-pdf/${fileName}`,
    schema: Schemas.PDF_RESULTS, 
  }
})

export const DELETE_TEST_PDF_REQUEST = 'DELETE_TEST_PDF_REQUEST'
export const DELETE_TEST_PDF_SUCCESS = 'DELETE_TEST_PDF_SUCCESS'
export const DELETE_TEST_PDF_FAILURE = 'DELETE_TEST_PDF_FAILURE'

export const deletePDF = (fileName) => (dispatch, getState) => {
  var pdfs = getState().entities.pdfs;
  if (Object.keys(pdfs).length > 0) {
    Object.keys(pdfs).map(function(key) {

      delete pdfs[key];

    }) 
  } 
  return dispatch(fetchDeletePDF(fileName))
}

const fetchFHMExport = (body) => ({
  [CALL_API]: {
    types: [GET_TEST_PDF_REQUEST, GET_TEST_PDF_SUCCESS, GET_TEST_PDF_FAILURE],
    endpoint: `secure/results/get-fhm-pdf`,
    schema: Schemas.PDF_RESULTS, 
    method: 'POST', 
    body: body
  }
})

export const getFHMExport = (body) => (dispatch, getState) => {
  return dispatch(fetchFHMExport(body))
}

export const SEND_TRUSTPILOT_REVIEW_EMAIL_REQUEST = 'SEND_TRUSTPILOT_REVIEW_EMAIL_REQUEST'
export const SEND_TRUSTPILOT_REVIEW_EMAIL_SUCCESS = 'SEND_TRUSTPILOT_REVIEW_EMAIL_SUCCESS'
export const SEND_TRUSTPILOT_REVIEW_EMAIL_FAILURE = 'SEND_TRUSTPILOT_REVIEW_EMAIL_FAILURE'

const fetchSendTrustpilotReviewEmail = (body) => ({
  [CALL_API]: {
    types: [SEND_TRUSTPILOT_REVIEW_EMAIL_REQUEST, SEND_TRUSTPILOT_REVIEW_EMAIL_SUCCESS, SEND_TRUSTPILOT_REVIEW_EMAIL_FAILURE],
    endpoint: `secure/results/send-trustpilot-review-email`,
    schema: null, 
    method: 'POST', 
    body: body
  }
})

export const sendTrustpilotReviewEmail = (body) => (dispatch, getState) => {
  return dispatch(fetchSendTrustpilotReviewEmail(body))
}

export const LOAD_BLOOD_LIMITS_REQUEST = 'LOAD_BLOOD_LIMITS_REQUEST'
export const LOAD_BLOOD_LIMITS_SUCCESS = 'LOAD_BLOOD_LIMITS_SUCCESS'
export const LOAD_BLOOD_LIMITS_FAILURE = 'LOAD_BLOOD_LIMITS_FAILURE'

const fetchBloodLimits = () => ({
  [CALL_API]: {
    types: [LOAD_BLOOD_LIMITS_REQUEST, LOAD_BLOOD_LIMITS_SUCCESS, LOAD_BLOOD_LIMITS_FAILURE],
    endpoint: `/get-blood-limits`,
    schema: null, 
  }
})

export const loadBloodLimits = () => (dispatch, getState) => {
  return dispatch(fetchBloodLimits())
}