import { isEmpty } from "lodash";

const SEXUAL_TEST_TYPES = {
  4: "Throat swab",
  5: "Saliva",
  6: "Urine",
  7: "Vagina Swab",
  8: "Rectal Swab",
  9: "Finger Prick and Urine",
  10: "Finger Prick and Vaginal swab",
  11: "Penile Swab",
  12: "Finger prick and penile swab"
}

export const checkBasketHasSexualTest = (tests = {}) => {
  if(isEmpty(tests)) return false;
  if(typeof tests !== "object") return false;
  
  return Object.values(tests).some(test => Object.keys(SEXUAL_TEST_TYPES).map(testType => testType.toString()).includes(test.testType))
}

export const checkTestTypeIsSexualTest = (testType) => {
  if(!testType) return false;
  return Object.keys(SEXUAL_TEST_TYPES).map(testType => testType.toString()).includes(testType.toString())
}