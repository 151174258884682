import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import RenderIf from '../RenderIf/RenderIf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxes, faChartBar, faReceipt, faTint, faUsers } from '@fortawesome/pro-light-svg-icons';
class MobileMenu extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { partner } = this.props
    const hasAccessToShockedKits = partner[0]?.showStockedKits == 1 ? true : false

    return (
      <div className="row text-center align-items-center justify-content-center g-0 text-uppercase px-2">
        <div className="col">
          <NavLink exact activeClassName="active" className="nav-link px-0 mobile-nav d-flex justify-content-center align-content-center flex-column" to="/">
            <FontAwesomeIcon icon={faChartBar} className='pb-2'/>
            Dashboard
          </NavLink>
        </div>
        <div className="col position-relative">
          <NavLink exact activeClassName="active" className="nav-link px-0 mobile-nav d-flex justify-content-center align-content-center flex-column" to="/partner-orders">
            <FontAwesomeIcon icon={faReceipt} className='pb-2'/>
            Orders
          </NavLink>
          {/* <span className="notif-num"></span> */}
        </div>
        <RenderIf isTrue={hasAccessToShockedKits}>
          <div className="col position-relative">
            <NavLink exact activeClassName="active" className="nav-link px-0 mobile-nav d-flex justify-content-center align-content-center flex-column" to="/stocked-kits">
              <FontAwesomeIcon icon={faBoxes} className='pb-2'/>
              Stocked kits
            </NavLink>
            {/* <span className="notif-num"></span> */}
          </div>
        </RenderIf>
        <div className="col position-relative">
          <NavLink exact to="/partner-results" isActive={(match, location) => location.pathname.split("/").includes('partner-results')} className="nav-link px-0 mobile-nav d-flex justify-content-center align-content-center flex-column">
            <FontAwesomeIcon icon={faTint} className='pb-2'/>
            Results
          </NavLink>
          {/* <span className="notif-num"></span> */}
        </div>
        <div className="col">
          <NavLink exact to="/partner-clients" isActive={(match, location) => location.pathname.split("/").includes('partner-clients')} className="nav-link mobile-nav d-flex justify-content-center align-content-center flex-column px-0">
            <FontAwesomeIcon icon={faUsers} className='pb-2'/>
            Clients
          </NavLink>
        </div>
        {/*
        <div className="col">
          <div className="mobile-nav d-flex justify-content-center align-content-center flex-column">
            <FontAwesomeIcon icon={faLifeRing} className="pb-2" />
            <p className="px-0">Help Guides</p>
          </div>
        </div>
        */}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {

  const { 
    entities:{partner},
  } = state
 

  return {
    partner,
  }
}

export default withRouter(connect(mapStateToProps, { 
})(MobileMenu))