import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect, Route, withRouter } from 'react-router-dom'

class PrivateRoute extends Component {
  static propTypes = {
    component: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      redirectToLogin: false
    };
  }

  componentWillMount() {}

  componentDidMount() {
 
    this.taskRunner();
  }

  taskRunner() {
    setInterval((function(page){
      return function(){
        
        var triggerLogout = localStorage.getItem('triggerLogout'); 
        if (triggerLogout == 'true') {

          this.setState({redirectToLogin: true});

        }
      };
    })(this),800);
  }

  render() {
    const { component: Component, ...rest } = this.props

    return (
      <Route {...rest} render={(props) => (
        this.props.authentication.isAuthenticated === true || this.state.redirectToLogin === true
          ? <Component {...props} />
          : <Redirect to={{
              pathname: '/sign-in',
              state: { from: props.location }
            }} />
      )} />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    authentication
  } = state

  return {
    authentication
  }
}

export default withRouter(connect(mapStateToProps, {
})(PrivateRoute))