import React, {useState, useEffect} from 'react' 
import styles from "./CheckoutProgressBar.module.scss"; 
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const steps = {
  basketScreen: 0,
  testOptionsScreen: 1,
  clientDetailsScreen: 2,
  deliveryScreen: 3,
  paymentScreen: 4,
  paymentSuccess: 5,
  endoAdditionalQuestions: 6
}

export default function CheckoutProgressBar(props) {  
  const [stepNames, setStepNames] = useState([])
  const [stepNumbers, setStepNumbers] = useState([])
 
  const returnToStep = (step) => {
    if(!step) return;

    props.goToStep(step);
  }


  useEffect(()=> {
    const {
      basketScreen,
      testOptionsScreen,
      clientDetailsScreen,
      deliveryScreen,
      paymentScreen,
      paymentSuccess,
      endoAdditionalQuestions
    } = steps
    if (props.checkoutFromPartner == 1) {
      setStepNames(["Client details", "Delivery", "Payment"])
      setStepNumbers([clientDetailsScreen, deliveryScreen, paymentScreen])
    } else if (props.skipStepOne) {
      setStepNames(["Your details", "Delivery", "Payment"])
      setStepNumbers([clientDetailsScreen, deliveryScreen, paymentScreen])
    } else if (props.guestCheckout) {
      setStepNames(["Test options", "Your details", "Payment"])
      setStepNumbers([testOptionsScreen, clientDetailsScreen, paymentScreen])
    } else if (props.showQuestionAdditionalStep) {
      setStepNames(["Check eligibility", "Your details", "Delivery", "Payment"])
      setStepNumbers([endoAdditionalQuestions, clientDetailsScreen, deliveryScreen, paymentScreen])
    } else {
      setStepNames(["Test options", "Your details", "Delivery", "Payment"])
      setStepNumbers([testOptionsScreen, clientDetailsScreen, deliveryScreen, paymentScreen])
    }
  }, [])

  return ( 
    
    <div id={styles.CheckoutProgressBar} className="d-flex justify-content-between">

      {stepNames.map((stepName, i) => {
        let currentStep = props.step
        const step = i + 1

        if(currentStep == 6) {
          currentStep = 1
        } 

        return (
          <div onClick={() => { returnToStep(stepNumbers[i]) }} className={`${styles.step} ${currentStep == step ? styles.active : ''} ${currentStep > step ? styles.complete : '' } ${step >= currentStep ? styles.disabled : ''}`}>
            <div className={styles.stepCircle}>
              <div className={styles.stepsBorder}>{currentStep > step ? <FontAwesomeIcon icon={faCheck}/> : step}</div>
            </div>
            <div className={styles.stepTitle}>{stepName}</div> 
          </div>  
        )
      })}

      <div className={styles.stepBreak}><hr/></div>
    </div>
  )

}