import merge from "lodash/merge";
import { combineReducers } from "redux";

import * as ActionTypes from "../actions";
import * as AuthenticationActionTypes from "../actions/authentication-actions";
import * as UsersActionTypes from "../actions/users-actions";
import * as BasketActionTypes from "../actions/basket-actions";
import * as PhlebotomyActionType from "../actions/phlebotomy-actions";
import * as KitActionType from "../actions/kit-actions";
import * as VouchersActionType from "../actions/vouchers-actions";


// Updates an entity cache in response to any action with response.entities.

const entities = (state = {healthCategories: {}, allSubscriptionTests: {}, stripeSubscriptions: {}, partnerSessions: {}, partnerSignUp: {}, partnerShop: {}, partnerTags: {}, partnerResults: {}, partnerUsersArray: {}, partnerAllResults: {}, questionsList: {}, subscriptionAmend: {}, partnerNotifications: {}, partnerCustomers: {}, partnerOrders: {}, partnerUsers: {}, areaOfBusiness: {}, cardSubscriptions: {}, orderDetails: {}, partnerTests: {}, getContraceptionSelected: {}, getContraceptionTypes: {}, getStartedQs: {}, pdfs: {}, ratings: {}, hormoneOrder: {}, femaleHormoneResults: {}, partnerClinics: {}, basketTestItems: {}, plusTests: {}, parentBespokeTest: {}, cart: {}, allTests: {}, partner: {}, markerAverage: {}, blog: {}, allLatestBiomarkers:{}, markerHistory: {}, categoryTestHistory: {}, orderBiomarkers: {}, subscriptions: {}, completedOrders: {}, currentOrders: {}, categoryTests: {}, categoryTest: {}, currentHistoryMarker: {}, currentMarker: {}, singleTest: {}, hpComment: {}, tests: {}, completeIncompleteMarker: {}, outOfRangeResults: {}, improvedResults: {}, lastOrder: {}, result: {}, order: {}, medical: {}, users: {} }, action) => {
  if (action.type == 'LOGOUT' || action.type == 'CLEAR_GLOBAL_STATE') { 
    return state = {healthCategories: {}, allSubscriptionTests: {}, stripeSubscriptions: {}, partnerSessions: {}, partnerSignUp: {}, partnerShop: {}, partnerTags: {}, partnerResults: {}, partnerUsersArray: {}, partnerAllResults: {}, questionsList: {}, subscriptionAmend: {}, partnerNotifications: {}, partnerCustomers: {}, partnerOrders: {}, partnerUsers: {}, areaOfBusiness: {}, cardSubscriptions: {}, orderDetails: {}, partnerTests: {}, getContraceptionSelected: {}, getContraceptionTypes: {}, getStartedQs: {}, pdfs: {}, ratings: {}, hormoneOrder: {}, femaleHormoneResults: {}, partnerClinics: {}, basketTestItems: {}, plusTests: {}, parentBespokeTest: {}, cart: {}, allTests: {}, partner: {}, markerAverage: {}, blog: {}, allLatestBiomarkers:{}, markerHistory: {}, categoryTestHistory: {}, orderBiomarkers: {}, subscriptions: {}, completedOrders: {}, currentOrders: {}, categoryTests: {}, categoryTest: {}, currentHistoryMarker: {}, currentMarker: {}, singleTest: {}, hpComment: {}, tests: {}, completeIncompleteMarker: {}, outOfRangeResults: {}, improvedResults: {}, lastOrder: {}, result: {}, order: {}, medical: {}, users: {} };

  } else if (action.response && action.response.entities && action.type != 'LOGOUT') { 
    return merge({}, state, action.response.entities)
  }

  return state;
};

const token = localStorage.getItem("token");
const authenticationInitialState = token
  ? { isAuthenticated: true, isAuthenticating: false, token: token }
  : { isAuthenticated: false, isAuthenticating: false, token: "" };

// Updates the authenticated state in response to any token or validate request
const authentication = (state = authenticationInitialState, action) => {
  const { type } = action;
  // Get current token from localStorage
  const currentToken =
    localStorage && localStorage.getItem("token")
      ? localStorage.getItem("token")
      : false;
  // If Offline and we have a token set alreadyAuthenticated to true
  const alreadyAuthenticated = currentToken ? true : false;
  switch (type) {
    case AuthenticationActionTypes.TOKEN_REQUEST:
    case AuthenticationActionTypes.VALIDATE_REQUEST:
      return merge({}, state, {
        isAuthenticated: alreadyAuthenticated,
        isAuthenticating: true,
      });
    case AuthenticationActionTypes.TOKEN_SUCCESS:
      localStorage.setItem("token", action.response.token);
      return merge({}, state, {
        isAuthenticated: true,
        isAuthenticating: false,
      });
    case AuthenticationActionTypes.VALIDATE_SUCCESS:
      return merge({}, state, {
        isAuthenticated: true,
        isAuthenticating: false,
      });
    case AuthenticationActionTypes.TOKEN_FAILURE:
    case AuthenticationActionTypes.VALIDATE_FAILURE:
      if (!alreadyAuthenticated || navigator.onLine) {
        localStorage.removeItem("token");
        return merge({}, state, {
          isAuthenticated: false,
          isAuthenticating: false,
          token: "",
        });
      }
      return merge({}, state, {
        isAuthenticated: alreadyAuthenticated || !navigator.onLine,
        isAuthenticating: false,
      });
    case AuthenticationActionTypes.LOGOUT:
      var appWindow = window;

      let tmp = appWindow.postMessage(
        JSON.stringify({ type: "logout", response: "" }),
        "*"
      );

      localStorage.removeItem("token");
      return merge({}, state, {
        isAuthenticated: false,
        isAuthenticating: false,
        token: "",
      });
    default:
  }
  return state;
};

 

// Updates current user information in response to token and user requests
const user = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case AuthenticationActionTypes.TOKEN_SUCCESS:
      return merge({}, state, action.response);
    case AuthenticationActionTypes.LOGOUT:
      return {};
    case UsersActionTypes.USER_SUCCESS:
      if (action.userId === "me") {
        return merge({}, state, action.response);
      }
      break;
    case UsersActionTypes.UPDATE_USER_SUCCESS:
      return merge({}, state, action.response);
    default:
  }
  return state;
};


// Updates current user information in response to token and user requests
const phlebotomyPrices = (state = {}, action) => {
  const { type } = action;
  switch (type) { 
    case PhlebotomyActionType.PHLEBOTOMY_PRICE_SUCCESS: 
    case PhlebotomyActionType.SET_PHLEBOTOMY_PRICES_SUCCESS:
      let response = action.response;
      let orderedList = {}
      Object.keys(response).map(x => {
          orderedList[response[x].phlebotomyId] = response[x]
      }) 
      return merge({}, state, orderedList);
    default:
  }
  return state;
};


// Updates current user information in response to token and user requests
const kitPrices = (state = {}, action) => {
  const { type } = action;
  switch (type) { 
    case KitActionType.KIT_PRICE_SUCCESS: 
    case KitActionType.SET_KIT_PRICES_SUCCESS:
      let response = action.response;
      let orderedList = {}
      Object.keys(response).map(x => {
          orderedList[response[x].kitId] = response[x]
      }) 
      return merge({}, state, orderedList);
    default:
  }
  return state;
};

// Updates current user information in response to token and user requests
const referCodeDetails = (state = {}, action) => {
  const { type } = action;
  switch (type) { 
    case VouchersActionType.LOAD_REFER_CODE_DETAILS_SUCCESS: 
    case VouchersActionType.SET_REFER_CODE_DETAILS_SUCCESS:
      const referralCodeDetails = action.response;

      return merge({}, state, referralCodeDetails);
    default:
  }
  return state;
};

const deliveryOptions = (state = {}, action) => {
  const { type } = action;
  switch (type) { 
    case BasketActionTypes.SET_DELIVERY_OPTIONS_SUCCESS: 
    case BasketActionTypes.GET_DELIVERY_OPTIONS_SUCCESS:
      const deliveryOptions = action.response;

      return merge({}, state, deliveryOptions);
    default:
  }
  return state;
};

var basketItems = localStorage.getItem("basket");

if (basketItems == "" || !basketItems) {
  basketItems = {};
} else {
  basketItems = JSON.parse(localStorage.getItem("basket"));
}

const basket = (state = basketItems, action) => {
  const { type } = action;

  switch (type) {
    case BasketActionTypes.ADD_TO_BASKET:
      return merge({}, state, action.id);
    case BasketActionTypes.REMOVE_FROM_BASKET:
      delete state[action.id];
      return state;
    case BasketActionTypes.REMOVE_ALL_FROM_BASKET:
      state = {};
      return state;
    default:
  }
  return state;
};

// Updates error message to notify about the failed fetches.
const errorMessage = (state = null, action) => {
  const { type, error } = action;

  if (type === ActionTypes.RESET_ERROR_MESSAGE) {
    return null;
  } else if (error) {
    return error;
  }

  return state;
};

const rootReducer = combineReducers({
  entities,
  authentication,
  user,
  phlebotomyPrices,
  kitPrices,
  basket,
  errorMessage,
  referCodeDetails,
  deliveryOptions
});

export default rootReducer;
