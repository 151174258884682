import { CALL_API, Schemas } from '../middleware/api'

export const UPDATE_PAYMENT_INTENT_REQUEST = 'UPDATE_PAYMENT_INTENT_REQUEST'
export const UPDATE_PAYMENT_INTENT_SUCCESS = 'UPDATE_PAYMENT_INTENT_SUCCESS'
export const UPDATE_PAYMENT_INTENT_FAILURE = 'UPDATE_PAYMENT_INTENT_FAILURE'

const fetchPaymentIntent= (body) => ({
  [CALL_API]: {
    types: [ UPDATE_PAYMENT_INTENT_REQUEST, UPDATE_PAYMENT_INTENT_SUCCESS, UPDATE_PAYMENT_INTENT_FAILURE ],
    endpoint: 'secure/partner/blank-kit/payment-intent',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updatePaymentIntent = (body) => (dispatch, getState) => {
  return dispatch(fetchPaymentIntent(body))
};


export const UPDATE_PROCESS_ORDER_REQUEST = 'UPDATE_PROCESS_ORDER_REQUEST'
export const UPDATE_PROCESS_ORDER_SUCCESS = 'UPDATE_PROCESS_ORDER_SUCCESS'
export const UPDATE_PROCESS_ORDER_FAILURE = 'UPDATE_PROCESS_ORDER_FAILURE'

const fetchProcessIntent= (body) => ({
  [CALL_API]: {
    types: [ UPDATE_PROCESS_ORDER_REQUEST, UPDATE_PROCESS_ORDER_SUCCESS, UPDATE_PROCESS_ORDER_FAILURE ],
    endpoint: 'secure/partner/blank-kit/process-intent',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateProcessIntent = (body) => (dispatch, getState) => {
  return dispatch(fetchProcessIntent(body))
};

export const PROCESS_INVOICE_REQUEST = 'PROCESS_INVOICE_REQUEST'
export const PROCESS_INVOICE_SUCCESS = 'PROCESS_INVOICE_SUCCESS'
export const PROCESS_INVOICE_FAILURE = 'PROCESS_INVOICE_FAILURE'

const fetchProcessInvoice = (payload) => ({
  payload,
  [CALL_API]: {
    endpoint: `secure/partner/blank-kit/process-invoice`,
    method: 'POST',
    types: [ PROCESS_INVOICE_REQUEST, PROCESS_INVOICE_SUCCESS, PROCESS_INVOICE_FAILURE ],
    body: payload,
  }
})

export const processInvoice = (payload) => (dispatch, getState) => {
  return dispatch(fetchProcessInvoice(payload))
};