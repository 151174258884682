import React, { Component }from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom';

import { loadValidate, logout } from '../actions/authentication-actions'


class RestrictedRoute extends Component {
  static propTypes = {
    component: PropTypes.func,
    restricted: PropTypes.bool.isRequired
  }
    
  constructor(props) {
    super(props);

    this.state = {
      redirectToLogin: false,
      isRestricted: this.props.isRestricted
    };
  }

  componentWillMount() {
    const token = localStorage && localStorage.getItem('token') ? localStorage.getItem('token') : false;
    const {isAuthenticated} = this.props.authentication;
    if (!token && !isAuthenticated) {
      this.setState({
        redirectToLogin: true,
        isRestricted: true
      })
    }
  }

  componentDidMount() {
    this.taskRunner();
  }

  componentWillReceiveProps (nextProps) {
    if(this.props.path !== nextProps.path || this.props.location.search !== nextProps.location.search){
      this.props.loadValidate();
    }
  }

  componentDidUpdate(){
    const token = localStorage && localStorage.getItem('token') ? localStorage.getItem('token') : false;
    const authenticated = this.props.authentication.isAuthenticated;
    if (!token && !authenticated) {
      this.props.logout()
      this.setState({
        redirectToLogin: true,
        isRestricted: true
      })
    }
  }

  taskRunner() {
    setInterval((function(page){
      return function(){
        
        var triggerLogout = localStorage.getItem('triggerLogout'); 
        if (triggerLogout == 'true') {

          this.setState({redirectToLogin: true});

        }
      };
    })(this),800);
  }


  render () {
    const { component: Component, children, ...rest } = this.props
    const { isRestricted } = this.state
    let redirectTo = this.props.redirectTo ? this.props.redirectTo : "/partnerRedirect"
    // isRestricted = false meaning has access
    // isRestricted = true meaning has no access

    return (
      <Route {...rest} render={props => (
          (this.props.authentication.isAuthenticated === true || this.state.redirectToLogin === true) && isRestricted 
          ? <Redirect to={redirectTo} />
          : Component 
            ? <Component {...props}/> 
            : children 
      )} />
    )

  }
};

const mapStateToProps = (state, ownProps) => {
    const {
      authentication
    } = state
  
    return {
      authentication
    }
  }
  
  export default withRouter(connect(mapStateToProps, {
    loadValidate,
    logout,
  })(RestrictedRoute))