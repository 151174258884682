import {Modal} from "bootstrap";

export const closeBootstrapModal = modalId => {
  if (!modalId) return;

  const myModal = Modal.getInstance(document.getElementById(`${modalId}`));
  myModal?.hide();

  document.querySelectorAll(".modal-backdrop").forEach(el => el.remove());
};
