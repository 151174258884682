import cryptoJs from "crypto-js";
import padZeroPadding from "crypto-js/pad-zeropadding";
import Utf8 from "crypto-js/enc-utf8";
import config from '../config';

const key = cryptoJs.enc.Hex.parse(config.E2EE_KEY);
const iv = cryptoJs.enc.Hex.parse(config.E2EE_IV);

export const aesEncrypt = (text, k = key, i = iv) => {
  if(typeof text !== 'string') return "";

  try {
    return cryptoJs.AES.encrypt(text, k, {
      iv: i,
      padding: padZeroPadding,
    }).toString();
  } catch (e) {
    return "";
  }
}

export const aesDecrypt = (text, k = key, i = iv) => {
  if(typeof text !== 'string') return "";

  try {
    return cryptoJs.AES.decrypt(text, k, {
      iv: i,
      padding: padZeroPadding,
    }).toString(Utf8);
  } catch (e) {
    return "";
  }
}