import { useState, useEffect } from "react"

export const useCheckElementVisibility = (elementId) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if(elementId) {
      window.addEventListener("scroll", () => checkSkipBarIsOnViewport(elementId))
    } else {
      throw new Error("Element is undefined")
    }

    return () => window.removeEventListener("scroll", () => checkSkipBarIsOnViewport(elementId))
  },[elementId])

  const checkSkipBarIsOnViewport = (elementId) => {
    let el = document.getElementById(elementId)

    if (document.getElementById(elementId)) {
      let top = el.offsetTop;
      let left = el.offsetLeft;
      let width = el.offsetWidth;
      let height = el.offsetHeight;
    
      while(el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
      }
    
      const isElementOnViewport = 
        top < (window.pageYOffset + window.innerHeight) &&
        left < (window.pageXOffset + window.innerWidth) &&
        (top + height) > window.pageYOffset &&
        (left + width) > window.pageXOffset
      ;

      setTimeout(() => {
        setIsVisible(isElementOnViewport);
      }, 300)
      }
  }

  return [isVisible]
}