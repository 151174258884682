export default function getLogo(websiteId, partner = {}) {
  let partnerPortalId = localStorage.getItem('partnerPortalId');
  if (partnerPortalId == 'undefined' || !partnerPortalId) {
    partnerPortalId = 0;
  } 

  let logo;

  if (partnerPortalId && Object.keys(partner).length > 0) {
    if (partner[0].partnerLogo.includes("https://sys.forthwithlife.co.uk")) {
      logo = partner[0].partnerLogo; 
    } else if (partner[0].partnerLogo.includes("/public/uploads/partnerLogos/") && !partner[0].partnerLogo.includes("https://api.forthwithlife.co.uk")) {
      logo = 'https://api.forthwithlife.co.uk'+partner[0].partnerLogo; 
    } else if (partner[0].partnerLogo.includes("https://api.forthwithlife.co.uk")) {
      logo = partner[0].partnerLogo; 
    } else {
      logo = 'https://sys.forthwithlife.co.uk'+partner[0].partnerLogo; 
    }
  } else {
    
    switch (websiteId) {
      case 1:
        logo = '/img/forthBlack.png';
        break; 
      case 2:
        logo = '/img/edgeWhiteLogo.svg';
        break; 
      case 3:
        if (partnerPortalId && Object.keys(partner).length > 0) {
          if (partner[0].partnerLogo.includes("https://sys.forthwithlife.co.uk")) {
            logo = partner[0].partnerLogo; 
          } else if (partner[0].partnerLogo.includes("/public/uploads/partnerLogos/") && !partner[0].partnerLogo.includes("https://api.forthwithlife.co.uk")) {
            logo = 'https://api.forthwithlife.co.uk'+partner[0].partnerLogo; 
          } else if (partner[0].partnerLogo.includes("https://api.forthwithlife.co.uk")) {
            logo = partner[0].partnerLogo; 
          } else {
            logo = 'https://sys.forthwithlife.co.uk'+partner[0].partnerLogo; 
          }
        } 
        break;
      default:
        logo = '/img/forthBlack.png';
    }
  }

  return logo; 
   
}
