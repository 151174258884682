import React, { useState } from 'react'
import RenderIf from '../../../RenderIf/RenderIf';

import styles from './InputPassword.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';

const InputPasswordRef = React.forwardRef(({label, labelClassName, name, id, className, isLoading, required, showPassword, ...rest}, ref) => {
  const [mustShowPassword, setMustShowPassword] = useState(false)
  return (
    <>
      <RenderIf isTrue={label}>
        <label htmlFor={id} className={`${labelClassName}`}>{label}</label>
      </RenderIf>

      <div className={`${styles.forthInput} input-group`}>
        <input {...rest} type={mustShowPassword ? "text" : "password"} name={name} id={id} ref={ref} className={`form-control ${className}`} required={required} />
        <RenderIf isTrue={showPassword}>
          <RenderIf isTrue={mustShowPassword}>
            <span className={`input-group-text ${styles.bgPrimary}`} id={id} onClick={() => setMustShowPassword(!mustShowPassword)}>
              <FontAwesomeIcon className='fa-fw' icon={faEye} />
            </span>
          </RenderIf>
          <RenderIf isTrue={!mustShowPassword}>
            <span className={`input-group-text ${styles.bgPrimary}`} id={id} onClick={() => setMustShowPassword(!mustShowPassword)}>
              <FontAwesomeIcon className='fa-fw' icon={faEyeSlash} />
            </span>
          </RenderIf>
        </RenderIf>
      </div>
    </>
  );
})

export default InputPasswordRef