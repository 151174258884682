import { CALL_API } from '../middleware/api';

/**
 * Token
 */
export const TOKEN_REQUEST = 'TOKEN_REQUEST';
export const TOKEN_SUCCESS = 'TOKEN_SUCCESS';
export const TOKEN_FAILURE = 'TOKEN_FAILURE';

const fetchToken = (username, password, ip, siteId, customerPlatform, partner) => ({
  username,
  password,
  ip,
  siteId,
  customerPlatform,
  [CALL_API]: {
    types: [TOKEN_REQUEST, TOKEN_SUCCESS, TOKEN_FAILURE],
    endpoint: 'login',
    method: 'POST',
    body: {
      username,
      password,
      ip,
      siteId,
      customerPlatform,
      partner
    },
  },
});

export const loadToken = (username, password, ip, siteId, customerPlatform, partner) => (dispatch) => {
  return dispatch(fetchToken(username, password, ip, siteId, customerPlatform, partner));
};

/**
 * Login
 */

export const VALIDATE_REQUEST = 'VALIDATE_REQUEST';
export const VALIDATE_SUCCESS = 'VALIDATE_SUCCESS';
export const VALIDATE_FAILURE = 'VALIDATE_FAILURE';

const fetchValidate = () => ({
  [CALL_API]: {
    types: [VALIDATE_REQUEST, VALIDATE_SUCCESS, VALIDATE_FAILURE],
    endpoint: 'secure/validate',
    method: 'GET',
  },
});

export const loadValidate = () => dispatch => dispatch(fetchValidate());

/**
 * Logout
 */

export const LOGOUT = 'LOGOUT';

export const logout = () => dispatch => dispatch({
  type: LOGOUT,
});

 




export const PASSWORD_CHECK_REQUEST = 'PASSWORD_CHECK_REQUEST';
export const PASSWORD_CHECK_SUCCESS = 'PASSWORD_CHECK_SUCCESS';
export const PASSWORD_CHECK_FAILURE = 'PASSWORD_CHECK_FAILURE';

const fetchPasswordCheck = (userId, password) => ({
  userId, 
  password,
  [CALL_API]: {
    types: [PASSWORD_CHECK_REQUEST, PASSWORD_CHECK_SUCCESS, PASSWORD_CHECK_FAILURE],
    endpoint: 'check-password',
    method: 'POST',
    body: {
      userId,
      password,
    },
  },
});

export const loadPasswordCheck = (userId, password) => (dispatch) => {
  return dispatch(fetchPasswordCheck(userId, password));
};



export const PASSWORD_UPDATE_REQUEST = 'PASSWORD_UPDATE_REQUEST';
export const PASSWORD_UPDATE_SUCCESS = 'PASSWORD_UPDATE_SUCCESS';
export const PASSWORD_UPDATE_FAILURE = 'PASSWORD_UPDATE_FAILURE';

const fetchPasswordUpdate = (currentPassword, newPassword2) => ({
  currentPassword, 
  newPassword2,
  [CALL_API]: {
    types: [PASSWORD_UPDATE_REQUEST, PASSWORD_UPDATE_SUCCESS, PASSWORD_UPDATE_FAILURE],
    endpoint: 'secure/update-password',
    method: 'POST',
    body: {
      currentPassword,
      newPassword2,
    },
  },
});

export const loadPasswordUpdate = (currentPassword, newPassword2) => (dispatch) => {
  return dispatch(fetchPasswordUpdate(currentPassword, newPassword2));
};



export const ACCOUNT_REQUEST = 'ACCOUNT_REQUEST';
export const ACCOUNT_SUCCESS = 'ACCOUNT_SUCCESS';
export const ACCOUNT_FAILURE = 'ACCOUNT_FAILURE';

const fetchCreateAccount = (body) => ({
  [CALL_API]: {
    types: [ACCOUNT_REQUEST, ACCOUNT_SUCCESS, ACCOUNT_FAILURE],
    endpoint: '/create-account',
    schema: null,
    method: 'POST',
    body: body,
  },
});

export const loadCreateAccount = (body) => (dispatch) => {
  return dispatch(fetchCreateAccount(body));
};



export const TOKEN_CHECK_REQUEST = 'TOKEN_CHECK_REQUEST'
export const TOKEN_CHECK_SUCCESS = 'TOKEN_CHECK_SUCCESS'
export const TOKEN_CHECK_FAILURE = 'TOKEN_CHECK_FAILURE'

const fetchResetToken = (token) => ({
  token,
  [CALL_API]: {
    types: [ TOKEN_CHECK_REQUEST, TOKEN_CHECK_SUCCESS, TOKEN_CHECK_FAILURE ],
    endpoint: `check-reset-token/${token}`,
    schema: null
  }
})

export const checkResetToken = (token) => (dispatch, getState) => {
  return dispatch(fetchResetToken(token))
};



const fetchUpdatePassword = (password, customerId, token) => ({
  password,
  customerId,
  token,
  [CALL_API]: {
    types: [PASSWORD_UPDATE_REQUEST, PASSWORD_UPDATE_SUCCESS, PASSWORD_UPDATE_FAILURE],
    endpoint: '/update-password',
    method: 'POST',
    body: {
      password,
      customerId,
      token
    },
  },
});

export const loadUpdatePassword = (password, customerId, token) => (dispatch) => {
  return dispatch(fetchUpdatePassword(password, customerId, token));
};

export const CHECK_EMAIL_EXIST_REQUEST = 'CHECK_EMAIL_EXIST_REQUEST';
export const CHECK_EMAIL_EXIST_SUCCESS = 'CHECK_EMAIL_EXIST_SUCCESS';
export const CHECK_EMAIL_EXIST_FAILURE = 'CHECK_EMAIL_EXIST_FAILURE';

const fetchCheckEmailExist = (body) => ({
  [CALL_API]: {
    types: [CHECK_EMAIL_EXIST_REQUEST, CHECK_EMAIL_EXIST_SUCCESS, CHECK_EMAIL_EXIST_FAILURE],
    endpoint: '/check-email',
    schema: null,
    method: 'POST',
    body: body,
  },
});

export const checkEmailExist = (body) => (dispatch) => {
  return dispatch(fetchCheckEmailExist(body));
};