import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from "./YourOrderWrapper.module.scss"; 
import Button from '../../GlobalComponents/Button';
import { faShoppingCart } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';


export default function YourOrderWrapper(props) {
  const { children, subtotal, totalAmount, partner, checkoutFromPartner } = props
  const [arrowToggle, setArrowToggle] = useState(false);

  const handleCloseBasket = () => {
    let url;
    let redirectUrl;
    let reload; 
    let referrer = localStorage.getItem('referrer');
    if (referrer) {
      referrer = referrer.replace(/^"(.*)"$/, '$1');
    }

    if(window != window.parent){
      if(window.parent) {
        window.parent.postMessage('closeIframe', '*');
      }
    }

    if(window == window.parent){
      if(checkoutFromPartner == 1){
        if(window.parent) {
          window.parent.postMessage('closeIframe', '*');
        }
        window.location.href = `https://forthpartners.co.uk`
      }

      if (referrer) { 
        url = referrer.split('/')[2]; 
        if (url == 'www.forthwithlife.co.uk' || url == 'www.forthedge.co.uk' || url == 'shop.forthwithlife.co.uk' || url == 'shop.forthedge.co.uk' || url == 'shop.sportsbloodtests.co.uk' || url == 'www.sportsbloodtests.co.uk' || url.includes("biomarkertracking.com")) {
          if (url == 'shop.forthwithlife.co.uk') {
            redirectUrl = 'https://www.forthwithlife.co.uk';
            props.step == 1 && localStorage.setItem('partnerPortalId', 0);
          } else if (url == 'shop.forthedge.co.uk' || url == 'shop.sportsbloodtests.co.uk') {
            redirectUrl = 'https://www.sportsbloodtests.co.uk';
            props.step == 1 && localStorage.setItem('partnerPortalId', 0);
          } else if (url.includes("biomarkertracking.com") && Object.keys(partner).length > 0) {
            redirectUrl = 'https://biomarkertracking.com/'+partner[0].requestCode;
          } else { 
            redirectUrl = referrer;
          } 
        } else { 
          if (props.step == 1  || props.step == 3) {
            reload = true;
          } else {
            redirectUrl = '/'
          } 
          if (props.step == 4 && !props.paymentSuccess) {
            reload = true;
          }
        }
      } else {
        if (props.step == 1 || props.step == 3) {
          reload = true;
        } else {
          if (window.location.hostname == 'shop.forthwithlife.co.uk') {
            redirectUrl = 'https://forthwithlife.co.uk';
          } else if (window.location.hostname == 'shop.forthedge.co.uk' || window.location.hostname == 'shop.sportsbloodtests.co.uk') {
            redirectUrl = 'https://sportsbloodtests.co.uk';
          } else if (window.location.hostname.includes("biomarkertracking.com") && Object.keys(partner).length > 0){
            redirectUrl = 'https://biomarkertracking.com/'+partner[0].requestCode;
          } else {
            redirectUrl = '/'
          }
        } 

      }
      
      if(reload){
        return window.location.reload();
      }

      return window.location.href = redirectUrl
    }
  }

  return(
    <div className={styles.yourOrderWrapper}>
      <h2 className="d-none d-md-block text-center mb-5">Your order</h2>
      <div className="px-3 py-3 d-block d-md-none" data-bs-toggle="collapse" data-bs-target="#yourOrder" aria-expanded="false" aria-controls="yourOrder" onClick={() => setArrowToggle(!arrowToggle)}>
        <div className="d-flex justify-content-between">
          <div className={styles.mobileSummaryLeft}>
            <FontAwesomeIcon icon={faShoppingCart} className="me-2"/> Your order summary
          </div>
          {subtotal && (
          <div className={styles.mobileSummaryRight}>
            &pound;{parseFloat(totalAmount).toFixed(2)} <FontAwesomeIcon icon={arrowToggle ? faAngleUp : faAngleDown} className='ms-2' />
          </div>
          )}
        </div> 
      </div> 
      <div className={`p-3 p-md-0 collapse ${styles.dontCollapseMd}`} id="yourOrder">
        {children} 
        <div className="row d-block d-md-none justify-content-center align-item-center">
          <div className="col-12 text-center">
            <Button variant="link" onClick={() => window.location.reload()}>Return to basket</Button>
          </div>
        </div>
      </div> 
    </div>  
  )
}

YourOrderWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element), 
    PropTypes.element.isRequired
  ]),
  subtotal: PropTypes.string.isRequired,
}