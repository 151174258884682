import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class Redirect extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    let path
    if(this.props.variant == "bespoke-builder"){
      if(this.props.parentTestId){
        path = `${this.props.to}/bespoke-test/${this.props.parentTestId}/1`
      } else {
        path = `${this.props.to}/bespoke-test/0/1`
      }

      if(this.props.preData){
        path = `${path}/${this.props.preData}`
      }
    }

    if(this.props.variant == "edit-test"){
      path = `${this.props.to}/edit-test/${this.props.editTestId}/1/`
    }

    if (this.props.editTestId == 0) {
      path = `${this.props.to}/bespoke-test/0/1`
    }
 
  
    if(path) {
      window.location.replace(path)
    }
  }

  render() {

    return (
      <></>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  
  let parentTestId = 0;
  let preData = '';
  let editTestId = '';
  if (ownProps && ownProps.match != null) {
    parentTestId = ownProps?.match.params.parentTestId;
    preData = ownProps?.match.params.preData;
    editTestId = ownProps?.match.params.editTestId;
  }

  const {} = state
 

  return {
    parentTestId,
    preData,
    editTestId
  }
}

export default withRouter(
  connect(mapStateToProps, {})(Redirect)
);