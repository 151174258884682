import React, { memo, useState } from 'react'
import isEmpty from 'lodash/isEmpty';

import { Alert, Button } from '../../';
import { InputText, InputSelect } from '../FormFields';
import RenderIf from '../../RenderIf/RenderIf';
import styles from './PostCodeLookUp.module.scss'

const INITIAL_ERROR_VALUE = {variant: "", message: ""}

export default memo(function PostCodeLookUp(props) {
  const [postcode, setPostcode] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [addressOptions, setAddressOptions] = useState([])
  const [error, setError] = useState(INITIAL_ERROR_VALUE)
  const [addressSelected, setAddressSelected] = useState({})

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(INITIAL_ERROR_VALUE)
    if(!postcode) {
      setError({variant: "danger", message: "Please enter a valid postcode."})
      setTimeout(() => { setError(INITIAL_ERROR_VALUE) },5000)
      return 
    }

    const cleanPostcode = postcode.replaceAll(/\s/g, '')

    if(cleanPostcode.length > 0){
      setIsLoading(true)
      fetch(`https://ws.postcoder.com/pcw/${global.config.postcoderApiKey}/address/UK/${cleanPostcode}?format=json&lines=2&postcodeonly=true`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          setError({variant: "danger", message: "Unable to find address, please enter your address manually"})
        }
      }).then(data => {  
        if(data.length > 0){
          setAddressOptions(data);
        }else{
          setError({variant: "danger", message: "Please, check your postcode and try again"})
        }
      }).finally(() => setIsLoading(false))
    }else{
      setError({variant: "danger", message: "Postcode invalid."})
    }

  }

 
  const handleSelectAddress = ({target}) => {
    const addressSelected = addressOptions?.filter( address => address.summaryline == target.value)[0] || {}
    setAddressSelected(target.value)

    if(!isEmpty(addressSelected)) {
      addressSelected.country = "United Kingdom"
    }

    props.handleReturnInitialAddress && props.handleReturnInitialAddress(addressSelected)
  }


  return (
    <form onSubmit={handleSubmit} className={`${styles.container} ${styles.box}`}>
      <InputText 
        label="Postcode look up" 
        labelClassName="fw-semibold" 
        className="form-control" 
        value={postcode} 
        onChange={(e) => setPostcode(e.target.value)} 
        placeholder="Enter postcode" 
        isLoading={isLoading}
      />
      <RenderIf isTrue={addressOptions?.length > 0}>
        <InputSelect
          className="form-control mt-3" 
          value={addressSelected} 
          onChange={(e) => handleSelectAddress(e)} 
          placeholder="Select address" 
          options={addressOptions}
          optionValue="summaryline"
        />
      </RenderIf>

      <Alert variant={error.variant} className="mt-2">{error.message}</Alert>
      <Button type="submit" variant="default-outline" className="w-100 mt-3">Find my address</Button>
    </form>
  );
})