import React, { useEffect } from 'react'
import RenderIf from '../../RenderIf/RenderIf';
import styles from './GrantPartnerPermission.module.scss'

export default function GrantPartnerPermission(props) {
  const { partner, customerDetails, toggleGrantAccess } = props
  
  return (
    <label htmlFor="grantPermissionToPartner">
      <div className={`form-group col-12 ${styles.container}`}>
        <RenderIf isTrue={partner[0]?.bespokePermissionText == 0 || !partner[0]?.bespokePermissionText}>
          <p className="fw-semibold"> Your test is being purchased through {partner[0]?.partnerName}, by proceeding with this purchase, you agree to share your results with them.</p>

          <p>{partner[0]?.partnerName} will have access to the following information related to you:</p>
          <ul>
            <li>Name and contact details</li>
            <li>Partial view of your delivery address</li>
            <li>Gender</li>
            <li>DOB</li>
            <li>Any health & lifestyle details you supplied</li>
            <li>Menstrual cycle <span className="fst-italic">(if applicable)</span></li>
          </ul>

          <p>Any results you receive from Forth will automatically be shared with {partner[0]?.partnerName}. You can manage this at any time using your dashboard.</p>
 
 
        </RenderIf>

        <RenderIf isTrue={partner[0]?.bespokePermissionText != 0 || partner[0]?.bespokePermissionText}>
          <div dangerouslySetInnerHTML={{__html: partner[0]?.bespokePermissionTextContent}}></div>
        </RenderIf>

        <div className="d-none mt-4 text-center row align-items-center justify-content-center">
          <input 
            checked={1} 
            onChange={toggleGrantAccess} 
            name="grantPermissionToPartner" 
            type="checkbox" 
            id="grantPermissionToPartner" 
            value={1} 
            className={`col-auto`}
          />
          <p className={`col-auto mb-0`}>Tick here to confirm you grant permission</p>
        </div>
      </div>
    </label>
  );
}

