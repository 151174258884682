import _ from 'lodash';
import React, {useState, useEffect} from 'react'  
import styles from "./WhyAddTheseMarkers.module.scss"; 

export default function WhyAddTheseMarkers(props) {  
  return (
    <>

    <div className="row">
      <div className="col-12">
        <h2 className="mb-4">Why add these biomarkers?</h2>
      </div>
      <div className={"row "+ styles.image}>
        <div className="col-12">
          {global.config.websiteId == 1 
            ? <img src="img/Dr-Thom-lrg.png" />
            : <img src="img/FE-Tom-Glow.png" />
          } 
          <h3><b>Dr Thom Phillips</b></h3>
          {/* <p>BA, MA (Cantab), MB, BChir, MRCP.</p> */}
        </div>
      </div>
      <div className="col-12">
        <div className={styles.markersIncluded}>
          <div className="mb-3 text-start">
            <div className="fw-light" dangerouslySetInnerHTML={{__html: props.test.relevantMarkersReason}}></div>
          </div> 
        </div>
      </div>
    </div> 
    </>
  ) 
}