import { CALL_API, Schemas } from '../middleware/api'
 

export const PHLEBOTOMY_PRICE_REQUEST = 'PHLEBOTOMY_PRICE_REQUEST'
export const PHLEBOTOMY_PRICE_SUCCESS = 'PHLEBOTOMY_PRICE_SUCCESS'
export const PHLEBOTOMY_PRICE_FAILURE = 'PHLEBOTOMY_PRICE_FAILURE'

const fetchPhlebotomyPrices = () => ({
  [CALL_API]: {
    types: [ PHLEBOTOMY_PRICE_REQUEST, PHLEBOTOMY_PRICE_SUCCESS, PHLEBOTOMY_PRICE_FAILURE ],
    endpoint: `phlebotomy/get-plebotomy-prices`, 
  }
})

export const loadPhlebotomyPrices = () => (dispatch, getState) => {
  const phlebotomyPrices = getState().entities.phlebotomyPrices;
  if (phlebotomyPrices) {
    return null
  }

  return dispatch(fetchPhlebotomyPrices())
}

export const SET_PHLEBOTOMY_PRICES_REQUEST = "SET_PHLEBOTOMY_PRICES_REQUEST"
export const SET_PHLEBOTOMY_PRICES_SUCCESS = "SET_PHLEBOTOMY_PRICES_SUCCESS"
export const SET_PHLEBOTOMY_PRICES_FAILURE = "SET_PHLEBOTOMY_PRICES_FAILURE"

export const setPhlebotomyPrices = (phlebotomyPrices) => (dispatch, getState) => {
  dispatch({
    type: SET_PHLEBOTOMY_PRICES_REQUEST
  })

  try {
    dispatch({
      type: SET_PHLEBOTOMY_PRICES_SUCCESS,
      response: phlebotomyPrices
    })
  } catch (error) {
    dispatch({
      type: SET_PHLEBOTOMY_PRICES_FAILURE,
      error: error
    })
  }
}

 