/**
 * Data required.
 * @param {string} date - The date when the update is released. Date format, YYYY-MM-DD.
 * @param {string} appVersion - The app version when the update is released.
 * @param {string} title - The title of the update released.
 * @param {string} description - The description is optional and this will shown under title. (The description may contain html tags)
 * @param {string} img - The img is optional and this will shown under title or description if this exist. There is a folder on public/img/updatesImages for this.
 * @param {array} features - The features must be an array of objects and the object must contain a title and description (The description may include html tags).
 */

export const updatesList = [
  {
    date: "2022-10-27", 
    appVersion: '3.8',
    title: "First of many UI improvements coming your way", 
    description: "", 
    img: "/img/newUpdates/update1.png",
    features: [
      {title: "Improved search", description: "You can now search by full name, email address and postcode, as well as reference number and activation codes to help you find your clients."},
      {title: "New action menu", description: "All actions such as viewing a client, downloading a receipt and viewing results now available under one button."},
      {title: "Additional information available", description: "Information regarding your clients email address, postcode and order status is now available in the table."},
    ]
  },
  {
    date: "2022-10-26", 
    appVersion: '3.8',
    title: "Buy now. Pay over time with Klarna", 
    description: "During checkout, you and your clients can now spread the cost in 3 interest-free payments. The first payment is made at point of purchase, with the remaining 2 payments scheduled automatically every 30 days.", 
    img: "/img/newUpdates/update2.png",
    features: []
  },
  // {
  //   date: "2022-10-25", 
  //   appVersion: '3.8',
  //   title: "Understanding your clients and their results better", 
  //   description: "Your clients profile information is now next to their results, making it easier to understand you client and their results.", 
  //   img: "/img/newUpdates/update3.png",
  //   features: []
  // },
  {
    date: "2022-10-24", 
    appVersion: '3.8',
    title: "Clarity on failed markers", 
    description: "We heard your feedback and we have made it clearer for you to see if any markers failed analysis.", 
    img: "/img/newUpdates/update4.png",
    features: []
  },
  {
    date: "2024-05-23", 
    appVersion: '4.0.0',
    title: "Improved Ordering and Activation", 
    description: "", 
    img: "/img/newUpdates/update5.png",
    features: [
      {title: "", description: "Our Development team have been hard at work building improvements to ordering and activation of tests."},
      {title: "Ordering", description: "You can now order Activation Kits within your Portal using the Create a New Order button on the Dashboard. </br><br/> We have also made improvements to the ordering process so it is now faster and more efficient to place orders."},
      {title: "Activation", description: "It is now much easier to Activate a test kit and we have resolved issues a small number of partners were having, making activation easier and more reliable so you can focus on your clients."},
    ]
  }, 
  {
    date: "2024-11-01", 
    appVersion: '4.6.0',
    title: "HealthCoach™", 
    description: "Now, You can offer to your client the user-friendly results and actionable insights provided by HealthCoach™", 
    img: "/img/Baseline-Unlock-Health-Score.webp",
    features: [
      {title: "Actionable insights", description: "Easy-to-understand health scores, less jargon, and more insight into your client's health and potential improvements."},
      {title: "Personalised guidance", description: "Recommendations tailored to your client's unique profile including gender, age, menstrual cycle, and hormone medication."},
      {title: "Key areas of focus", description: "Pinpoints the crucial elements that will most significantly improve client's health scores."},
      {title: "Personalised goals", description: "Set achievable target scores specific to the results."},
    ]
  },
]