import React from 'react'
import RenderIf from '../../../RenderIf/RenderIf';
import styles from './InputSelect.module.scss'

export default function InputSelect({name, id, className, labelClassName, label, options, optionValue, placeholder, ...rest}) {
  return (
    <>
      <RenderIf isTrue={label}>
        <label htmlFor={id} className={`${labelClassName}`}>{label}</label>
      </RenderIf>

      <select name={name} id={id} {...rest} className={`${styles.select} ${className}`}>
        <RenderIf isTrue={placeholder}>
          <option value={""}>{placeholder}</option>
        </RenderIf>
        {options?.map( (opt, index) => (
          <option value={opt[`${optionValue}`]} key={index}>{opt[`${optionValue}`]}</option>
        ))}
      </select>
    </>
  );
}