import React, { useEffect, useState, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import {loadScoreRanges} from "../../../actions/basket-actions"

import {BasketItems, CartSummary, CheckoutProgressBar, YourOrderWrapper, Button, RenderIf} from '../../../components'
import calculateBasket from "../../../helpers/calculateBasket"
import Radio from './Radio/Radio'
import Slider from '../../Fields/Slider/Slider'
import { steps } from '../CheckoutProgressBar'

import styles from './CheckoutAdditionalQuestions.module.scss'
import _ from 'lodash'

function CheckoutAdditionalQuestions(props) {
  const {foundEndoTest, endoQuestions, setCustomerEndoDetails, customerEndoDetails, scoreByQuestion, setScoreByQuestion, foundTrueCheckTest, trueCheckQuestions, setCustomerTrueCheckDetails, customerTrueCheckDetails} = props

  const [subtotal, setSubtotal] = useState();
  const [basketTotal, setBasketTotal] = useState();
  const [forceGuestCheckout, setForceGuestCheckout] = useState(true);
  const [checkIfGuestSelected, setCheckIfGuestSelected] = useState(false);
  const [giftCardTotal, setGiftCardTotal] = useState(0);
  const [testPrice, setTestPrice] = useState();
  const [scoreRanges, setScoreRanges] = useState({})
  const [error, setError] = useState("");

  const totalScore = useMemo(() => {
    return Object.values(scoreByQuestion).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
  }, [scoreByQuestion])

  if(["localhost", "appdev.forthwithlife.co.uk"].includes(window.location.hostname) && foundEndoTest){
    console.log('totalScore: ', totalScore);
  }


  const isBtnDisabled = useMemo(() => {
    if(foundEndoTest){
      return Object.values(customerEndoDetails).some(value => value == "")
    }

    if(foundTrueCheckTest){
      return Object.values(customerTrueCheckDetails).some(value => value == false)
    }
  }, [customerEndoDetails, customerTrueCheckDetails])

  useEffect(() => { 
    getValues(); 
  },[props]);

  useEffect(() => {
    if(foundEndoTest){ 
      initialScore()
      getScoreRanges()
    }
  }, [])

  const getValues = () => {
    let cartProps = {
      "user": props.user,
      "cart": props.cart[0],
      "tests": props.basketTestItems,
      "basket": props.cart[0].basketArray,
      "phlebotomyOption": props.phlebotomyOption,
      "stateVoucher": localStorage.getItem('voucher'),
      "voucher": props.voucher,
      "partner": props.partner,
      "phlebotomyPrices": props.phlebotomyPrices,
      "subscriptionOption": props.subscriptionOption,
      "globalPlusTests": props.globalPlusTests,
      "globalHCtests": props.globalHCTests,
      "guestCheckout": props.guestCheckout,
      "deliveryOption": props.deliveryOption,
      "deliveryInfo": props.deliveryInfo,
      "nextDayDeliveryPrice": props.nextDayDeliveryPrice,
      "step": props.step
    } 
    
    let checkoutTotal = calculateBasket(cartProps); 
    setGiftCardTotal(checkoutTotal.giftCardTotal)
    setBasketTotal(checkoutTotal.testPrice);
    setSubtotal(checkoutTotal.subtotal);
    setTestPrice(checkoutTotal.testPrice); 
    let tests = props.basketTestItems;
    let basketArray = props.cart[0].basketArray;
    let items = [];
    let overrideGuest = false;

    if (Object.keys(props.cart).length > 0) { 

      var checkIfGuestSelected = false;
      var guestPurchase = props.cart[0].allowGuestPurchase;

      Object.keys(basketArray).map(function(key) {
        if (
          parseInt(basketArray[key][0]) == parseInt(38978) ||
          basketArray[key][0] == "38978" ||
          parseInt(basketArray[key][0]) == parseInt(40305) ||
          basketArray[key][0] == "40305" ||
          basketArray[key][0] == 42639 ||
          basketArray[key][0] == "42639"
        ) {
          guestPurchase = false;
        }
      });

      if (props.phlebotomyOption != 1) {
        guestPurchase = false;
      }

      var stateVoucher = localStorage.getItem("voucher");
      var voucher = props.voucher;
      if (!voucher) {
        voucher = JSON.parse(stateVoucher);
      }

      if (voucher && (voucher.orderCorporateId != "0" || voucher.orderCorporateId != 0)) {
        guestPurchase = false;
      }

      if (props.subscriptionOption == 0) {
        if (guestPurchase && props.guestCheckout != "unset") {
          setCheckIfGuestSelected(true) 
        } else {
          setCheckIfGuestSelected(false); 
        }
      } else {
        setCheckIfGuestSelected(false);
      }
    }

    Object.keys(basketArray).map(function(key) {
      find(tests, (test) => {
        if (test.testId === basketArray[key][0]) {
          items.push(basketArray[key]);
        } else {
          return false;
        }

        if (basketArray[key][0] == 152286) {
          overrideGuest = true;
        }
      });
    }); 
    let grouped = _.mapValues(_.groupBy(items, "0"));
//    setForceGuestCheckout(true);
    let forceGuest = true;
    if (parseInt(Object.keys(grouped).length) == parseInt(Object.keys(props.cart[0].basketArray).length)) {
      setForceGuestCheckout(false);
      forceGuest = false;
      if (props.guestCheckout == 'unset') {
        props.setGuestCheckoutValue(false);
      } 
    }

    if (overrideGuest) {
      setForceGuestCheckout(false);
      forceGuest = false;
      if (props.guestCheckout == 'unset') {
        props.setGuestCheckoutValue(false);
      } 
    }


    if (props.guestCheckout == 'unset') {
      props.setGuestCheckoutValue(false);
    } 
    
  }

  const initialScore = () => {
    if(!_.isEmpty(scoreByQuestion)) return

    const initialScore = Object.entries(customerEndoDetails).reduce((obj, [name, value]) => {
      return {
        ...obj,
        [name]: value ? value : 0
      }
    }, {})

    setScoreByQuestion(initialScore)
  }

  const getScoreRanges = () => {
    let testType = 0

    if(foundEndoTest){
      testType = 5
    }

    props.loadScoreRanges(testType)?.then(response => {
      if(response.error) {
        const errorMsg = `
          <p class='mb-0'>
            Something went wrong, please try again and if the problem persist contact us at <a class='text-black fw-semibold' href='mailto:help@forthsupport.co.uk'>help@forthsupport.co.uk</a>
          </p>`;
        setError(errorMsg)
      }

      setScoreRanges(Object.values(response.response)[0])
    })
  }

  const handleSliderChange = (e) => {
    if(!e.target.value || !e.target.name) return
    if(error) setError("")

    const {value, name} = e.target
    let delay

    if(delay) clearTimeout(delay)

    setCustomerEndoDetails({...customerEndoDetails, [name]: value})
    setScoreByQuestion({
      ...scoreByQuestion,
      [name]: value
    })
  }
  
  const handleChangeInputs = (name, value, score = 0) => {
    if(!value || !name) return
    if(error) setError("")

    setCustomerEndoDetails({...customerEndoDetails, [name]: value})
    setScoreByQuestion({
      ...scoreByQuestion,
      [name]: score
    })
  }

  const handleSubmit = (e) => {
    
    e.preventDefault();
    if(props.guestCheckout && !props.checkoutFromPartner){
      props.goToStep(steps.testOptionsScreen)
      return
    }

    if(foundEndoTest){
      const {suitableAction, suitableMinScore, suitableMaxScore, unsuitableAction, unsuitableMinScore, unsuitableMaxScore} = scoreRanges || {}
  
      if(totalScore >= unsuitableMinScore && totalScore <= unsuitableMaxScore){
        setError("Sorry, you are not suitable for this test.")
        return
      }
  
      if(totalScore >= suitableMinScore && totalScore <= suitableMaxScore){
        props.handleSwitchToBespokeTest(false)
        props.goToStep(steps.clientDetailsScreen)
        return
      }
    }

    if(foundTrueCheckTest){
      const hasCheckedAll = Object.values(customerTrueCheckDetails).every(Boolean)
      if (hasCheckedAll) {
        props.handleSwitchToBespokeTest(false)
      }

      return hasCheckedAll && props.goToStep(steps.clientDetailsScreen)
    }
    
  }

  const handleCheckboxChange = (e) => {
    const {checked, name} = e.target

    setCustomerTrueCheckDetails({...customerTrueCheckDetails, [name]: checked})
  }

  return (
    <>
      <div className="row d-block d-md-none pt-0 pt-md-5 g-0 w-100">
        <div className="col-12">
          <YourOrderWrapper subtotal={subtotal} totalAmount={parseFloat(testPrice)} partner={props.partner} checkoutFromPartner={props.checkoutFromPartner} step={props.step}>
            <BasketItems
              cart={props.cart}
              basketTestItems={props.basketTestItems}
              phlebotomyOption={props.phlebotomyOption}
              getVoucher={props.getVoucher}
              globalPlusTests={props.globalPlusTests}
              setPlusTests={props.setPlusTests}
              handleQuantityChange={props.handleQuantityChange}
              subscriptionInfo={props.subscriptionInfo}
              subscriptionOption={props.subscriptionOption}
              step={props.step} 
              partner={props.partner} 
              voucher={props.voucher} 
              handleSubscriptionChange={props.handleSubscriptionChange}
              globalHCTests={props.globalHCTests}
              setGlobalHCTests={props.setGlobalHCTests}
            />
            <CartSummary 
              deliveryInfo={props.deliveryInfo}
              customerDetails={props.customerDetails}
              phlebotomyPrices={props.phlebotomyPrices}
              voucherRemovedBecauseOfCredit={props.voucherRemovedBecauseOfCredit}
              subscriptionOption={props.subscriptionOption}
              step={props.step}
              globalPlusTests={props.globalPlusTests}
              deliveryOption={props.deliveryOption}
              guestCheckout={props.guestCheckout}
              subscriptionInfo={props.subscriptionInfo}
              cart={props.cart[0]}
              basketTestItems={props.basketTestItems}
              basketArray={props.cart[0].basketArray}
              phlebotomyOption={props.phlebotomyOption}
              voucher={props.voucher}
              user={props.user}
              partner={props.partner}
              nextDayDeliveryPrice={props.nextDayDeliveryPrice}
              partnerClinics={props.partnerClinics}
              basket={props.basket}
              tests={props.basketTestItems}
              updatePhlebotomyOption={props.updatePhlebotomyOption}
              phlebotomyError={props.phlebotomyError}
              updateDeliveryOption={props.updateDeliveryOption} 
              allowInternational={props?.stateVoucher?.allowInternational}
              globalHCTests={props.globalHCTests}
            /> 
          </YourOrderWrapper>
             
        </div>
      </div>

      <div className="container py-5 z">
        <div className="row">  
          <div className="col-12 col-md-6">
            <CheckoutProgressBar showQuestionAdditionalStep={foundEndoTest || foundTrueCheckTest} goToStep={props.goToStep} step={props.step} checkoutFromPartner={props.checkoutFromPartner} guestCheckout={props.guestCheckout}/>

            <form onSubmit={handleSubmit} className={`mt-4 ${styles.form}`}>
              <RenderIf isTrue={foundEndoTest && !_.isEmpty(endoQuestions)}>
                {endoQuestions?.map((question = {}, i) => {
                  const {label, fieldName, additionalInformation, options, fieldType, required} = question

                  return (
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label" htmlFor={question.fieldName}>
                        {label} {required ? <span className='me-1'>*</span> : ""}
                        {additionalInformation ? <span className='fw-light'>({additionalInformation})</span> : ""}</label>
                      <div className="col-sm-12">
                        <RenderIf isTrue={fieldType === "radio"}>
                          <Radio options={options} defaultValue="0" id={fieldName} name={fieldName} onClick={handleChangeInputs} value={customerEndoDetails[fieldName]}/>
                        </RenderIf>
                        <RenderIf isTrue={fieldType === "slider"}>
                          <Slider id={fieldName} name={fieldName} options={options} onChange={handleSliderChange} className={`form-range`} value={customerEndoDetails[fieldName]}/>
                        </RenderIf>
                      </div>
                    </div>
                  )
                })}
              </RenderIf>
              <RenderIf isTrue={foundTrueCheckTest && !_.isEmpty(trueCheckQuestions)}>
                <h3 className='fw-normal lh-base mt-4'>Please confirm the following:</h3>

                {trueCheckQuestions?.map((question = {}, i) => {
                  const {label, fieldName, additionalInformation, options, fieldType, required} = question

                  return (
                    <label className={styles.checkboxContainer}>
                      <div className="form-group row align-items-center">
                        <div className="col-auto h-100">
                          <RenderIf isTrue={fieldType === "checkbox"}>
                            <input type='checkbox' id={fieldName} name={fieldName} checked={customerTrueCheckDetails[fieldName]} className={styles.checkboxInput} onChange={handleCheckboxChange}/>
                          </RenderIf>
                        </div>
                        <div className="col col-form-label">
                          <p className="mb-0 text-pretty" htmlFor={fieldName}>
                            {label}
                            {additionalInformation ? <span className='fw-light'>({additionalInformation})</span> : ""}
                          </p>
                        </div>
                      </div>
                    </label>
                  )
                })}
              </RenderIf>

              <div className='row'>
                {error != "" && (  
                  <div className="col-md-12 mt-3">
                    <div className="alert alert-danger basketAlert text-wrap" role="alert" dangerouslySetInnerHTML={{__html: error}}></div>
                  </div> 
                )}

                <div className="col-12" align="center">
                  {props.guestCheckout && !props.checkoutFromPartner ? (  
                    <Button type="submit" disabled={isBtnDisabled} className={`mt-2 mb-3 w-100`}>
                      Next - Test options
                    </Button>
                  ) : (
                    <Button type="submit" disabled={isBtnDisabled} className={`mt-2 mb-3 w-100`}>
                      Next - Your Details
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 col-md-6 d-none d-md-block"> 
            <YourOrderWrapper>
              <BasketItems
                cart={props.cart}
                basketTestItems={props.basketTestItems}
                phlebotomyOption={props.phlebotomyOption}
                getVoucher={props.getVoucher}
                globalPlusTests={props.globalPlusTests}
                setPlusTests={props.setPlusTests}
                handleQuantityChange={props.handleQuantityChange}
                subscriptionInfo={props.subscriptionInfo}
                subscriptionOption={props.subscriptionOption}
                step={props.step} 
                partner={props.partner} 
                voucher={props.voucher} 
                handleSubscriptionChange={props.handleSubscriptionChange}
                globalHCTests={props.globalHCTests}
                setGlobalHCTests={props.setGlobalHCTests}
              />
              <CartSummary 
                deliveryInfo={props.deliveryInfo}
                customerDetails={props.customerDetails}
                phlebotomyPrices={props.phlebotomyPrices}
                voucherRemovedBecauseOfCredit={props.voucherRemovedBecauseOfCredit}
                subscriptionOption={props.subscriptionOption}
                step={props.step}
                globalPlusTests={props.globalPlusTests}
                deliveryOption={props.deliveryOption}
                guestCheckout={props.guestCheckout}
                subscriptionInfo={props.subscriptionInfo}
                cart={props.cart[0]}
                basketTestItems={props.basketTestItems}
                basketArray={props.cart[0].basketArray}
                phlebotomyOption={props.phlebotomyOption}
                voucher={props.voucher}
                user={props.user}
                partner={props.partner}
                nextDayDeliveryPrice={props.nextDayDeliveryPrice}
                partnerClinics={props.partnerClinics}
                basket={props.basket}
                tests={props.basketTestItems}
                updatePhlebotomyOption={props.updatePhlebotomyOption}
                phlebotomyError={props.phlebotomyError}
                updateDeliveryOption={props.updateDeliveryOption} 
                allowInternational={props?.stateVoucher?.allowInternational}
                globalHCTests={props.globalHCTests}
              /> 
            </YourOrderWrapper> 
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const {} = state;

  return {};
};

export default withRouter(
  connect(mapStateToProps, {
    loadScoreRanges,
  })(CheckoutAdditionalQuestions)
);
