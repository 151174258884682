import React, { useEffect } from 'react'
import { CheckoutFooter, CheckoutHeader } from '../..';
import { getWebsiteUrl } from '../../../utils'; 
import styles from './CheckoutEmptyCart.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';

export default function CheckoutEmptyCart(props) {
  const {step, partner} = props
  const sendCloseIframe = (e) => {
    e.preventDefault();
    if(window.parent) {
      window.parent.postMessage('closeIframe', '*');
    }
  }

  useEffect(() => {
    localStorage.removeItem("lastItemAdded");
  }, [])

  return (
    <>
      { window == window.parent && (
        <CheckoutHeader step={step} partner={partner}/>
      )}
      <main className="flex-grow-1 py-5">
        <div className="row align-items-center justify-content-center h-100">
          <div className="col-12 col-md-10 text-center">
            <h1 className="mb-5">You do not currently have any items in your basket</h1>

            { window !== window.parent ? (
              <a href="#" className="mt-4" onClick={(e) => sendCloseIframe(e) }>
                <FontAwesomeIcon icon={faChevronLeft} /> Continue shopping
              </a>
            ) : (
              <a href={getWebsiteUrl(global.config.websiteId)} className="mt-4">
                <FontAwesomeIcon icon={faChevronLeft} /> Continue shopping
              </a>
            )}

             
          </div>
        </div>
      </main>
      { window == window.parent && (
        <CheckoutFooter partner={partner}/>
      )}
    </>
  );
}