import moment from 'moment'

/*
  dow == 0 => Sunday
  dow == 1 => Monday
  dow == 2 => Tuesday
  dow == 3 => Wednesday
  dow == 4 => Thursday
  dow == 5 => Friday
  dow == 6 => Saturday
*/

export default function getDeliveryCopy() {
  let nextDayTitle = "Guaranteed Next Working Day";
  let nextDayDescription = "Your kit will be dispatched via guaranteed next day for delivery (UK only)";
  const time = moment()
  const beforeTime = moment().set("hour", 11).set("minute", 59)
  const afterTime = moment().set("hour", 23).set("minute", 59)
  const dow = moment().day()
  let postDate = 0 
  let deliveryDate = 0

  //Saturday
  if (dow == 6) {
    postDate = moment().add(3,'days').format("DD/MM/YY");
    deliveryDate = moment().add(3,'days').format("YYYY/MM/DD");

    nextDayTitle = 'Guaranteed by ' + postDate;
    nextDayDescription = "Your kit will be guaranteed for delivery by "+ moment(deliveryDate).format("dddd Do MMMM YYYY")+ ". There is no need to be in, the kit will fit through your letter box."
  }

  //Sunday
  else if (dow == 0) {
    postDate = moment().add(2,'days').format("DD/MM/YY");
    deliveryDate = moment().add(2,'days').format("YYYY/MM/DD");

    nextDayTitle = 'Guaranteed by ' + postDate;
    nextDayDescription = "Your kit will be guaranteed for delivery by "+ moment(deliveryDate).format("dddd Do MMMM YYYY")+ ". There is no need to be in, the kit will fit through your letter box."

  }

  //Monday - Friday After 12pm
  else { 
    if(moment().isBetween(beforeTime, afterTime)){

      //Thursday
      if (dow == 4) {       
        postDate = moment().add(4,'days').format("DD/MM/YY");
        deliveryDate = moment().add(4,'days').format("YYYY/MM/DD");
        nextDayTitle = 'Guaranteed by ' + postDate;
        nextDayDescription = "Your kit will be guaranteed for delivery by "+ moment(deliveryDate).format("dddd Do MMMM YYYY")+ ". There is no need to be in, the kit will fit through your letter box."
      } 

      //Friday
      else if (dow == 5) {
        postDate = moment().add(4,'days').format("DD/MM/YY");
        deliveryDate = moment().add(4,'days').format("YYYY/MM/DD");
        nextDayTitle = 'Guaranteed by ' + postDate;
        nextDayDescription = "Your kit will be guaranteed for delivery by "+ moment(deliveryDate).format("dddd Do MMMM YYYY")+ ". There is no need to be in, the kit will fit through your letter box."
      } 

      //Monday, Tuesday, Wednesday
      else {
        postDate = moment().add(2,'days').format("DD/MM/YY");
        deliveryDate = moment().add(2,'days').format("YYYY/MM/DD");
        nextDayTitle = 'Guaranteed by ' + postDate;
        nextDayDescription = "Your kit will be guaranteed for delivery by "+ moment(deliveryDate).format("dddd Do MMMM YYYY")+ ". There is no need to be in, the kit will fit through your letter box."
      }
    } 
  }

  return {'nextDayTitle':nextDayTitle, 'nextDayDescription':nextDayDescription};

}
