import isEmpty from "lodash/isEmpty";

export const getFingerPrickCopy = (tests = {}) => {
  const copies = {
    1: "Finger prick kit",
    2: "Finger prick kit",
    3: "Finger prick kit",
    4: "Throat swab",
    5: "Saliva",
    6: "Urine",
    7: "Vaginal Swab",
    8: "Rectal Swab",
    9: "Finger Prick and Urine",
    10: "Finger Prick and Vaginal swab",
    11: "Penile Swab",
    12: "Finger prick and penile swab"
  }

  if(typeof tests === "object" && isEmpty(tests)) return copies[1];
  if(typeof tests === "string" || typeof tests === "number") return copies[tests] ?? copies[1];

  const testType = Object.values(tests || {}).find(test => test.testType).testType

  return copies[testType] ?? copies[1]
}