import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom'
import styles from "./BiomarkersAnalysed.module.scss"; 
import uniqBy from 'lodash/uniqBy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicroscope } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

export default function BiomarkersAnalysed(props) {  
  const [toggled, setToggled] = useState(false);  

  const biomarkersList = (testBiomarkers) => {
    testBiomarkers = uniqBy(testBiomarkers, 'biomarkerId');
    testBiomarkers?.sort((a,b) => {
      return a.biomarkerName.localeCompare(b.biomarkerName)}
    );

  
    let keys = Object.keys(testBiomarkers);
    let half = Math.ceil(keys.length/2);
 
    const firstHalf = []
    const secondHalf = []
    
    for(let i = 0;i<half;i++) {
      firstHalf.push(<li className={"tubeColourId"+testBiomarkers[keys[i]].tubeColourId} dangerouslySetInnerHTML={{__html: testBiomarkers[keys[i]].biomarkerName}}></li>)
    }

    for(let i =half;i<keys.length;i++) {
      secondHalf.push(<li className={"tubeColourId"+testBiomarkers[keys[i]].tubeColourId} dangerouslySetInnerHTML={{__html: testBiomarkers[keys[i]].biomarkerName}}></li>)
    }
    
 
    return(
      <div className="row">
        <div className="col-6">

          <ul>
            {firstHalf}
          </ul>

        </div>

        <div className="col-6">
          
          <ul>
            {secondHalf}
          </ul>

        </div>

      </div>
    ) 
  }

  const setToggle = (e) => {
    setToggled(!toggled)
  }
 
  return (
    <div className={"card-block"}>   
      <div className={styles.card+" card mt-3"}>
        <div className={styles.cardHeader+ " card-header"} id="headingThree">
     
          <h5 className="mb-0"> 
            <a className="collapsed d-flex align-items-center" onClick={() => { setToggle() }} data-bs-toggle="collapse" href={"#collapse"+props.testId} role="button" aria-expanded="false" aria-controls={"collapse"+props.testId}  >
              <FontAwesomeIcon icon={faMicroscope} className='fa-fw pe-1'/> What gets tested
              {toggled ? (
                <FontAwesomeIcon icon={faChevronUp} className='float-right ps-3 ms-auto'/>
              ) : (
                <FontAwesomeIcon icon={faChevronDown} className='float-right ps-3 ms-auto'/>
              )}
               
            </a>
          </h5>
        </div>
        <div id={"collapse"+props.testId} className="collapse" >
          <div className="card-body"> 
            {biomarkersList(props.test.biomarkers)}
          </div>
        </div>
      </div>
    </div>

  )

}
 

      
