import React from 'react'
import RenderIf from '../../RenderIf/RenderIf';
import styles from './Button.module.scss'

export default function Button({children, variant = "primary", fontSize = "default", className, isLoading, href = "", ...rest}) {

  return (
    <>
      <RenderIf isTrue={!href}>
        
        <button variant={variant} data-font-size={fontSize} className={`${styles.button} ${className}` } {...rest} >
          <RenderIf isTrue={isLoading}>
            <div className="spinner-border spinner-border-sm mx-2" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </RenderIf>
          {children}
        </button>

      </RenderIf>

      <RenderIf isTrue={href}>
        <a href={href} variant={variant} data-font-size={fontSize} className={`${styles.button} ${className}` } {...rest} >
          <RenderIf isTrue={isLoading}>
            <div className="spinner-border spinner-border-sm mx-2" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </RenderIf>
          {children}
        </a>

      </RenderIf>
    </>
  );
}