import { CALL_API, Schemas } from '../middleware/api'

export const ORDER_STATUS_REQUEST = 'ORDER_STATUS_REQUEST'
export const ORDER_STATUS_SUCCESS = 'ORDER_STATUS_SUCCESS'
export const ORDER_STATUS_FAILURE = 'ORDER_STATUS_FAILURE'

const fetchLatestOrderStatus = (userId) => ({
  userId,
  [CALL_API]: {
    types: [ ORDER_STATUS_REQUEST, ORDER_STATUS_SUCCESS, ORDER_STATUS_FAILURE ],
    endpoint: `secure/orders/latest-orders/status/${userId}`,
    schema: Schemas.ORDER_ARRAY
  }
})

export const loadLatestOrderStatus = (userId) => (dispatch, getState) => {
  return dispatch(fetchLatestOrderStatus(userId))
}
 
export const GET_LAST_ORDER_DATE_REQUEST = 'GET_LAST_ORDER_DATE_REQUEST'
export const GET_LAST_ORDER_DATE_SUCCESS = 'GET_LAST_ORDER_DATE_SUCCESS'
export const GET_LAST_ORDER_DATE_FAILURE = 'GET_LAST_ORDER_DATE_FAILURE'

const fetchLastOrderDate = (userId) => ({
  userId,
  [CALL_API]: {
    types: [ GET_LAST_ORDER_DATE_REQUEST, GET_LAST_ORDER_DATE_SUCCESS, GET_LAST_ORDER_DATE_FAILURE ],
    endpoint: `secure/orders/last-test/${userId}`,
    schema: Schemas.LAST_ORDER
  }
})

export const loadLastOrderDate = (userId) => (dispatch, getState) => {
  return dispatch(fetchLastOrderDate(userId))
}


export const GET_HP_COMMENT_REQUEST = 'GET_HP_COMMENT_REQUEST'
export const GET_HP_COMMENT_SUCCESS = 'GET_HP_COMMENT_SUCCESS'
export const GET_HP_COMMENT_FAILURE = 'GET_HP_COMMENT_FAILURE'

const fetchHpComment = (orderId) => ({
  orderId,
  [CALL_API]: {
    types: [ GET_HP_COMMENT_REQUEST, GET_HP_COMMENT_SUCCESS, GET_HP_COMMENT_FAILURE ],
    endpoint: `secure/orders/hp-comment/${orderId}`,
    schema: Schemas.HP_COMMENT
  }
})

export const loadHpComment = (orderId) => (dispatch, getState) => {
  const hpComment = getState().entities.hpComment;
 
  if (Object.keys(hpComment).length > 0) {
    Object.keys(hpComment).map(function(key) {

      delete hpComment[key];

    })  
  } 
  return dispatch(fetchHpComment(orderId))
}


export const GET_CURRENT_ORDERS_REQUEST = 'GET_CURRENT_ORDERS_REQUEST'
export const GET_CURRENT_ORDERS_SUCCESS = 'GET_CURRENT_ORDERS_SUCCESS'
export const GET_CURRENT_ORDERS_FAILURE = 'GET_CURRENT_ORDERS_FAILURE'

const fetchCurrentOrders = (userId) => ({
  userId,
  [CALL_API]: {
    types: [ GET_CURRENT_ORDERS_REQUEST, GET_CURRENT_ORDERS_SUCCESS, GET_CURRENT_ORDERS_FAILURE ],
    endpoint: `/secure/orders/current-orders/${userId}`,
    schema: Schemas.CURRENT_ORDERS
  }
})

export const loadCurrentOrders = (userId) => (dispatch, getState) => {
  return dispatch(fetchCurrentOrders(userId))
}


export const GET_COMPLETED_ORDERS_REQUEST = 'GET_COMPLETED_ORDERS_REQUEST'
export const GET_COMPLETED_ORDERS_SUCCESS = 'GET_COMPLETED_ORDERS_SUCCESS'
export const GET_COMPLETED_ORDERS_FAILURE = 'GET_COMPLETED_ORDERS_FAILURE'

const fetchCompletedOrders = (userId) => ({
  userId,
  [CALL_API]: {
    types: [ GET_COMPLETED_ORDERS_REQUEST, GET_COMPLETED_ORDERS_SUCCESS, GET_COMPLETED_ORDERS_FAILURE ],
    endpoint: `/secure/orders/completed-orders/${userId}`,
    schema: Schemas.COMPLETED_ORDERS
  }
})

export const loadCompletedOrders = (userId) => (dispatch, getState) => {
  return dispatch(fetchCompletedOrders(userId))
}

export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST'
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS'
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE'

const fetchReplacementKit = (body) => ({
  [CALL_API]: {
    types: [ UPDATE_ORDER_REQUEST, UPDATE_ORDER_SUCCESS, UPDATE_ORDER_FAILURE ],
    endpoint: 'secure/orders/replacement-kit',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateReplacementKit = (body) => (dispatch, getState) => {
  return dispatch(fetchReplacementKit(body))
};
 

const fetchPhlebotomyUpdate = (body) => ({
  [CALL_API]: {
    types: [ UPDATE_ORDER_REQUEST, UPDATE_ORDER_SUCCESS, UPDATE_ORDER_FAILURE ],
    endpoint: 'secure/orders/phlebotomy',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updatePhlebotomyUpdate = (body) => (dispatch, getState) => {
  return dispatch(fetchPhlebotomyUpdate(body))
};


const fetchCancelOrder = (body) => ({
  [CALL_API]: {
    types: [ UPDATE_ORDER_REQUEST, UPDATE_ORDER_SUCCESS, UPDATE_ORDER_FAILURE ],
    endpoint: 'secure/orders/cancel',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateCancelOrder = (body) => (dispatch, getState) => {
  return dispatch(fetchCancelOrder(body))
};



const fetchUpdateBloodSent = (body) => ({
  [CALL_API]: {
    types: [ UPDATE_ORDER_REQUEST, UPDATE_ORDER_SUCCESS, UPDATE_ORDER_FAILURE ],
    endpoint: 'secure/orders/update-order-blood-sent',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateBloodSent = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdateBloodSent(body))
};

 
const fetchLatestHormoneOrders = (userId, loadLatestHormoneOrders) => ({
  userId,
  loadLatestHormoneOrders,
  [CALL_API]: {
    types: [ ORDER_STATUS_REQUEST, ORDER_STATUS_SUCCESS, ORDER_STATUS_FAILURE ],
    endpoint: `secure/get-hormone-results/orders/latest-orders/${userId}/${loadLatestHormoneOrders}`,
    schema: Schemas.HORMONE_ORDER_ARRAY
  }
})

export const loadLatestHormoneOrders  = (userId, loadLatestHormoneOrders) => (dispatch, getState) => {
  const hormoneOrder = getState().entities.hormoneOrder;
  if (Object.keys(hormoneOrder).length > 0) {
    Object.keys(hormoneOrder).map(function(key) {

      delete hormoneOrder[key];

    })  
  } 
  return dispatch(fetchLatestHormoneOrders(userId, loadLatestHormoneOrders))
}

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST'
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS'
export const ORDER_DETAILS_FAILURE = 'ORDER_DETAILS_FAILURE'

const fetchLoadOrderDetails = (orderId) => ({

  [CALL_API]: {
    types: [ ORDER_DETAILS_FAILURE, ORDER_DETAILS_SUCCESS, ORDER_DETAILS_FAILURE ],
    endpoint: `secure/orders/get-order-details/${orderId}`,
    schema: Schemas.ORDER_DETAILS
  }
})

export const loadOrderDetails  = (orderId) => (dispatch, getState) => {

  const orderDetails = getState().entities.orderDetails;
 
  if (Object.keys(orderDetails).length > 0) {
    Object.keys(orderDetails).map(function(key) {

      delete orderDetails[key];

    })  
  } 
  return dispatch(fetchLoadOrderDetails(orderId))
}
export const GET_RECIEPT_REQUEST = 'GET_RECIEPT_REQUEST'
export const GET_RECIEPT_SUCCESS = 'GET_RECIEPT_SUCCESS'
export const GET_RECIEPT_FAILURE = 'GET_RECIEPT_FAILURE'

const fetchGetReceipt = (orderId) => ({
  orderId,
  [CALL_API]: {
    types: [GET_RECIEPT_REQUEST, GET_RECIEPT_SUCCESS, GET_RECIEPT_FAILURE],
    endpoint: `secure/orders/get-receipt/${orderId}`,
    schema: Schemas.PDF_RESULTS, 
  }
})


export const getReceipt = (orderId) => (dispatch, getState) => {
  return dispatch(fetchGetReceipt(orderId))
}

export const GET_CERTIFICATE_REQUEST = 'GET_CERTIFICATE_REQUEST'
export const GET_CERTIFICATE_SUCCESS = 'GET_CERTIFICATE_SUCCESS'
export const GET_CERTIFICATE_FAILURE = 'GET_CERTIFICATE_FAILURE'

const fetchGetCertificate = (orderId) => ({
  orderId,
  [CALL_API]: {
    types: [GET_CERTIFICATE_REQUEST, GET_CERTIFICATE_SUCCESS, GET_CERTIFICATE_FAILURE],
    endpoint: `secure/orders/get-certificate/${orderId}`,
    schema: Schemas.PDF_RESULTS, 
  }
})

export const getCertificate = (orderId) => (dispatch, getState) => {
  return dispatch(fetchGetCertificate(orderId))
}


export const GET_QUESTIONNAIRE_STATUS_REQUEST = 'GET_QUESTIONNAIRE_STATUS_REQUEST'
export const GET_QUESTIONNAIRE_STATUS_SUCCESS = 'GET_QUESTIONNAIRE_STATUS_SUCCESS'
export const GET_QUESTIONNAIRE_STATUS_FAILURE = 'GET_QUESTIONNAIRE_STATUS_FAILURE'

const fetchGetQuestionnaireStatus = (orderId) => ({
  orderId,
  [CALL_API]: {
    types: [GET_QUESTIONNAIRE_STATUS_REQUEST, GET_QUESTIONNAIRE_STATUS_SUCCESS, GET_QUESTIONNAIRE_STATUS_FAILURE],
    endpoint: `secure/orders/get-questionnaire-status/${orderId}`,
    schema: null, 
  }
})

export const getQuestionnaireStatus = (orderId) => (dispatch, getState) => {
  return dispatch(fetchGetQuestionnaireStatus(orderId))
}

export const UPDATE_QUESTIONNAIRE_STATUS_REQUEST = 'UPDATE_QUESTIONNAIRE_STATUS_REQUEST'
export const UPDATE_QUESTIONNAIRE_STATUS_SUCCESS = 'UPDATE_QUESTIONNAIRE_STATUS_SUCCESS'
export const UPDATE_QUESTIONNAIRE_STATUS_FAILURE = 'UPDATE_QUESTIONNAIRE_STATUS_FAILURE'

const fetchUpdateQuestionnaireStatus = (body) => ({
  [CALL_API]: {
    types: [ UPDATE_QUESTIONNAIRE_STATUS_REQUEST, UPDATE_QUESTIONNAIRE_STATUS_SUCCESS, UPDATE_QUESTIONNAIRE_STATUS_FAILURE ],
    endpoint: 'secure/orders/update-questionnaire-status',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateQuestionnaireStatus = (body) => (dispatch, getState) => {
  return dispatch(fetchUpdateQuestionnaireStatus(body))
};