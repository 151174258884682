import { CALL_API, Schemas } from '../middleware/api'

export const PARENT_BESPOKE_TEST_REQUEST = 'PARENT_BESPOKE_TEST_REQUEST'
export const PARENT_BESPOKE_TEST_SUCCESS = 'PARENT_BESPOKE_TEST_SUCCESS'
export const PARENT_BESPOKE_TEST_FAILURE = 'PARENT_BESPOKE_TEST_FAILURE'

const fetchParentBespokeTest = (parentTest, partnerId) => ({
  [CALL_API]: {
    types: [ PARENT_BESPOKE_TEST_REQUEST, PARENT_BESPOKE_TEST_REQUEST, PARENT_BESPOKE_TEST_REQUEST ],
    endpoint: `bespoke-test/get-parent-test/${parentTest}/${partnerId}`,
    schema: Schemas.PARENT_BESPOKE_TEST
  }
})

export const loadParentBespokeTest = (parentTest, partnerId) => (dispatch, getState) => {
  const parentBespokeTest = getState().entities.parentBespokeTest;
  if (Object.keys(parentBespokeTest).length > 0) {
    Object.keys(parentBespokeTest).map(function(key) {

      delete parentBespokeTest[key];

    })  
  }

  return dispatch(fetchParentBespokeTest(parentTest, partnerId))
}



export const PLUS_TEST_REQUEST = 'PLUS_TEST_REQUEST'
export const PLUS_TEST_SUCCESS = 'PLUS_TEST_SUCCESS'
export const PLUS_TEST_FAILURE = 'PLUS_TEST_FAILURE'

const fetchPlusTests = (websiteId) => ({
  [CALL_API]: {
    types: [ PLUS_TEST_REQUEST, PLUS_TEST_SUCCESS, PLUS_TEST_FAILURE ],
    endpoint: `bespoke-test/get-plus-tests/${websiteId}`,
    schema: Schemas.PLUS_TESTS_ARRAY
  }
})

export const loadPlusTests = (websiteId) => (dispatch, getState) => {
  const plusTests = getState().entities.plusTests;
  if (Object.keys(plusTests).length > 0) {
    Object.keys(plusTests).map(function(key) {

      delete plusTests[key];

    })  
  }

  return dispatch(fetchPlusTests(websiteId))
}

export const PLUS_TEST_PARTNER_REQUEST = 'PLUS_TEST_PARTNER_REQUEST'
export const PLUS_TEST_PARTNER_SUCCESS = 'PLUS_TEST_PARTNER_SUCCESS'
export const PLUS_TEST_PARTNER_FAILURE = 'PLUS_TEST_PARTNER_FAILURE'

const fetchPartnerPlusTests = (websiteId) => ({
  [CALL_API]: {
    types: [ PLUS_TEST_PARTNER_REQUEST, PLUS_TEST_PARTNER_SUCCESS, PLUS_TEST_PARTNER_FAILURE ],
    endpoint: `bespoke-test/get-partner-plus-tests/${websiteId}`,
    schema: Schemas.PLUS_TESTS_ARRAY
  }
})

export const loadPartnerPlusTests = (websiteId) => (dispatch, getState) => {
  const plusTests = getState().entities.plusTests;
  if (Object.keys(plusTests).length > 0) {
    Object.keys(plusTests).map(function(key) {

      delete plusTests[key];

    })  
  }

  return dispatch(fetchPartnerPlusTests(websiteId))
}

export const ADD_BESPOKE_TEST_REQUEST = 'ADD_BESPOKE_TEST_REQUEST'
export const ADD_BESPOKE_TEST_SUCCESS = 'ADD_BESPOKE_TEST_SUCCESS'
export const ADD_BESPOKE_TEST_FAILURE = 'ADD_BESPOKE_TEST_FAILURE'

const fetchAddTest = (body) => ({
  [CALL_API]: {
    types: [ ADD_BESPOKE_TEST_REQUEST, ADD_BESPOKE_TEST_SUCCESS, ADD_BESPOKE_TEST_FAILURE ],
    endpoint: 'bespoke-test/add-test',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateAddTest  = (body) => (dispatch, getState) => {
  return dispatch(fetchAddTest(body))
};


export const ADD_MULTIPLE_BESPOKE_TEST_REQUEST = 'ADD_MULTIPLE_BESPOKE_TEST_REQUEST'
export const ADD_MULTIPLE_BESPOKE_TEST_SUCCESS = 'ADD_MULTIPLE_TEST_SUCCESS'
export const ADD_MULTIPLE_BESPOKE_TEST_FAILURE = 'ADD_MULTIPLE_TEST_FAILURE'

const fetchMultipleAddTest = (body) => ({
  [CALL_API]: {
    types: [ ADD_BESPOKE_TEST_REQUEST, ADD_BESPOKE_TEST_SUCCESS, ADD_BESPOKE_TEST_FAILURE ],
    endpoint: 'bespoke-test/add-multiple-tests',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const updateMultipleAddTest  = (body) => (dispatch, getState) => {
  return dispatch(fetchMultipleAddTest(body))
};



export const AVAILABLE_TESTS_REQUEST = 'AVAILABLE_TESTS_REQUEST'
export const AVAILABLE_TESTS_SUCCESS = 'AVAILABLE_TESTS_SUCCESS'
export const AVAILABLE_TESTS_FAILURE = 'AVAILABLE_TESTS_FAILURE'

const fetchAvailableTests = (body) => ({
  [CALL_API]: {
    types: [ AVAILABLE_TESTS_REQUEST, AVAILABLE_TESTS_SUCCESS, AVAILABLE_TESTS_FAILURE ],
    endpoint: 'bespoke-test/check-available-tests',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const loadAvailableTests  = (body) => (dispatch, getState) => {
  return dispatch(fetchAvailableTests(body))
};



export const RENAME_TESTS_REQUEST = 'RENAME_TESTS_REQUEST'
export const RENAME_TESTS_SUCCESS = 'RENAME_TESTS_SUCCESS'
export const RENAME_TESTS_FAILURE = 'RENAME_TESTS_FAILURE'

const fetchRenameTest = (body) => ({
  [CALL_API]: {
    types: [ RENAME_TESTS_REQUEST, RENAME_TESTS_SUCCESS, RENAME_TESTS_FAILURE ],
    endpoint: 'bespoke-test/rename-test',
    schema: null,
    method: 'POST', 
    body: body
  }
})

export const loadRenameTest  = (body) => (dispatch, getState) => {
  return dispatch(fetchRenameTest(body))
};




export const EDIT_TESTS_REQUEST = 'EDIT_TESTS_REQUEST'
export const EDIT_TESTS_SUCCESS = 'EDIT_TESTS_SUCCESS'
export const EDIT_TESTS_FAILURE = 'EDIT_TESTS_FAILURE'

const fetchEditTest = (editTestId) => ({
  [CALL_API]: {
    types: [ PARENT_BESPOKE_TEST_REQUEST, PARENT_BESPOKE_TEST_REQUEST, PARENT_BESPOKE_TEST_REQUEST ],
    endpoint: `bespoke-test/get-edit-test/${editTestId}`,
    schema: Schemas.PARENT_BESPOKE_TEST
  }
})

export const loadEditTest  = (editTestId) => (dispatch, getState) => {
  return dispatch(fetchEditTest(editTestId))
};



export const HEALTH_CATEGORIES_REQUEST = 'HEALTH_CATEGORIES_REQUEST'
export const HEALTH_CATEGORIES_SUCCESS = 'HEALTH_CATEGORIES_SUCCESS'
export const HEALTH_CATEGORIES_FAILURE = 'HEALTH_CATEGORIES_FAILURE'

const fetchHealthCategories = () => ({
  [CALL_API]: {
    types: [ HEALTH_CATEGORIES_REQUEST, HEALTH_CATEGORIES_SUCCESS, HEALTH_CATEGORIES_FAILURE ],
    endpoint: `/bespoke-test/get-test-categories`,
    schema: Schemas.HEALTH_CATEGORIES
  }
})

export const loadHealthCategories = () => (dispatch, getState) => {
  return dispatch(fetchHealthCategories())
}



